import { Row } from 'reactstrap';
import { CARTS_BY_FILTERS, CART_FILTERS } from '../../utils/constants';
import './packageInfo.scss';

const PackageInfoCartFilters = ({
  activeCardsFilter,
  setActiveCardsFilter,
  availableFilters,
}: any) => {
  return (
    <Row className="ml-2 mr-0 mt-2">
      {Object.entries(CART_FILTERS).map(([key, value], id) => {
        if (
          CARTS_BY_FILTERS[key as keyof typeof CARTS_BY_FILTERS] &&
          CARTS_BY_FILTERS[key as keyof typeof CARTS_BY_FILTERS].every((el: any) => {
            return !availableFilters.includes(el);
          })
        ) {
          return;
        }

        return (
          <button
            className={`btn border m-1
          ${
            value === activeCardsFilter || (!activeCardsFilter && value === 'All')
              ? 'active-filter'
              : ''
          }`}
            onClick={(e) => {
              e.preventDefault();

              if (key === 'all') {
                setActiveCardsFilter('');
              } else {
                setActiveCardsFilter(value);
              }
            }}
            key={id}
          >
            {value}
          </button>
        );
      })}
    </Row>
  );
};

export default PackageInfoCartFilters;
