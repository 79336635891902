import { useEffect, useState } from 'react';
import { Input } from 'reactstrap';

export const PhoneInput = ({
  value,
  className,
  placeholder,
  onChange,
}: any) => {
  const [phoneValue, setPhoneValue] = useState('');

  const formatPhoneNumberProgressive = (input: string) => {
    let cleaned = input.replace(/[^\d]/g, '');
    cleaned = `+${cleaned}`;

    let formatted = cleaned;

    if (formatted.length > 2) {
      formatted = formatted.replace(/^(\+\d)(\d{0,3})/, '$1 $2'); // +9 999
    }
    if (formatted.length > 6) {
      formatted = formatted.replace(/^(\+\d \d{3})(\d{0,3})/, '$1-$2'); // +9 999-999
    }
    if (formatted.length > 10) {
      formatted = formatted.replace(/^(\+\d \d{3}-\d{3})(\d{0,4})/, '$1-$2'); // +9 999-999-9999
    }
    if (formatted.length > 15) {
      formatted = formatted.replace(/^(\+\d \d{3}-\d{3}-\d{4})(\d{0,4})/, '$1-$2'); // +9 999-999-9999-9999
    }

    return formatted.slice(0, 20);
  }
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.trim();
    if (onChange) onChange(formatPhoneNumberProgressive(newValue));
  };
  
  useEffect(() => {
    if (value) {
      setPhoneValue(formatPhoneNumberProgressive(value));
      if (onChange) onChange(formatPhoneNumberProgressive(value));
    } else {
      setPhoneValue('');
    }
  }, [value]);

  return (
    <Input
      className={className}
      placeholder={placeholder}
      value={phoneValue}
      onChange={handleInputChange}
    />
  );
};
