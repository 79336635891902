import { Modal } from 'reactstrap';
import { successMsg } from '../../cache/vars';
import { PUBLISH_OR_UNPUBLISH_ALL_CARDS } from '../../queries/vip-workflow';
import { editableCartTypes, FULLY_EDITABLE_CARDS } from '../../utils/constants';

const ConfirmPublishCardsModal = ({
  isModalOpened,
  setIsModalOpened,
  apolloClient,
  isAllCardsPublished,
  invoiceId,
  setPackageOrderWithDetails,
}: any) => {
  return (
    <Modal
      isOpen={isModalOpened}
      toggle={() => {
        setIsModalOpened(false);
      }}
      className="modal-dialog-centered modal-secondary"
      size="sm"
      fade={false}
    >
      <div className="modal-body">
        <span>The card is declined. Do you want to publish it?</span>
        <div className="text-right mt-2">
          <button
            className="btn btn-sm border-light"
            onClick={async () => {
              const { data } = await apolloClient.mutate({
                mutation: PUBLISH_OR_UNPUBLISH_ALL_CARDS,
                variables: {
                  invoiceId,
                  action: !isAllCardsPublished ? 'publish' : 'unpublish',
                  skipIfCardDeclined: true,
                },
              });

              if (data?.publishOrUnpublichAllCards === 'ok') {
                setPackageOrderWithDetails((prev: any) => {
                  return {
                    ...prev,
                    orderDetails: prev.orderDetails.map((item: any) => {
                      return item.cartDetails?.isServiceDeclined !== 'DECLINED' &&
                        (editableCartTypes.includes(item.group) ||
                          FULLY_EDITABLE_CARDS.includes(item.group))
                        ? {
                            ...item,
                            cartDetails: {
                              ...item.cartDetails,
                              isPublished: isAllCardsPublished ? false : true,
                            },
                          }
                        : item;
                    }),
                  };
                });

                successMsg('All cards published');
              }

              setIsModalOpened(false);
            }}
          >
            No, only un-declined
          </button>
          <button
            className="btn btn-sm btn-primary"
            onClick={async () => {
              const { data } = await apolloClient.mutate({
                mutation: PUBLISH_OR_UNPUBLISH_ALL_CARDS,
                variables: {
                  invoiceId,
                  action: !isAllCardsPublished ? 'publish' : 'unpublish',
                },
              });

              if (data?.publishOrUnpublichAllCards === 'ok') {
                setPackageOrderWithDetails((prev: any) => {
                  return {
                    ...prev,
                    orderDetails: prev.orderDetails.map((item: any) => {
                      return editableCartTypes.includes(item.group) ||
                        FULLY_EDITABLE_CARDS.includes(item.group)
                        ? {
                            ...item,
                            cartDetails: {
                              ...item.cartDetails,
                              isPublished: isAllCardsPublished ? false : true,
                            },
                          }
                        : item;
                    }),
                  };
                });

                successMsg('All cards published');
              }

              setIsModalOpened(false);
            }}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmPublishCardsModal;
