import { useApolloClient } from '@apollo/client';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';

const UploadTickets = ({ setIsUploadTicketsModalOpened }: any) => {
  return (
    <Card style={{ marginBottom: '15px' }}>
      <CardHeader>
        <Row className="ml-0 mr-0 ml-2">
          <h2 className="m-0">Tickets</h2>
        </Row>
      </CardHeader>
      <CardBody className="d-flex flex-column">
        <Row className="ml-0 mr-0 ml-2 d-flex justify-content-center">
          <button
            className="btn btn-primary w-75 text-uppercase"
            onClick={async () => {
              setIsUploadTicketsModalOpened(true);
            }}
          >
            Manage Tickets
          </button>
        </Row>
      </CardBody>
    </Card>
  );
};

export default UploadTickets;
