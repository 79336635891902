import { useQuery } from '@apollo/client';
import './getTickets.scss';
import { GET_LATEST_CRON_INFO } from '../../queries/ticket';
import moment from 'moment';
import 'moment-timezone';
import './cronInfoTable.scss';
import { DatePicker } from 'antd';

import { Col, Row, Spinner } from 'reactstrap';
import { useEffect, useState } from 'react';

const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

const CronInfoTable = () => {
  const [filterDateRange, setFilterDateRange] = useState([
    moment().format(dateFormat),
    moment().format(dateFormat),
  ]);
  
  const [compareDates, setCompareDates] = useState(true);
  
  const { data, refetch, loading: isLoading } = useQuery(GET_LATEST_CRON_INFO, {
    variables: {
      startDate: filterDateRange[0],
      endDate: filterDateRange[1],
    },
  });
  
  const handleDateChange = (dates: any) => {
    if (dates && dates?.length === 2) {
      const [start, end] = dates;
      setFilterDateRange([
        moment(start).format(dateFormat),
        moment(end).format(dateFormat),
      ]);
    } else {
      console.error('Invalid date range selected');
    }
  };
  
  const handleCronInfo = (data: string) => {
    const cronUTC = moment(data, 'YYYY-MM-DD, hh:mm a').utc(true).toDate();
    return moment(cronUTC).format("YYYY-MM-DD, hh:mm a");
  };
  
  useEffect(() => {
    refetch({
      startDate: filterDateRange[0],
      endDate: filterDateRange[1],
    });
    if (filterDateRange?.length) {
      const [start, end] = filterDateRange;
      const date1Local = moment(start, dateFormat).startOf('day').toDate();
      const date2Local = moment(end, dateFormat).startOf('day').toDate();
      setCompareDates(!moment(date1Local).diff(moment(date2Local)));
    }
  }, [filterDateRange]);
  
  const inlineLoader = (
    <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
  );
  
  return (
    <div className="cron-info-box pl-4 pr-4 pt-3 pb-3">
      <Row className="d-flex justify-content-between align-items-center">
        <Col className="">
          <h1 className="m-0 text-white">Script Info</h1>
        </Col>
        <Col className="">
          <RangePicker
            defaultValue={[moment(), moment()]}
            format={dateFormat}
            onChange={handleDateChange}
            className="w-100"
            ranges={{
              'Last 7 Days': [
                moment().subtract(7, 'days').startOf('day'),
                moment().endOf('date'),
              ],
              'Last Month': [
                moment().subtract(30, 'days').startOf('day'),
                moment().endOf('date'),
              ],
              'Last Three Months': [
                moment().subtract(120, 'days').startOf('day'),
                moment().endOf('date'),
              ],
              'Last Half Year': [
                moment().subtract(180, 'days').startOf('day'),
                moment().endOf('date'),
              ],
              'Last Year': [
                moment().subtract(365, 'days').startOf('day'),
                moment().endOf('date'),
              ],
            }}
          />
        </Col>
      </Row>
      <div className="mt-2">
        <table className="w-100">
          <tbody>
          <tr>
            <td width="2%" className="text-center">
              <i className="fa fa-light fa-lg fa-calendar mr-2" />
            </td>
            <td>
            <span>
              Start time -{' '} 
              {!compareDates && ' This data is available for a 1 day period.'}
                {data?.getLatestCronInfo?.cronStartDate && compareDates
                  ? handleCronInfo(data.getLatestCronInfo.cronStartDate)
                  : ''}
            </span>
            </td>
          </tr>
          <tr>
            <td width="2%" className="text-center">
              <i className="fa fa-light fa-lg fa-calendar mr-2" />
            </td>
            <td>
              <span>
                End time -{' '}  
                {!compareDates && ' This data is available for 1 day period.'}
                {data?.getLatestCronInfo?.cronEndDate && compareDates
                  ? handleCronInfo(data?.getLatestCronInfo?.cronEndDate)
                  : ''}
              </span>
            </td>
          </tr>
            <tr>
              <td width="2%" className="text-center">
                <i className="fa fa-regular fa-lg fa-clock mr-2"></i>
              </td>
              <td>
                <span>
                  Total run time -{' '}
                  {!compareDates && ' This data is available for 1 day period.'}
                  {data?.getLatestCronInfo?.totalRunTime && compareDates ? 
                    `${moment.utc(data.getLatestCronInfo.totalRunTime).format('mm')} min ${moment.utc(data.getLatestCronInfo.totalRunTime).format('ss')} sec` : !compareDates ? '' : ' 0 sec'}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="line mt-4 mb-4"></div>
      <div className="cron-info-data">
        <div className="data-row">
          <div className="data-cell">
            <i className="fa-regular fa-lg fa-rotate mr-2"></i>
            <span>Total Tickets Scanned -{' '} 
              {isLoading 
              ? <>{inlineLoader}</>
              : (data?.getLatestCronInfo?.totalTicketsScanned || 0)}
           </span>
          </div>
          <div className="data-cell">
            <i className="fa-regular fa-lg fa-triangle-exclamation mr-2"></i> 
            
            <span>Total Not Transferrable -{' '} 
              {isLoading ?
              <>{inlineLoader}</>
              : (data?.getLatestCronInfo?.totalNotTransferable || 0)}</span>
          </div>
        </div>
        <div className="data-row">
          <div className="data-cell">
            <i className="fa fa-lg fa-regular fa-circle-check mr-2"></i>
            <span>Total Emailed -{' '} 
              {isLoading
              ? <>{inlineLoader}</>
              : (data?.getLatestCronInfo?.totalEmailed || 0)}
            </span>
          </div>
          <div className="data-cell">
            <i className="fa fa-regular fa-lg fa-circle-xmark mr-2"></i>
            <span>Total Failed -{' '} 
              {isLoading
              ? <>{inlineLoader}</>
              : (data?.getLatestCronInfo?.totalFailed || 0)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CronInfoTable;
