import React from 'react';
import routes from '../../Router';
import Header from '../../components/Header/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import { useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { GET_PROFILE } from '../../queries/auth';

export const CommonLayout = React.memo(({ children }) => {
  const client = useApolloClient();

  const [sidenavOpen, setSidenavOpen] = React.useState(true);

  const location = useLocation();
  const mainContentRef = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    if (document.scrollingElement) {
      document.scrollingElement.scrollTop = 0;
    }
    document.body.classList.remove('g-sidenav-pinned');
    document.body.classList.add('g-sidenav-hidden');
  }, [location]);

  // React.useEffect(() => {
  //   client.query({
  //     query: GET_PROFILE,
  //     fetchPolicy: 'cache-first',
  //   });
  // }, []);

  const toggleSidenav = () => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  };

  return (
    <>
      <Sidebar
        routes={routes}
        sidenavOpen={sidenavOpen}
        toggleSidenav={toggleSidenav}
        logo={{
          innerLink: '/',
          imgSrc: require('../../assets/img/brand/argon-react.png'),
          imgAlt: '...',
        }}
      />
      <div className="main-content" ref={mainContentRef}>
        <Header />
        {children}
      </div>
    </>
  );
});
