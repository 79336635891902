import React from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, CardHeader, Col, Container, Row, UncontrolledTooltip } from 'reactstrap';
import AdminNavbar from '../../components/Navbar/Navbar';
import ManageUsersTable from '../../components/ManageUsersTable/ManageUsersTable';
import { TABS, TABS_LABELS } from '../../utils/constants';
import { userRole } from '../../cache/vars';
import CreateAdminModal from '../../components/Modals/CreateAdminModal';
import EditAdminModal from '../../components/Modals/EditAdminModal';
import { useReactiveVar } from '@apollo/client';

const getAdminTabsLayout = (
  currentTab: string,
  setIsEditAdminModalOpened: any,
  setUserToEdit: any,
  userRoleValue: string,
  adminsList: any,
  setAdminsList: any,
) => {
  const adminTabs = {
    [TABS_LABELS.MANAGE_USERS]: (
      <ManageUsersTable
        setIsEditAdminModalOpened={setIsEditAdminModalOpened}
        setUserToEdit={setUserToEdit}
        userRoleValue={userRoleValue}
        adminsList={adminsList}
        setAdminsList={setAdminsList}
      />
    ),
  } as const;

  return adminTabs[currentTab];
};

const AdminPage: React.FC = () => {
  const userRoleValue = useReactiveVar(userRole);

  const location = useLocation();
  const currentSection: string = location.pathname.slice(1).toUpperCase();

  const [currentTab, setCurrentTab] = React.useState(TABS[currentSection as keyof typeof TABS][0]);
  const [isCreateAdminModalOpened, setIsCreateAdminModalOpened] = React.useState(false);
  const [isEditAdminModalOpened, setIsEditAdminModalOpened] = React.useState(false);
  const [userToEdit, setUserToEdit] = React.useState({});
  const [adminsList, setAdminsList] = React.useState([]);

  // const [currentTabKey, setCurrentTabKey] = React.useState<string>(
  //   Object.entries(TABS_LABELS)
  //     .filter(([key, value]) => value === currentTab)
  //     .flat()[0],
  // );

  React.useEffect(() => {
    setCurrentTab(TABS[currentSection as keyof typeof TABS][0]);
  }, [location]);

  const handleCurrentTab = (title: string) => {
    setCurrentTab(title);
    // setCurrentTabKey(
    //   Object.entries(TABS_LABELS)
    //     .filter(([key, value]) => value === title)
    //     .flat()[0],
    // );
  };

  return (
    <>
      {isCreateAdminModalOpened ? (
        <CreateAdminModal
          isModalOpened={isCreateAdminModalOpened}
          setIsModalOpened={setIsCreateAdminModalOpened}
          setAdminsList={setAdminsList}
        />
      ) : null}
      {isEditAdminModalOpened ? (
        <EditAdminModal
          isModalOpened={isEditAdminModalOpened}
          setIsModalOpened={setIsEditAdminModalOpened}
          userToEdit={userToEdit}
          setUserToEdit={setUserToEdit}
          setAdminsList={setAdminsList}
        />
      ) : null}
      <AdminNavbar
        handleCurrentTab={handleCurrentTab}
        currentTab={currentTab}
        currentSection={currentSection}
      />
      <Container className="mt-4" fluid>
        <Card>
          <CardHeader className="border-0">
            <Row className="align-items-center">
              <Col xs="10">
                <h3 className="mb-0">{currentTab}</h3>
              </Col>
              {userRoleValue === 'SUPER_ADMIN' ? (
                <Col className="text-right" xs="2">
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    onClick={(e) => {
                      setIsCreateAdminModalOpened(true);
                    }}
                    size="sm"
                  >
                    + Create User
                  </Button>
                </Col>
              ) : null}
            </Row>
          </CardHeader>
          {getAdminTabsLayout(
            currentTab,
            setIsEditAdminModalOpened,
            setUserToEdit,
            userRoleValue,
            adminsList,
            setAdminsList,
          )}
        </Card>
      </Container>
    </>
  );
};

export default AdminPage;
