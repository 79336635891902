import { Button, Col, Modal, Row } from 'reactstrap';
import { isNumeric } from '../../../utils/services';
import CloseModalButton from '../../Buttons/CloseModalButton';

const FiltersModal = ({
  isFiltersModalOpen,
  setIsFiltersModalOpen,
  tableConfig,
  filterState,
  setFilterState,
  handleApplyFilters,
  handleClearFilters,
}: any) => {
  return (
    <Modal
      isOpen={isFiltersModalOpen}
      toggle={() => {
        setIsFiltersModalOpen(false);
      }}
      className="modal-dialog-centered modal-secondary"
      size="md"
      fade={false}
    >
      <div className="modal-body pb-0">
        <div className="d-flex justify-content-between ">
          <div>
            <h1>Filter</h1>
          </div>
          <div>
            <CloseModalButton
              handleCloseModalButtonClick={(e) => {
                e.preventDefault();
                setIsFiltersModalOpen(false);
              }}
            />
          </div>
        </div>
        <form className="new-event--form mt-2" onSubmit={handleApplyFilters}>
          {Object.entries(tableConfig).map(([key, config]: any, id) => (
            <Row key={id} className="mb-2 ">
              <Col sm="4">
                <label className="form-control-label">{config.colTitle}</label>
              </Col>
              <Col sm="8">
                {key === 'type' ? (
                  <select
                    style={{ width: '85%', height: '31px' }}
                    onChange={(e) => {
                      setFilterState((state: any) => {
                        if (e.target.value === '') {
                          delete state[config.key];

                          return { ...state };
                        }

                        return {
                          ...state,
                          [config.key]: e.target.value,
                        };
                      });
                    }}
                    value={(filterState[config.key] && filterState[config.key]) || ''}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleApplyFilters(e);
                      }
                    }}
                  >
                    <option></option>
                    <option value="CONCERT">CONCERT</option>
                    <option value="SPORT">SPORT</option>
                    <option value="OTHER">OTHER</option>
                  </select>
                ) : (
                  <input
                    style={{ width: '85%' }}
                    value={(filterState[config.key] && filterState[config.key]) || ''}
                    onChange={(e) => {
                      setFilterState((state: any) => {
                        if (e.target.value === '') {
                          delete state[config.key];

                          return { ...state };
                        }

                        return {
                          ...state,
                          [config.key]:
                            key === 'saleDate' || key === 'eventDate' || key === 'shipBy'
                              ? e.target.value
                              : isNumeric(e.target.value)
                              ? Number.parseFloat(e.target.value)
                              : e.target.value.toLowerCase(),
                        };
                      });
                    }}
                  />
                )}
              </Col>
            </Row>
          ))}
          <div className="modal-footer pt-0 mt-4">
            <Button
              className="new-event--add text-uppercase"
              color="primary"
              type="submit"
              disabled={!Object.keys(filterState).length}
            >
              Apply
            </Button>
            <Button
              className="new-event--add text-uppercase"
              color="primary"
              onClick={handleClearFilters}
            >
              Clear
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FiltersModal;
