import { gql } from '@apollo/client';

export const GET_ADMIN_USERS = gql`
  query adminUsersList(
    $page: Float!
    $sortBy: String!
    $sortDirection: String!
    $itemsPerPage: Float!
  ) {
    adminUsersList(
      page: $page
      sortBy: $sortBy
      sortDirection: $sortDirection
      itemsPerPage: $itemsPerPage
    ) {
      totalUsersCount
      totalPageCount
      users {
        email
        userId
        username
        createdAt
        role
      }
    }
  }
`;

export const GET_CSR_LIST = gql`
  query getCsrList {
    getCsrList {
      username
      csrId
    }
  }
`;

export const USERS_LIST = gql`
  query usersList(
    $page: Float!
    $itemsPerPage: Float!
    $sortBy: String
    $sortDirection: String
    $filter: UsersFilterInput
  ) {
    usersList(
      page: $page
      itemsPerPage: $itemsPerPage
      sortBy: $sortBy
      sortDirection: $sortDirection
      filter: $filter
    ) {
      totalPageCount
      count
      clientData {
        userId
        email
        role
        createdAtDate
        phoneNumber
        username
        brokerId
        clientBrokerName
        associatedEmails {
          id
          assignedEmail
        }
      }
    }
  }
`;

export const EDIT_USER = gql`
  query editUser($updatedUserInput: UpdatedUserInput!) {
    editUser(updatedUserInput: $updatedUserInput) {
      userId
      email
      role
      createdAtDate
      phoneNumber
      username
      brokerId
      clientBrokerName
      associatedEmails {
        id
        assignedEmail
      }
    }
  }
`;

export const DELETE_USER = gql`
  query deleteUser($userId: Float!) {
    deleteUser(userId: $userId)
  }
`;

export const SAVE_USERS_ASSOC = gql`
  mutation newUsersAssoc($userId: Float!, $recipientEmail: String!) {
    newUsersAssoc(userId: $userId, recipientEmail: $recipientEmail) {
      assignedEmail
      id
    }
  }
`;

export const DELETE_USERS_ASSOC = gql`
  mutation deleteUsersAssoc($id: Float!) {
    deleteUsersAssoc(id: $id)
  }
`;

export const EDIT_ADMIN_USER = gql`
  mutation editAdminUser($updatedAdminUserInput: UpdatedAdminUserInput!) {
    editAdminUser(updatedAdminUserInput: $updatedAdminUserInput) {
      email
      role
      username
      userId
      createdAt
    }
  }
`;

export const CREATE_ADMIN_USER = gql`
  mutation createNewAdmin($newAdminUserInput: RegisterInput!) {
    createNewAdmin(newAdminUserInput: $newAdminUserInput) {
      email
      role
      username
      userId
      createdAt
    }
  }
`;
