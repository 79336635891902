export const invoices = [
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
  {
    invoiceId: 12321,
    saleDate: '10/12/2022',
    client: 'TND',
    eventName: 'The Music Man',
    venue: 'Gold Fan',
    eventDate: '2011/04/25',
    quantity: 2,
    shipBy: '2022/12/12',
    notes: 'Lorem ipsum',
    invoiceTotal: '$123.00',
    listPrice: '%123.00',
    budget: '$123.00',
    authorize: 'Authorized',
    validated: 'Pending',
  },
];

export const users = [
  {
    id: 1,
    user: 'lenasiegendorf',
    email: 'lena@premiumseatsusa.com',
    createdAt: '2019-12-12',
    isEmailVerified: 'true',
    isActive: 'true',
    hasRole: 'true',
  },
  {
    id: 2,
    user: 'lenasiegendorf',
    email: 'lena@premiumseatsusa.com',
    createdAt: '2019-12-12',
    isEmailVerified: 'true',
    isActive: 'true',
    hasRole: 'true',
  },
  {
    id: 3,
    user: 'lenasiegendorf',
    email: 'lena@premiumseatsusa.com',
    createdAt: '2019-12-12',
    isEmailVerified: 'true',
    isActive: 'true',
    hasRole: 'true',
  },
  {
    id: 4,
    user: 'lenasiegendorf',
    email: 'lena@premiumseatsusa.com',
    createdAt: '2019-12-12',
    isEmailVerified: 'true',
    isActive: 'true',
    hasRole: 'true',
  },
  {
    id: 5,
    user: 'lenasiegendorf',
    email: 'lena@premiumseatsusa.com',
    createdAt: '2019-12-12',
    isEmailVerified: 'true',
    isActive: 'true',
    hasRole: 'true',
  },
  {
    id: 6,
    user: 'lenasiegendorf',
    email: 'lena@premiumseatsusa.com',
    createdAt: '2019-12-12',
    isEmailVerified: 'true',
    isActive: 'true',
    hasRole: 'true',
  },
  {
    id: 7,
    user: 'lenasiegendorf',
    email: 'lena@premiumseatsusa.com',
    createdAt: '2019-12-12',
    isEmailVerified: 'true',
    isActive: 'true',
    hasRole: 'true',
  },
];
