import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import AdminNavbar from '../../components/Navbar/Navbar';
import { TABS, TABS_LABELS } from '../../utils/constants';
import { useLocation } from 'react-router-dom';
import { GenerateReport } from '../../components/ReportComponents/GenerateReport';
import { ManageUserReports } from '../../components/ReportComponents/ManageUserReports';

const reportsTabs = (currentTabKey: string, currentTab: string) => {
  return {
    [TABS_LABELS.GENERATE_REPORT]: <GenerateReport />,
    [TABS_LABELS.MANAGE_USER_REPORTS]: (
      <ManageUserReports currentTabKey={currentTabKey} currentTab={currentTab} />
    ),
  };
};

const ReportsPage: React.FC = () => {
  const location = useLocation();
  const currentSection: string = location.pathname.slice(1).toUpperCase();

  const [currentTab, setCurrentTab] = React.useState(TABS[currentSection as keyof typeof TABS][0]);
  const [currentTabKey, setCurrentTabKey] = React.useState<string>(
    Object.entries(TABS_LABELS)
      .filter(([key, value]) => value === currentTab)
      .flat()[0],
  );

  React.useEffect(() => {
    setCurrentTab(TABS[currentSection as keyof typeof TABS][0]);
  }, [location]);

  const handleCurrentTab = (title: string) => {
    setCurrentTab(title);
    setCurrentTabKey(
      Object.entries(TABS_LABELS)
        .filter(([key, value]) => value === title)
        .flat()[0],
    );
  };

  return (
    <>
      <AdminNavbar
        handleCurrentTab={handleCurrentTab}
        currentTab={currentTab}
        currentSection={currentSection}
      />
      <Container className="mt-4" fluid>
        <Row className="align-items-center">
          <Col xs="6">
            <h1 className="mb-0">{currentTab}</h1>
          </Col>
        </Row>
        <Container className="align-items-center mt-6">
          {reportsTabs(currentTabKey, currentTab)[currentTab]}
        </Container>
      </Container>
    </>
  );
};

export default ReportsPage;
