export const DailyPricingReport: React.FC = () => {
  return (
    <ul>
      <li>
        Pull all events with the following -
        <ul>
          <li>If it is Monday or Friday - Event Date between today and 42 days</li>
          <li>Other Days - Event Date between today and 21 Days</li>
          <li>Event date is 91, 121,151, 181, 211, 241, 271, 301, 331, or 361 days out.</li>
          <li>Purchase date is between today and 21 Days</li>
          <li>
            Has to have inventory with section or row that does not contains either 'Gift' Or 'Card'
          </li>
          <li>
            Has to have Ticket Group Code that is not in this list
            'NLA','CAT','CATNW','CATNWTG','CATFB','CATFBTG','FILLCONCERTS','FILLSPORTS', 'NWSPORTS',
            'PSCONCERTS', 'CONSIGN','LP','AM','LM','CJ','NWSPORTSTG'
          </li>
          <li>Has to have Ticket Group code that is not Empty</li>
          <li>Has to include Non Category Inventory</li>
          <li>If Event is within 1 week, highlight Yellow</li>
        </ul>
        <p></p>
        <h4>Stale Tab</h4>
      </li>
      <li>Find most recent price update date for each event</li>
      <li>Ignore events on Daily Pricing tab</li>
      <li>
        Show records If
        <ul>
          <li>
            event date is in the next 14 days and was most recently priced more than 1 day or never
          </li>
          <li>
            event date is between 15 and 21 days and was most recently priced more than 2 days or
            never
          </li>
          <li>
            event date is between 31 and 45 days and was most recently priced more than 4 days or
            never
          </li>
          <li>
            event date is between 46 and 90 days and was most recently priced more than 13 days or
            never
          </li>
          <li>
            event date is between 91 and 98 days and was most recently priced more than 30 days or
            never
          </li>
          <li>
            event date is greater than 91 days and was most recently priced more than 30 days or
            never
          </li>
          <li>Purchase date is in the next 21 days</li>
        </ul>
      </li>
      <li>
        Has to have inventory with section or row that does not contains either 'Gift' Or 'Card'
      </li>
      <li>
        Has to have Ticket Group Code that is not in this list
        'NLA','CAT','CATNW','CATNWTG','CATFB','CATFBTG','FILLCONCERTS','FILLSPORTS', 'NWSPORTS',
        'PSCONCERTS', 'CONSIGN','LP','AM','LM','CJ','NWSPORTSTG'
      </li>
      <li>Has to have Ticket Group code that is not Empty</li>
      <li>
        Has to include Non Category Inventory
        <p></p>
        <h4>Specific TG Code Tabs</h4>
      </li>
      <li>
        For both Daily and Stale, it will follow the same logic as Daily above, except it will be
        looking only for that specific Ticket Group Code
      </li>
      <li>
        The Stale will exclude inventory from the Daily tab only for the specific Ticket Group Code
        <p></p>
        <h4>1 Week Tab</h4>
      </li>
      <li>
        This will include the same data as the Daily Tab, but will include all TG Codes from this
        report. It will only show the inventory that is highlighted in the other tabs, which are
        events that are within 1 week.
        <p></p>
        <h4>Schedule</h4>
      </li>
      <li>
        Report is run daily at 7am Eastern and is delivered to recipients managed online{' '}
        <a href="https://psusa.premiumseatsusa.com/psusa/reports/manage/ManageReportRecipients.php">
          Here
        </a>
      </li>
    </ul>
  );
};
