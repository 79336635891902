import { gql } from '@apollo/client';

export const RECIPIENTS = gql`
  query recipients(
    $page: Float!
    $itemsPerPage: Float!
    $sortBy: String
    $sortDirection: String
    $search: String
    $isHideEmpty: Boolean
  ) {
    recipients(
      page: $page
      itemsPerPage: $itemsPerPage
      sortBy: $sortBy
      sortDirection: $sortDirection
      search: $search
      isHideEmpty: $isHideEmpty
    ) {
      totalPageCount
      count
      clientData {
        name
        clientId
        email
        phoneNumber
        packagesCount
        totalSales
      }
    }
  }
`;

export const AUTOCOMPLETE_RECIPIENTS = gql`
  query recipientsAutocomplete($recipientEmail: String!, $userEmail: String!) {
    recipientsAutocomplete(recipientEmail: $recipientEmail, userEmail: $userEmail) {
      email
    }
  }
`;
