import { RouteItemInterface } from './utils/interfaces';

const routes: Array<RouteItemInterface> = [
  {
    path: '/vip-workflow',
    name: 'VIP Workflow',
    icon: 'fas fa-stream text-green',
  },
  {
    path: '/clients',
    name: 'Clients/Recipients',
    icon: 'fas fa-user text-yellow',
  },
  {
    path: '/reports',
    name: 'Reports',
    icon: 'fas fa-chart-pie text-blue',
  },
  {
    path: '/docs',
    name: 'Docs',
    icon: 'fas fa-file-invoice text-default',
  },
  {
    path: '/admin',
    name: 'Admin',
    icon: 'fas fa-cogs text-red',
  },
  {
    path: '/tickets-dashboard',
    name: 'Tickets Dashboard',
    icon: 'fas fa-ticket text-green',
  },
];

export default routes;
