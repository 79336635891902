import { makeVar } from '@apollo/client';

export const errorMsg = makeVar('');

export const infoMsg = makeVar('');

export const isTableDataLoading = makeVar(false);

export const clientsFilter = makeVar<any>(null);

export const successMsg = makeVar('');

export const userRole = makeVar('');
