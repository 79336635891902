import {
    Card,
    CardBody,
    Container,
  } from 'reactstrap';
  import './getTickets.scss';
  import React, { useState } from 'react';
  import NestedTransferInfoTable from './NestedTransferInfoTable';
  import NestedTransferLogsTable from './NestedTransferLogsTable';
  import { GET_PACKAGE_PO_FROM_POS_INFO_FOR_TICKETS_DASHBOARD, GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD } from '../../queries/ticket';
  import { useMutation, useQuery } from '@apollo/client';
import { successMsg } from '../../cache/vars';
  
  type Props = {
    isDetailsOpened: number[];
    invoiceId: number;  
    editable: boolean;
  };

  const TicketCardDetails: React.FC<Props> = ({ isDetailsOpened, invoiceId, editable }) => {
    const [isShowTransferLogsTable, setIsShowTransferLogsTable] = React.useState(false);
    const [isEditing, setIsEditing] = React.useState(false);
    const [editData, setEditData] = React.useState([]);
    const [selectedTicketGroupId, setSelectedTicketGroupId] = React.useState<number[]>([]);
    const [isEditable, setIsEditable] = React.useState(editable);
    
    const { data: ticketGroupData } = useQuery(GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD, {
      variables: { invoiceIdList: [invoiceId] },
    });
  
    const { data, loading, error } = useQuery(GET_PACKAGE_PO_FROM_POS_INFO_FOR_TICKETS_DASHBOARD, {
      variables: { ticketGroupId: selectedTicketGroupId },
      skip: !isEditing, 
      onCompleted(data) {
        if (data && data.ticketGroup) {
          const newEditData = data.ticketGroup.map((item: any) => {
            const { __typename, tickets, ...ticketGroup } = item;
            return {...ticketGroup, tickets: tickets.map((el: any) => {
              const { __typename, ...ticket } = el;
              return ticket;
            })};
          });
          setEditData(newEditData);
        }
      },
      onError(err) {
        console.error("Error fetching edit data:", err.message);
      }
    });
    
    // const [putTicketGroup] = useMutation(PUT_PACKAGE_PO_FROM_POS_INFO_FOR_TICKETS_DASHBOARD, {
    //   variables: { ticketGroup: editData },
    //   refetchQueries: [],
    //   onCompleted(data) {
    //     successMsg('Information successfully updated.');
    //   },
    // });
    
    const handleEditClick = () => {
      if (ticketGroupData && ticketGroupData?.getPackagePoInfoForTicketsDahboard) {
        const parsedData = JSON.parse(ticketGroupData.getPackagePoInfoForTicketsDahboard);
        const firstKey = Object.keys(parsedData)[0];
        const ticketGroupId: number[] = [];
        const uniqueSet = new Set();
        parsedData[firstKey].forEach((obj: { [x: string]: number; }) => {
          const value = obj['ticketGroupId'];
          if (!uniqueSet.has(value)) {
            uniqueSet.add(value);
            ticketGroupId.push(obj['ticketGroupId']);
          }
        });

        if (ticketGroupId?.length) {
          setIsEditing(true);
          setSelectedTicketGroupId([...ticketGroupId]);
        }
      }
    };
    
    const handleSaveChanges = () => {
      // putTicketGroup();
      setIsEditing(false);
    };
  
    const handleCancelEditing = () => {
      setIsEditing(false);
    };
    
    return (
      <Container className="" fluid>
        <Card className="m-0">
        <div className="pl-4 pr-4 pt-2 text-left d-flex align-items-center justify-content-between">
          <div className="d-flex w-25" style={{border: '1px solid #dfe5ec', borderRadius: '100px'}}>
            <button
              className={`tab-left`}
              style={{
                borderRadius: '100px',
                border: !isShowTransferLogsTable
                ? '1px solid #dfe5ec'
                : 'none',
                background: !isShowTransferLogsTable
                  ? 'rgb(206, 66, 117)'
                  : '#ffffff',
                color: !isShowTransferLogsTable ? '#ffffff' : '',
              }}
              onClick={(e) => {
                e.preventDefault();
                setIsShowTransferLogsTable(false);
              }}
            >
              PO Information
            </button>
            <button
              className={`tab-right `}
              style={{
                borderRadius: '100px',
                border: isShowTransferLogsTable
                ? '1px solid #dfe5ec'
                : 'none',
                background: isShowTransferLogsTable
                  ? 'rgb(206, 66, 117)'
                  : '#ffffff',
                color: isShowTransferLogsTable ? '#ffffff' : '',
              }}
              onClick={(e) => {
                e.preventDefault();
                setIsShowTransferLogsTable(true);
              }}
            >
              Transfer Logs
            </button>
          </div>
          { !isEditing && !isShowTransferLogsTable && isEditable && (
            <div className="align-items-end">
              <button
                disabled={isEditing}
                style={{ border: 'none', background: 'none' }}
                className="text-primary p-0 ml-2"
                onClick={handleEditClick}
              >
                <i className="fa fa-lg fa-light fa-pen"></i>
              </button>
            </div>
          )}
          {isEditing && !isShowTransferLogsTable && isEditable &&(
            <div className="align-items-end">
              <button className="btn btn-sm ml-2 m-0 text-primary border-primary" onClick={handleSaveChanges}>Save</button>
              <button className="btn btn-sm ml-2 m-0 text-primary border-primary" onClick={handleCancelEditing}>Cancel</button>
            </div>
          )}
          </div>

          <CardBody>
          {!isShowTransferLogsTable ?
            <NestedTransferInfoTable
              invoiceIdList={isDetailsOpened}
              isDetailsOpened={isDetailsOpened?.includes(invoiceId)}
              openedTransfer={invoiceId}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              editData={editData}
              setEditData={setEditData}
              setEditable={setIsEditable}
            /> : 
            <NestedTransferLogsTable 
              invoiceIdList={isDetailsOpened}
              isDetailsOpened={isDetailsOpened?.includes(invoiceId)}
              openedTransfer={invoiceId}
            />}
          </CardBody>
        </Card>
      </Container>
    );
  };
  
  export default TicketCardDetails;
  