const CloseModalButton = ({
  handleCloseModalButtonClick,
}: {
  handleCloseModalButtonClick: (e: any) => void;
}) => {
  return (
    <button style={{ background: 'none', border: 'none' }} onClick={handleCloseModalButtonClick}>
      <i className="fas fa-times" aria-hidden="true" />
    </button>
  );
};

export default CloseModalButton;
