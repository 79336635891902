import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Form, FormFeedback, FormGroup, Input, Modal } from 'reactstrap';
import * as Yup from 'yup';
import { successMsg } from '../../cache/vars';
import { EDIT_ADMIN_USER, GET_ADMIN_USERS } from '../../queries/users';
import { ADMIN_ROLES } from '../../utils/constants';
import CloseModalButton from '../Buttons/CloseModalButton';

const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string().optional(),
  email: Yup.string().email().required('Required'),
  role: Yup.string().oneOf(ADMIN_ROLES).required('Required'),
  password: Yup.string().min(8).max(32).optional(),
});

const EditAdminModal = ({
  isModalOpened,
  setIsModalOpened,
  userToEdit,
  setUserToEdit,
  setAdminsList,
}: any) => {
  const [editAdminUser] = useMutation(EDIT_ADMIN_USER, {
    update(cache, { data: { editAdminUser } }) {
      setAdminsList((prev: any) => {
        return prev.map((item: any) =>
          item.userId === editAdminUser.userId ? editAdminUser : item,
        );
      });
    },
  });

  return (
    <Modal
      isOpen={isModalOpened}
      toggle={() => {
        setIsModalOpened(false);
        setUserToEdit({});
      }}
      className="modal-dialog-centered modal-secondary"
      size="md"
      fade={false}
    >
      <div className="modal-body pb-0">
        <div className="d-flex justify-content-between ">
          <div>
            <h1>Edit User</h1>
          </div>
          <div>
            <CloseModalButton
              handleCloseModalButtonClick={(e) => {
                e.preventDefault();
                setIsModalOpened(false);
              }}
            />
          </div>
        </div>
        <Formik
          initialValues={{
            email: userToEdit.email,
            role: userToEdit.role,
            username: userToEdit.username,
            password: userToEdit.password,
          }}
          onSubmit={async (userInfo) => {
            editAdminUser({
              variables: {
                updatedAdminUserInput: {
                  ...userInfo,
                  userId: userToEdit.userId,
                },
              },
            });

            setIsModalOpened(false);
            successMsg('Saved');
          }}
          validationSchema={VALIDATION_SCHEMA}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            } = props;

            return (
              <Form role="form" onSubmit={handleSubmit}>
                <FormGroup>
                  <Input
                    type="text"
                    name="username"
                    placeholder="User Name"
                    onChange={handleChange}
                    value={values.username}
                    onBlur={handleBlur}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    invalid={!!errors.email && !!touched.email}
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email ? (
                    <FormFeedback style={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}>
                      Wrong email format
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Input
                    className="form-control-alternative new-event--title"
                    type="select"
                    name="role"
                    placeholder="Role"
                    onChange={handleChange}
                    value={values.role}
                    onBlur={handleBlur}
                  >
                    {ADMIN_ROLES.map((role: string, id) => {
                      return (
                        <option value={role} key={id}>
                          {role}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Input
                    type="password"
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    placeholder="New Password"
                    invalid={!!errors.password && !!touched.password}
                    onBlur={handleBlur}
                  />
                  {errors.password && touched.password ? (
                    <FormFeedback style={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}>
                      Password should contain min 8 and max 32 symbols
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <div className="text-right">
                  <Button
                    className="my-4"
                    color="info"
                    type="submit"
                    disabled={!dirty && isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default EditAdminModal;
