import { useApolloClient } from '@apollo/client';
import { Switch } from 'antd';
import React from 'react';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { successMsg } from '../../cache/vars';
import PublishedLabel from '../../components/labels/PublishedLabel';
import ConfirmPublishCardsModal from '../../components/Modals/ConfirmPublishCardsModal';
import { PUBLISH_OR_UNPUBLISH_ALL_CARDS, RESET_CARDS_ORDER } from '../../queries/vip-workflow';
import { editableCartTypes, FULLY_EDITABLE_CARDS } from '../../utils/constants';

const PublishAll = ({
  invoiceId,
  orderDetails,
  setPackageOrderWithDetails,
  setIsCreateNewCardModalOpened,
  resetCardsFilter
}: any) => {
  const client = useApolloClient();

  const [isConfirmPublishCardsModalOpened, setIsConfirmPublishCardsModalOpened] =
    React.useState(false);

  const isAllCardsPublished = orderDetails?.every((el: any) => {
    return !!el.cartDetails?.isPublished;
  });

  return (
    <Card>
      {isConfirmPublishCardsModalOpened ? (
        <ConfirmPublishCardsModal
          isModalOpened={isConfirmPublishCardsModalOpened}
          setIsModalOpened={setIsConfirmPublishCardsModalOpened}
          apolloClient={client}
          isAllCardsPublished={isAllCardsPublished}
          invoiceId={invoiceId}
          setPackageOrderWithDetails={setPackageOrderWithDetails}
        />
      ) : null}
      <CardHeader>
        <Row className="ml-0 mr-0 ml-2 d-flex justify-content-between">
          <h2 className="m-0">Cards</h2>
          <button
            className="btn btn-sm btn-primary"
            onClick={() => {
              setIsCreateNewCardModalOpened(true);
            }}
          >
            Add New Card
          </button>
        </Row>
      </CardHeader>
      <CardBody>
        <Row className="mr-0 ml-2 d-flex align-items-center">
          <div className="d-flex">
            <h3 className="mb-0 mr-2">Mark All Cards</h3>
            <PublishedLabel isPublished={isAllCardsPublished} />
            <h3 className="mb-0 ml-1">?</h3>
          </div>
          <Switch
            className="ml-3"
            checked={isAllCardsPublished}
            onClick={async () => {
              const isPackageHasDeclinedCards = orderDetails.some((item: any) => {
                return item.cartDetails?.isServiceDeclined === 'DECLINED';
              });

              if (isPackageHasDeclinedCards && !isAllCardsPublished) {
                setIsConfirmPublishCardsModalOpened(true);
              } else {
                const { data } = await client.mutate({
                  mutation: PUBLISH_OR_UNPUBLISH_ALL_CARDS,
                  variables: {
                    invoiceId,
                    action: !isAllCardsPublished ? 'publish' : 'unpublish',
                  },
                });

                if (data?.publishOrUnpublichAllCards === 'ok') {
                  setPackageOrderWithDetails((prev: any) => {
                    return {
                      ...prev,
                      orderDetails: prev.orderDetails.map((item: any) => {
                        return editableCartTypes.includes(item.group) ||
                          FULLY_EDITABLE_CARDS.includes(item.group)
                          ? {
                              ...item,
                              cartDetails: {
                                ...item.cartDetails,
                                isPublished: isAllCardsPublished ? false : true,
                              },
                            }
                          : item;
                      }),
                    };
                  });

                  successMsg(
                    isAllCardsPublished ? 'All cards are published' : 'All cards are unpublished',
                  );
                }
              }
            }}
          />
        </Row>
        <Row className="mt-4 mr-0 ml-2 d-flex align-items-center">
          <h3 className="mb-0 mr-2">Reset Cards Order?</h3>
          <button
            className="btn btn-sm btn-primary ml-3"
            onClick={async () => {
              const { data } = await client.mutate({
                mutation: RESET_CARDS_ORDER,
                variables: {
                  invoiceId,
                },
              });

              if (data?.resetCardsOrder) {
                setPackageOrderWithDetails(data?.resetCardsOrder);
                resetCardsFilter('');
                successMsg('Cards order reset');
              }
            }}
          >
            Reset
          </button>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PublishAll;
