import {
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from 'reactstrap';

export const GenerateReport: React.FC = () => {
  return (
    <Form>
      <Row>
        <Col>
          <FormGroup className="row">
            <Label className="form-control-label" htmlFor="example-month-input" md="2">
              Select Report Type
            </Label>
            <Col md="10">
              <Input id="exampleFormControlSelect3" type="select">
                <option>Commision Report</option>
                <option>Category Report</option>
                <option>Inventory Dept Sales Report</option>
                <option>Affilate Report</option>
              </Input>
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="row">
            <Label className="form-control-label" htmlFor="example-month-input" md="2">
              Date
            </Label>
            <Col md="10">
              <Input
                defaultValue={new Date().getFullYear() + '-11'}
                id="example-month-input"
                type="month"
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup className="row">
            <Label className="form-control-label" htmlFor="example-month-input" md="2">
              Send To Email
            </Label>
            <Col md="10">
              <InputGroup
              // className={classnames('input-group-merge', {
              //   focused: emailAddress,
              // })}
              >
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-envelope" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email address"
                  type="email"
                  // onFocus={(e) => setemailAddress(true)}
                  // onBlur={(e) => setemailAddress(false)}
                />
              </InputGroup>
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Button color="success" type="button">
            Generate Report
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
