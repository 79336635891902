import { UncontrolledPopover, PopoverBody, Button } from 'reactstrap';
import { TABS_LABELS } from '../../utils/constants';
import { transformPhoneNumber } from '../../utils/services';
import './Table.scss';

const RecipientInfoButton = ({
  popoverId,
  config,
  handleTogglePopover,
  isPopoverOpen,
  item,
  itemKey,
  setCurrentTab,
  setIsPopoverOpen,
  setFilter,
  setFilterState,
}: any) => {
  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="btn-cell"
        id={`id${popoverId}`}
      >
        {config.render(item[itemKey])}
      </button>
      <UncontrolledPopover
        target={`id${popoverId}`}
        toggle={() => handleTogglePopover(`id${popoverId}`)}
        placement="top"
        isOpen={isPopoverOpen === `id${popoverId}`}
      >
        <PopoverBody onClick={(e) => e.stopPropagation()}>
          <div>
            <div className="d-flex justify-content-between">
              <span>
                <strong>Recipient Name:</strong>{' '}
                {item[itemKey].recipientName ? item[itemKey].recipientName : ''}
              </span>
              <i
                className="fas fa-window-close"
                onClick={(e) => {
                  e.stopPropagation();
                  handleTogglePopover(`id${popoverId}`);
                }}
                style={{ cursor: 'pointer' }}
              />
            </div>
            <span>
              <strong>Recipient Email:</strong>{' '}
              {item[itemKey].recipientEmail ? item[itemKey].recipientEmail : ''}
            </span>
            <br />
            <span>
              <strong>Recipient Phone: </strong>
              {item[itemKey].recipientPhone
                ? transformPhoneNumber(item[itemKey].recipientPhone)
                : ''}
            </span>
          </div>
          <div className="mt-2">
            <Button
              onClick={() => {
                setIsPopoverOpen('');
                setCurrentTab(TABS_LABELS.VIEW_ALL);
                setFilter((state: any) => {
                  return { ...state, recipientName: item[itemKey].recipientName };
                });
                setFilterState((state: any) => {
                  return { ...state, recipientName: item[itemKey].recipientName };
                });
              }}
            >
              Show All Packages
            </Button>
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default RecipientInfoButton;
