import { useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Row,
} from 'reactstrap';
import { userRole } from '../../cache/vars';
import { AUTH } from '../../queries/auth';
import * as Yup from 'yup';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

const LoginPage: React.FC = () => {
  const history = useHistory();
  const client = useApolloClient();

  return (
    <>
      <Container className="mt-8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <Formik
                  initialValues={{ email: '', password: '' }}
                  onSubmit={async (userInfo) => {
                    const authResponse = await client.query({
                      query: AUTH,
                      variables: { authInput: userInfo },
                      fetchPolicy: 'no-cache',
                    });

                    const jwt = document.cookie.match(/jwt=[^\s]+/);
                    const jwtValue = jwt && jwt[0];

                    if (
                      (authResponse.data?.logIn.user.role === 'ADMIN' ||
                        authResponse.data?.logIn.user.role === 'SUPER_ADMIN') &&
                      jwtValue
                    ) {
                      userRole(authResponse.data?.logIn.user.role);
                      history.push('/vip-workflow');
                    }
                  }}
                  validationSchema={VALIDATION_SCHEMA}
                >
                  {(props) => {
                    const {
                      values,
                      touched,
                      errors,
                      dirty,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                    } = props;

                    return (
                      <Form role="form" onSubmit={handleSubmit}>
                        <FormGroup>
                          <Input
                            placeholder="Email"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="email"
                            type="text"
                            value={values.email}
                            invalid={!!errors.email && touched.email}
                          />
                          {errors.email && touched.email ? (
                            <FormFeedback>Field should not be empty</FormFeedback>
                          ) : (
                            <FormText>Enter email</FormText>
                          )}
                        </FormGroup>
                        <FormGroup>
                          <Input
                            placeholder="Password"
                            type="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            name="password"
                            value={values.password}
                            invalid={!!errors.password && touched.password}
                          />
                          {errors.password && touched.password ? (
                            <FormFeedback>Field should not be empty</FormFeedback>
                          ) : (
                            <FormText>Enter password</FormText>
                          )}
                        </FormGroup>
                        {/* <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                          <label className="custom-control-label" htmlFor=" customCheckLogin">
                            <span className="text-muted">Remember me</span>
                          </label>
                        </div> */}
                        <div className="text-center d-flex flex-column">
                          <Button className="my-4" color="info" type="submit">
                            Log in
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </CardBody>
            </Card>
            <Row className="mt-3">
              <Col xs="6">
                <Link to="/forgot-password" className="text-primary">
                  <small>Forgot password?</small>
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginPage;
