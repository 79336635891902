import { gql } from '@apollo/client';

export const BROKERS = gql`
  query recipients(
    $page: Float!
    $itemsPerPage: Float!
    $sortBy: String
    $sortDirection: String
    $search: String
    $isHideEmpty: Boolean
  ) {
    brokers(
      page: $page
      itemsPerPage: $itemsPerPage
      sortBy: $sortBy
      sortDirection: $sortDirection
      search: $search
      isHideEmpty: $isHideEmpty
    ) {
      totalPageCount
      count
      clientData {
        name
        clientBrokerId
        packagesCount
        totalSales
      }
    }
  }
`;

export const AUTOCOMPLETE_BROKERS = gql`
  query autocompleteBrokers($search: String) {
    autocompleteBrokers(search: $search) {
      name
      clientBrokerId
    }
  }
`;
