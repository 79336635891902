import { useApolloClient } from '@apollo/client';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { clientsFilter, successMsg } from '../../cache/vars';
import { GET_CSR_LIST } from '../../queries/users';
import {
  NEEDS_FULFILLMENT,
  COMPLETED,
  NEEDS_DELIVERY,
  NEW,
  PENDING_REC,
  REJECTED,
  VOID,
  ALL,
  MERCH_DELIVERY,
} from '../../queries/vip-workflow';
import { responseKeys, TABS_LABELS } from '../../utils/constants';
import { IPackage } from '../../utils/interfaces';
import { TABLE_CONFIG } from '../../utils/tableConfigs';
import SearchAndExpandActions from './SearchAndExpandActions';
import TableLayout from './TableLayout';

const TableWrapper = ({
  currentTab,
  setCurrentTab,
  searchInvoiceId,
  setSearchInvoiceId,
  currentTabKey,
  filterState,
  setFilterState,
  filter,
  setFilter,
  page,
  setPage,
  clientsFilterState,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  isDetailsOpened,
  setIsDetailsOpened,
  searchPoId,
  setSearchPoId,
  sortDateRangeBy,
  setSortDateRangeBy,
  dateRange,
  setDateRange,
  invoiceIdInputState,
  setInvoiceIdInputState,
  poInputSate,
  setPoInputState,
  typesToFilter,
  setTypesToFilter,
  csrIdsToFilter,
  setCsrIdsToFilter,
  setOrderIdInputState,
  orderIdInputSate,
  searchOrderId,
  setSearchOrderId,
}: any) => {
  const client = useApolloClient();

  const [itemsPerPage, setItemsPerPage] = React.useState<number>(25);

  const [initialOrders, setInitialOrders] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [totalPageCount, setTotalPageCount] = React.useState(null);
  const [totalPackagesCount, setTotalPackagesCount] = React.useState(null);

  const [csrListState, setCsrListState] = React.useState([]);

  const getPackageQuery = async (QUERY: any) => {
    setLoading(true);

    if (initialOrders.length) {
      setInitialOrders([]);
    }

    const initialOrdersData = await client.query({
      query: QUERY,
      variables: {
        page,
        itemsPerPage,
        invoiceId: searchInvoiceId ? Number.parseFloat(searchInvoiceId) : null,
        sortBy,
        sortDirection,
        // IF CLICK ON ANY RECIPIENT OR CLIENT ROW IN CLIENTS/RECIPIENTS TABLE
        // IT WILL SET SET clientsFilterState STATE WITH DATA OF CERTAIN CLIENT/RECIPIENT
        // AND REDIRECT TO 'ALL' TAB TO FIND ALL PACKAGES OF THIS CLIENT
        filter: clientsFilterState ? clientsFilterState : filter,
        csrIdsToFilter: csrIdsToFilter.length ? csrIdsToFilter : null,
        po: searchPoId ? Number.parseFloat(searchPoId) : null,
        typesToFilter: typesToFilter.length ? typesToFilter : null,
        sortDateRangeBy: sortDateRangeBy && dateRange ? sortDateRangeBy : null,
        dateRange: sortDateRangeBy && dateRange ? dateRange : null,
        orderId: searchOrderId ? searchOrderId : null,
      },
      fetchPolicy: 'cache-first',
    });

    const { data } = await client.query({
      query: GET_CSR_LIST,
      fetchPolicy: 'cache-first',
    });

    setCsrListState(
      data?.getCsrList.map((csr: { username: string; csrId: number }) => {
        return { label: csr.username, value: csr.csrId };
      }),
    );
    clientsFilter(null);
    setLoading(false);
    setInitialOrders(initialOrdersData.data[responseKeys[currentTab]]?.orders);
    setTotalPageCount(initialOrdersData.data[responseKeys[currentTab]]?.totalPageCount);
    setTotalPackagesCount(initialOrdersData.data[responseKeys[currentTab]]?.totalPackagesCount);
  };

  React.useEffect(() => {
    switch (currentTab) {
      case TABS_LABELS.NEW:
        getPackageQuery(NEW);
        break;

      case TABS_LABELS.NEEDS_FULFILLMENT:
        getPackageQuery(NEEDS_FULFILLMENT);
        break;

      case TABS_LABELS.NEEDS_DELIVERY:
        getPackageQuery(NEEDS_DELIVERY);
        break;

      case TABS_LABELS.MERCH_DELIVERY:
        getPackageQuery(MERCH_DELIVERY);
        break;

      case TABS_LABELS.PENDING_REC:
        getPackageQuery(PENDING_REC);
        break;

      case TABS_LABELS.COMPLETED:
        getPackageQuery(COMPLETED);
        break;

      case TABS_LABELS.REJECTED:
        getPackageQuery(REJECTED);
        break;

      case TABS_LABELS.VIEW_ALL:
        getPackageQuery(ALL);
        break;

      case TABS_LABELS.VOID:
        getPackageQuery(VOID);
        break;

      default:
        return;
    }
  }, [
    currentTab,
    filter,
    sortBy,
    sortDirection,
    page,
    itemsPerPage,
    csrIdsToFilter,
    typesToFilter,
  ]);

  const handleExpandRows = () => {
    const invoiceIdsList = initialOrders.map((item: IPackage) => item.invoiceId);

    if (isDetailsOpened.length) {
      setIsDetailsOpened([]);
    } else {
      setIsDetailsOpened((state: number[]) => {
        return [...state, ...invoiceIdsList];
      });
    }
  };

  return (
    <Container className="mt-4" fluid>
      <Row className="align-items-center mb-4">
        <Col xs="4" className="d-flex">
          <h1 className="mb-0 mr-3">{currentTab}</h1>
          {localStorage.getItem('defaultTab') !== currentTab?.replace(' ', '_') ? (
            <button
              className="btn btn-sm"
              onClick={() => {
                localStorage.setItem('defaultTab', currentTab.replace(' ', '_'));
                successMsg('The tab set up as default');
              }}
            >
              Set up as default
            </button>
          ) : (
            <div className="d-flex align-items-center">
              <i className="fa fa-solid fa-lock"></i>
            </div>
          )}
        </Col>
        <Col xs="8">
          <SearchAndExpandActions
            setCurrentTab={setCurrentTab}
            currentTab={currentTab}
            isDetailsOpened={isDetailsOpened}
            handleExpandRows={handleExpandRows}
            invoiceIdInputState={invoiceIdInputState}
            setInvoiceId={setSearchInvoiceId}
            setInvoiceIdInputState={setInvoiceIdInputState}
            poInputSate={poInputSate}
            setPoInputState={setPoInputState}
            setSearchPoId={setSearchPoId}
            searchInvoiceId={searchInvoiceId}
            searchPoId={searchPoId}
            setInitialOrders={setInitialOrders}
            setTotalPageCount={setTotalPageCount}
            setTotalPackagesCount={setTotalPackagesCount}
            setOrderIdInputState={setOrderIdInputState}
            orderIdInputSate={orderIdInputSate}
            searchOrderId={searchOrderId}
            setSearchOrderId={setSearchOrderId}
          />
        </Col>
      </Row>
      <TableLayout
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        setFilter={setFilter}
        filterState={filterState}
        setFilterState={setFilterState}
        sortBy={sortBy}
        sortDirection={sortDirection}
        setSortBy={setSortBy}
        setSortDirection={setSortDirection}
        isQueryLoading={loading}
        isDetailsOpened={isDetailsOpened}
        setIsDetailsOpened={setIsDetailsOpened}
        tableConfig={TABLE_CONFIG[currentTabKey as keyof typeof TABLE_CONFIG]}
        orders={initialOrders}
        totalPageCount={totalPageCount}
        totalPackagesCount={totalPackagesCount}
        page={page}
        setPage={setPage}
        setItemsPerPage={setItemsPerPage}
        itemsPerPage={itemsPerPage}
        setInvoiceId={setSearchInvoiceId}
        csrIdsToFilter={csrIdsToFilter}
        setCsrIdsToFilter={setCsrIdsToFilter}
        filter={filter}
        csrListState={csrListState}
        typesToFilter={typesToFilter}
        setTypesToFilter={setTypesToFilter}
        sortDateRangeBy={sortDateRangeBy}
        setSortDateRangeBy={setSortDateRangeBy}
        dateRange={dateRange}
        setDateRange={setDateRange}
        getPackageQuery={getPackageQuery}
      />
    </Container>
  );
};

export default TableWrapper;
