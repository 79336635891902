import React from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const RenderFile = ({
  setFile,
  file,
  setIsOpenFilePreview,
}: {
  setFile: React.Dispatch<React.SetStateAction<string>>;
  file: string;
  setIsOpenFilePreview: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [numPages, setNumPages] = React.useState<null | number>(null);
  const [fileScale, setFileScale] = React.useState(1.25);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const modalWidth = document?.getElementById('ticketsModal')?.offsetWidth;

  React.useEffect(() => {
    if (modalWidth && modalWidth < 800 && fileScale !== 0.75) {
      setFileScale(0.75);
    }

    if (modalWidth && modalWidth >= 800 && fileScale !== 1.25) {
      setFileScale(1.25);
    }

    if (modalWidth && modalWidth < 400 && fileScale !== 0.59) {
      setFileScale(0.59);
    }
  }, [modalWidth]);

  const isImageFile = (file: string) => {
    return file.startsWith('data:image');
  };

  return (
    <div>
      <button
        className="btn btn-md p-1"
        onClick={() => {
          setFile('');
          setIsOpenFilePreview(false);
        }}
      >
        &larr; Back To Tickets List
      </button>
      {!file ? (
        <div>
          <span>Loading...</span>
        </div>
      ) : isImageFile(file) ? (
        <div className="mt-2">
          <img src={file} alt="Preview" style={{ width: '100%', height: 'auto' }} />
        </div>
      ) : (
        <div className="mt-2">
          <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={fileScale} />
            ))}
          </Document>
        </div>
      )}
    </div>
  );
};

export default RenderFile;
