import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import AdminNavbar from '../../components/Navbar/Navbar';
import { TABS, TABS_LABELS } from '../../utils/constants';
import { useLocation } from 'react-router-dom';
import { UnsentOrderReport } from '../../components/DocsComponents/UnsentOrderReport';
import { DailyInventoryReport } from '../../components/DocsComponents/DailyInventoryReport';
import { DailyPricingReport } from '../../components/DocsComponents/DailyPricingReport';

const docsTabs = {
  [TABS_LABELS.UNSENT_ORDER_REPORT]: <UnsentOrderReport />,
  [TABS_LABELS.DAILY_PRICING_REPORT]: <DailyPricingReport />,
  [TABS_LABELS.DAILY_INVENTORY_DEPT_REPORT]: <DailyInventoryReport />,
} as const;

const DocsPage: React.FC = () => {
  const location = useLocation();
  const currentSection: string = location.pathname.slice(1).toUpperCase();

  const [currentTab, setCurrentTab] = React.useState(TABS[currentSection as keyof typeof TABS][0]);

  React.useEffect(() => {
    setCurrentTab(TABS[currentSection as keyof typeof TABS][0]);
  }, [location]);

  const handleCurrentTab = (title: string) => {
    setCurrentTab(title);
  };

  return (
    <>
      <AdminNavbar
        handleCurrentTab={handleCurrentTab}
        currentTab={currentTab}
        currentSection={currentSection}
      />
      <Container className="mt-4" fluid>
        <Row>
          <Col xs="6">
            <h1 className="mb-0">{currentTab}</h1>
          </Col>
        </Row>
        {docsTabs[currentTab]}
      </Container>
    </>
  );
};

export default DocsPage;
