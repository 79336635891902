import { Col, Row, UncontrolledTooltip } from 'reactstrap';
import FlightInfoPopover from '../../components/Popovers/FlightInfoPopover';
import './packageInfo.scss';

const CartLayout = ({
  schemaConfig,
  cartData,
  isCartInfoOpenedId,
  handleOpenDetails,
  parsedCardDetails,
  cartInfoId,
  handleOpenLearnMore,
  isLearnMoreOpenedId,
  isLearnMoreOpened,
  dataKey,
}: any) => {
  const renderFieldValue = (cartDetails: any, config: any) => {
    if (config.alignToLeft) return;

    if (config.isAddress) {
      return cartDetails?.[config.placeLinkName as keyof typeof cartDetails] ? (
        <>
          <span>
            <a href={cartDetails[config.placeLinkName as keyof typeof cartDetails]} target="_blank">
              {config.linkLabel
                ? cartDetails[config.linkLabel as keyof typeof cartDetails]
                : cartDetails[config.name as keyof typeof cartDetails]}
            </a>
          </span>
          {config.isShowAsterisk ? <span className="font-weight-bold">&nbsp;*</span> : null}
        </>
      ) : (
        <>
          <span>{cartDetails[config.name as keyof typeof cartDetails]}</span>
          {config.isShowAsterisk ? <span className="font-weight-bold">&nbsp;*</span> : null}
        </>
      );
    }

    if (config.name === 'expectedArrival') {
      return cartDetails?.intlShipBy ? (
        <>
          <span>{cartDetails?.intlShipBy}</span>
          <span className="ml-2">
            <i className="fas fa-lg fa-regular fa-globe" id={`originalShipBy`} />
            <UncontrolledTooltip target={`originalShipBy`} delay={0}>
              Original ship by: {cartDetails?.[config.name as keyof typeof cartDetails]}
            </UncontrolledTooltip>
          </span>
        </>
      ) : (
        <span>{cartDetails?.[config.name as keyof typeof cartDetails]}</span>
      );
    }

    if (config.name === 'flightNumber' || config.name === 'departureFlightNumber') {
      return (
        <>
          <span>
            {config.render(
              cartDetails?.[config.name as keyof typeof cartDetails]
                ? cartDetails[config.name as keyof typeof cartDetails]
                : config.defaultValue,
              cartDetails?.[config.showDataFrom as keyof typeof cartDetails]
                ? cartDetails[config.showDataFrom as keyof typeof cartDetails]
                : config.defaultValue,
            )}
          </span>
          {config.isShowAsterisk ? <span className="font-weight-bold">&nbsp;*</span> : null}
          {cartDetails?.[config.name as keyof typeof cartDetails] ? (
            <FlightInfoPopover parsedCardDetails={parsedCardDetails} config={config} cartInfoId={cartInfoId} cardGroup={cartData?.group} />
          ) : null}
        </>
      );
    }

    if (config.render) {
      return (
        <>
          <span>
            {config.render(
              cartDetails?.[config.name as keyof typeof cartDetails]
                ? cartDetails[config.name as keyof typeof cartDetails]
                : config.defaultValue,
              cartDetails?.[config.showDataFrom as keyof typeof cartDetails]
                ? cartDetails[config.showDataFrom as keyof typeof cartDetails]
                : config.defaultValue,
            )}
          </span>
          {config.isShowAsterisk ? <span className="font-weight-bold">&nbsp;*</span> : null}
        </>
      );
    }

    if (config.inputType === 'checkbox') {
      return (
        <input
          type="checkbox"
          checked={cartDetails[config.name as keyof typeof cartDetails]}
          onChange={() => null}
        />
      );
    }

    if (config.showDataFrom) {
      return (
        <span>
          {cartDetails[config.showDataFrom as keyof typeof cartDetails]
            ? cartDetails[config.showDataFrom as keyof typeof cartDetails]
            : config.defaultValue}
        </span>
      );
    }

    if (config.isLink) {
      return cartDetails[config.name as keyof typeof cartDetails] ? (
        <a
          href={cartDetails[config.name as keyof typeof cartDetails]}
          target="_blank"
          rel="noreferrer"
        >
          {cartDetails[config.name as keyof typeof cartDetails]}
        </a>
      ) : (
        config.defaultValue
      );
    }

    return (
      <>
        <span>
          {cartDetails?.[config.name as keyof typeof cartDetails]
            ? cartDetails?.[config.name as keyof typeof cartDetails]
            : config.defaultValue}
        </span>
        {config.isShowAsterisk ? <span className="font-weight-bold">&nbsp;*</span> : null}
      </>
    );
  };

  const renderFieldLabel = (cartDetails: any, config: any) => {
    if (config.alignToLeft) {
      if (config.renderDescription) {
        return config.renderDescription(
          cartDetails?.[config.name as keyof typeof cartDetails]
            ? cartDetails[config.name as keyof typeof cartDetails]
            : config.defaultValue,
          cartDetails.group,
        );
      }

      if (config.render) {
        return config.render(
          cartDetails?.[config.name as keyof typeof cartDetails]
            ? cartDetails[config.name as keyof typeof cartDetails]
            : config.defaultValue,
          cartDetails?.[config.showDataFrom as keyof typeof cartDetails]
            ? cartDetails[config.showDataFrom as keyof typeof cartDetails]
            : config.defaultValue,
        );
      }

      return cartDetails?.[schemaConfig.name as any];
    } else {
      if (typeof config.label === 'function') {
        return schemaConfig.label(
          parsedCardDetails?.[schemaConfig.name as any]
            ? parsedCardDetails[schemaConfig.name as any]
            : '-',
        );
      }

      if (config.labelInCart) {
        return config.labelInCart;
      }

      return schemaConfig.label;
    }
  };

  return (
    <Row style={schemaConfig.style} key={cartInfoId}>
      <Col
        xs={schemaConfig.alignToLeft ? '11' : '6'}
        className={schemaConfig.bold ? 'font-weight-bold' : ''}
      >
        {schemaConfig.isTextContainsLink ? (
          <span
            style={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{
              __html: schemaConfig.render(parsedCardDetails?.[schemaConfig.name as any]),
            }}
          ></span>
        ) : (
          <span>{renderFieldLabel(parsedCardDetails, schemaConfig)}</span>
        )}
        {schemaConfig.learnMoreButton ? (
          <button
            style={{ border: 'none', background: 'none', marginLeft: '15px' }}
            onClick={(e) => handleOpenLearnMore(e, cartData.cartDetails.packageDetailsId)}
          >
            {isLearnMoreOpenedId.includes(cartData.cartDetails?.packageDetailsId) ? (
              <i className="fa fa-caret-up text-body fa-lg" />
            ) : (
              <i className="fa fa-caret-down text-body fa-lg" />
            )}
          </button>
        ) : null}
      </Col>
      {/* {!schemaConfig.learnMore ? ( */}
      <Col xs={schemaConfig.showMoreButton ? '4' : '6'}>
        {renderFieldValue(parsedCardDetails, schemaConfig)}
      </Col>
      {/* ) : null}
       {schemaConfig.learnMore && isLearnMoreOpened ? (
         <Col xs="12">{renderFieldValue(parsedCardDetails, schemaConfig)}</Col>
       ) : null} */}
      {schemaConfig.showMoreButton ? (
        <Col>
          <button
            style={{ border: 'none', background: 'none', marginLeft: '15px' }}
            onClick={(e) =>
              handleOpenDetails(
                e,
                (dataKey === 'Airport Transfer' || dataKey === 'Vegas Limo Transfer') &&
                  !parsedCardDetails.transfer1 &&
                  !parsedCardDetails.transfer2
                  ? parsedCardDetails.transferId
                  : cartData.cartDetails.packageDetailsId,
              )
            }
          >
            {isCartInfoOpenedId.includes(
              (cartData.cartDetails?.packageDetailsId && parsedCardDetails?.transferId) ||
                cartData.cartDetails?.packageDetailsId ||
                parsedCardDetails?.transferId,
            ) ? (
              <span style={{ color: '#337ab7', textDecoration: 'underline' }}>Hide</span>
            ) : (
              <span style={{ color: '#337ab7', textDecoration: 'underline' }}>Show More</span>
            )}
          </button>
        </Col>
      ) : null}
    </Row>
  );
};

export default CartLayout;
