import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { clientsFilter } from '../../cache/vars';
import AdminNavbar from '../../components/Navbar/Navbar';
import TableWrapper from '../../components/WorkflowTable/TableWrapper';
import { TABS, TABS_LABELS } from '../../utils/constants';

const WorkflowPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const currentSection: string = location.pathname.slice(1).toUpperCase();
  const tabQueryParamValue = new URLSearchParams(location.search).get('tab');

  const clientsFilterState = clientsFilter();

  const getCurrentTabValue = clientsFilterState
    ? TABS['VIP-WORKFLOW'][7]
    : TABS_LABELS[tabQueryParamValue as keyof typeof TABS_LABELS];

  const [locationKeys, setLocationKeys] = React.useState<(string | undefined)[]>([]);
  const [currentTab, setCurrentTab] = React.useState(getCurrentTabValue);
  const [isDetailsOpened, setIsDetailsOpened] = React.useState<number[]>([]);

  const [searchInvoiceId, setSearchInvoiceId] = React.useState(null);
  const [searchPoId, setSearchPoId] = React.useState(null);
  const [searchOrderId, setSearchOrderId] = React.useState(null);

  const [page, setPage] = React.useState<number>(1);
  const [filterState, setFilterState] = React.useState({});
  const [filter, setFilter] = React.useState({});
  const [recipientNameSearch, setRecipientNameSearch] = React.useState(null);
  const [sortBy, setSortBy] = React.useState('');
  const [sortDirection, setSortDirection] = React.useState('');
  const [sortDateRangeBy, setSortDateRangeBy] = React.useState('saleDate');
  const [dateRange, setDateRange] = React.useState(null);
  const [invoiceIdInputState, setInvoiceIdInputState] = React.useState('');
  const [poInputSate, setPoInputState] = React.useState('');
  const [typesToFilter, setTypesToFilter] = React.useState([]);
  const [csrIdsToFilter, setCsrIdsToFilter] = React.useState([]);
  const [orderIdInputSate, setOrderIdInputState] = React.useState(null);

  React.useEffect(() => {
    if (clientsFilterState) {
      setCurrentTab(getCurrentTabValue);
    }
  }, [location]);

  React.useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        if (location.key) {
          setLocationKeys([location.key]);
        }
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          const tabQueryParamValueOnForwardClick = new URLSearchParams(location.search).get('tab');
          if (tabQueryParamValueOnForwardClick) {
            setCurrentTab(
              TABS_LABELS[tabQueryParamValueOnForwardClick as keyof typeof TABS_LABELS],
            );
          }
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          const tabQueryParamValueOnBackClick = new URLSearchParams(location.search).get('tab');
          if (tabQueryParamValueOnBackClick) {
            setCurrentTab(TABS_LABELS[tabQueryParamValueOnBackClick as keyof typeof TABS_LABELS]);
          }
        }
      }
    });
  }, [locationKeys]);

  React.useEffect(() => {
    const defaultTab = localStorage.getItem('defaultTab');

    if (!tabQueryParamValue && !clientsFilterState) {
      history.push(
        `vip-workflow?tab=${
          defaultTab
            ? Object.entries(TABS_LABELS)
                .filter(([key, value]) => value === defaultTab.replace('_', ' '))
                .flat()[0]
            : TABS_LABELS.NEW
        }`,
      );
      setCurrentTab(defaultTab ? defaultTab.replace('_', ' ') : TABS_LABELS.NEW);
    }
  }, []);

  const handleCurrentTab = (title: string) => {
    setCurrentTab(title);
    setFilter({});
    setFilterState({});
    setTypesToFilter([]);
    setCsrIdsToFilter([]);
    setDateRange(null);
    setSortDateRangeBy('saleDate');

    if (invoiceIdInputState || searchInvoiceId) {
      setSearchInvoiceId(null);
      setInvoiceIdInputState('');
    }

    if (poInputSate) {
      setSearchPoId(null);
      setPoInputState('');
    }

    setPage(1);
    setSortBy('');
    setSortDirection('');
    setIsDetailsOpened([]);

    history.push(
      `vip-workflow?tab=${
        Object.entries(TABS_LABELS)
          .filter(([key, value]) => value === title)
          .flat()[0]
      }`,
    );
  };

  return (
    <>
      <AdminNavbar
        handleCurrentTab={handleCurrentTab}
        currentTab={currentTab ? currentTab : TABS_LABELS.NEW}
        currentSection={currentSection}
      />
      <TableWrapper
        setCurrentTab={setCurrentTab}
        currentTab={currentTab}
        searchInvoiceId={searchInvoiceId}
        setSearchInvoiceId={setSearchInvoiceId}
        currentTabKey={tabQueryParamValue ? tabQueryParamValue : TABS_LABELS.NEW}
        filterState={filterState}
        setFilterState={setFilterState}
        filter={filter}
        setFilter={setFilter}
        page={page}
        setPage={setPage}
        recipientNameSearch={recipientNameSearch}
        setRecipientNameSearch={setRecipientNameSearch}
        clientsFilterState={clientsFilterState}
        sortBy={sortBy}
        setSortBy={setSortBy}
        sortDirection={sortDirection}
        setSortDirection={setSortDirection}
        isDetailsOpened={isDetailsOpened}
        setIsDetailsOpened={setIsDetailsOpened}
        searchPoId={searchPoId}
        setSearchPoId={setSearchPoId}
        sortDateRangeBy={sortDateRangeBy}
        setSortDateRangeBy={setSortDateRangeBy}
        dateRange={dateRange}
        setDateRange={setDateRange}
        invoiceIdInputState={invoiceIdInputState}
        setInvoiceIdInputState={setInvoiceIdInputState}
        poInputSate={poInputSate}
        setPoInputState={setPoInputState}
        typesToFilter={typesToFilter}
        setTypesToFilter={setTypesToFilter}
        csrIdsToFilter={csrIdsToFilter}
        setCsrIdsToFilter={setCsrIdsToFilter}
        orderIdInputSate={orderIdInputSate}
        setOrderIdInputState={setOrderIdInputState}
        searchOrderId={searchOrderId}
        setSearchOrderId={setSearchOrderId}
      />
    </>
  );
};

export default WorkflowPage;
