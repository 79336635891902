import { gql } from '@apollo/client';

export const GET_TICKETS_LIST_FOR_DASHBOARD = gql`
  query getTicketsListForTicketsDashboard(
    $sortBy: String
    $sortDirection: String
    $statusesToFilter: [String!]
    $invoiceIdSearch: Float
    $page: Float!
    $itemsPerPage: Float!
  ) {
    getTicketsListForTicketsDashboard(
      sortBy: $sortBy
      sortDirection: $sortDirection
      statusesToFilter: $statusesToFilter
      invoiceIdSearch: $invoiceIdSearch
      page: $page
      itemsPerPage: $itemsPerPage
    ) {
      transfersList {
        deliveryStatus
        updatedAt
        invoiceId
        packagePo
        transferId
        eventDate
        marketplace
        acceptanceURL
        errorMessage
        errorLogs
        venue
        eventName
        recipient {
          recipientName
          recipientEmail
          recipientPhone
        }
        client
      }
      totalPageCount
      totalItemsCount
    }
  }
`;

export const GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD = gql`
  query getPackagePoInfoForTicketsDahboard($invoiceIdList: [Float!]) {
    getPackagePoInfoForTicketsDahboard(invoiceIdList: $invoiceIdList)
  }
`;

export const GET_PACKAGE_PO_FROM_POS_INFO_FOR_TICKETS_DASHBOARD = gql`
  query ticketGroup($ticketGroupId: [Float!]!) {
    ticketGroup(ticketGroupId: $ticketGroupId) {
      ticketGroupId
      section
      row
      tickets {
        ticketId
        ticketGroupId
        purchaseOrderId
        seatNumber
        seatOrder
        actualSoldPrice
        onHand
        onHandDate
        invoiceId
        available
        cost
      }
    }
  }
`;

// export const PUT_PACKAGE_PO_FROM_POS_INFO_FOR_TICKETS_DASHBOARD = gql`
//   mutation putTicketGroup($ticketGroup: TicketGroupInputDTO!) {
//     putTicketGroup(ticketGroup: $ticketGroup) {
//       ticketGroupId
//       section
//       row
//       updatedByCSRID
//       tickets {
//         ticketId
//         ticketGroupId
//         purchaseOrderId
//         seatNumber
//         seatOrder
//         actualSoldPrice
//         onHand
//         onHandDate
//         invoiceId
//         available
//         cost
//       }
//     }
//   }
// `;

export const GET_TICKETS_LIST_FOR_TICKETS_MODAL = gql`
  query getTicketsListForTicketsModal($invoiceId: Float!) {
    getTicketsListForTicketsModal(invoiceId: $invoiceId)
  }
`;

export const SAVE_ACCEPTANCE_URL = gql`
  mutation saveAcceptanceUrl($invoiceId: Float!, $acceptanceUrl: String!, $user: String!) {
    saveAcceptanceUrl(invoiceId: $invoiceId, acceptanceUrl: $acceptanceUrl, user: $user)
  }
`;

export const GET_LATEST_CRON_INFO = gql` 
  query getLatestCronInfo($startDate: DateTime!, $endDate: DateTime!) {
    getLatestCronInfo(startDate: $startDate, endDate: $endDate) {
      id
      cronStartDate
      cronEndDate
      totalTransfersCount
      notTransferableTransfersCount
      transferableTransfersCount
      totalRunTime
      totalTicketsScanned
      totalNotTransferable
      totalUniqueTicketsScanned
      totalUniqueNotTransferable
      totalFailed
      totalEmailed
  }
}`;


export const GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD = gql`
  query getPackageDetailsHistoryByInvoiceId($invoiceId: Float!) {
    getPackageDetailsHistoryByInvoiceId(invoiceId: $invoiceId) {
      packageHistoryId,
      trigger,
      date,
      deliveryStatus,
  }
  }
`;
