import './publishedLabel.scss';

const PublishedLabel = ({ isPublished }: { isPublished: boolean }) => {
  return !isPublished ? (
    <div className="d-flex align-items-center frame primary-border ml-3">
      <span className="text-uppercase text-primary">published</span>
    </div>
  ) : (
    <div className="d-flex align-items-center frame grey-border ml-3">
      <span className="text-uppercase">unpublished</span>
    </div>
  );
};

export default PublishedLabel;
