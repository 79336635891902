export const UnsentOrderReport: React.FC = () => {
  return (
    <ul>
      <li>
        Start with all orders where Ticket Group Code is not FILLCONCERTS OR FILLSPORTS and the
        orders are not marked as Sent in the POS
      </li>
      <li>
        Check if Sale is Near Term. This is if sale date is within 4 days of the expected on hand
        date.
      </li>
      <li>Set Estimated Ship Date</li>
      <ul>
        {/* <!--<ul>
              <li>If Near Term = 'N' - Use Estimated Ship Date from POS</li>
              <li>If Near Term = 'Y' and Sale Date = Event Date - Use Event Date as Estimated Ship Date</li>                                                                   
              <li>If Near Term = 'Y' and Sale Date is before Event Date -  Add up to 4 days to the Sale Date as Estimated Ship Date, but has to be at least 1 day prior to Event date</li>                                                          
            </ul> --> */}
        <li>
          CAT Orders go to Shipping Tracking table and use Estimated Ship Date as Ships by; unless
          the Estimated Ship Date = order date. Then we use Expected Arrival Date.
        </li>
        <li>Real TG Orders go to Ticket table and use Expected Arrival Date as Ships by</li>
        <li>
          Check if Sale is Near Term. This is if sale date is within 4 days of the expected on hand
          date.
        </li>
        <li>
          {' '}
          Set Ships by
          <ul>
            <li>If Near Term = 'N' Use Ships by from POS</li>
            <li>If Near Term = 'Y' and Sale Date = Event Date - Use Event Date as Ships by</li>
            <li>
              If Near Term = 'Y' and Sale Date is before Event Date - Add up to 4 days to the Sale
              Date as Ships by, but has to be at least 1 day prior to Event date
            </li>
          </ul>
        </li>
      </ul>

      <li>
        Create Individual tabs based on the Estimated Ship Dates. All Tabs exclude Local Pickup,
        except for the Local Pickup Tab
      </li>
      <ul>
        <li>
          0-1 Days will select any records where the Estimated ship date is today or tomorrow.
        </li>
        <li>
          2-3 Days will select any records where the Estimated ship date is 2 or 3 days from today.
        </li>
        <li>
          4-7 Days will select any records where the Estimated ship date is 4-7 days from today.
        </li>
        <li>
          Local Pickup will select any records where the Delivery type is set for local pickup and
          the Estimated Ship date is from Today to 14 days from Today.
        </li>
        <li>
          Missed will select any records where the Estimated ship date is from 8 days ago to
          yesterday
        </li>
      </ul>
      <li>
        Report is run daily at 7am Eastern and is delivered to recipients managed online{' '}
        <a href="https://psusa.premiumseatsusa.com/psusa/reports/manage/ManageReportRecipients.php">
          Here
        </a>
      </li>
    </ul>
  );
};
