import CloseModalButton from '../../Buttons/CloseModalButton';

const UploadTicketsModalHeader = ({
  setIsUploadTicketsModalOpened,
  setFile,
  setIsOpenFilePreview,
}: {
  setIsUploadTicketsModalOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setFile: React.Dispatch<React.SetStateAction<string>>;
  setIsOpenFilePreview: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <div className="d-flex justify-content-between">
      <div>
        <h1>Manage Tickets</h1>
      </div>
      <div>
        <CloseModalButton
          handleCloseModalButtonClick={(e) => {
            e.preventDefault();

            setIsUploadTicketsModalOpened(false);
            setFile('');
            setIsOpenFilePreview(false);
          }}
        />
      </div>
    </div>
  );
};

export default UploadTicketsModalHeader;
