const TableHead = ({
  tableConfig,
  setFilterState,
  handleSorting,
  handleReset,
  sortBy,
  sortDirection,
  filterState,
  handleApplyFilters,
  setFilter,
}: any) => {
  
  return (
    <thead className="thead-light text-center">
      <tr>
        {Object.entries(tableConfig).map(([key, config]: any, id) => (
          <th
            key={id}
            onClick={() => {
              if (tableConfig[key].notSortable) {
                return;
              } else {
                handleSorting(config.key);
              }
            }}
          >
            {tableConfig[key].colTitle}&nbsp;
            {tableConfig[key].notSortable ? null : sortBy && sortBy === key ? (
              sortDirection === 'ASC' ? (
                <>
                  <span>&uarr;</span>
                  <button type='button' onClick={handleReset} 
                  style={
                    {
                      border: 'none',
                      background: 'none',
                      outline: 'none',
                      cursor: 'pointer',
                      padding: '0'
                    }
                  }>
                    &nbsp;↺&nbsp;
                  </button>
                </>
              ) : (
                <>
                  <span>&darr;</span>
                  <button type='button' onClick={handleReset} 
                  style={
                    {
                      border: 'none',
                      background: 'none',
                      outline: 'none',
                      cursor: 'pointer',
                      padding: '0'
                    }
                  }>
                    &nbsp;↺&nbsp;
                  </button>
                </>
              )
            ) : (
              <>
                <span>&uarr;</span>
                <span>&darr;</span>
              </>
            )}
          </th>
        ))}
      </tr>
      <tr>
        {Object.entries(tableConfig).map(([key, config]: any, id) => (
          <th key={id} className="pl-2 pr-2 pt-1 pb-1">
            {key === 'type' ||
            key === 'statusId' ||
            key === 'validated' ||
            key === 'ticketFulfilled' ? (
              <select
                style={{ height: '21px' }}
                onChange={(e) => {
                  setFilterState((state: any) => {
                    if (e.target.value === '') {
                      delete state[config.key];
                      return { ...state };
                    }

                    return {
                      ...state,
                      [config.key]: e.target.value,
                    };
                  });

                  setFilter((state: any) => {
                    if (e.target.value === '') {
                      delete state[config.key];

                      return { ...state };
                    }

                    return {
                      ...state,
                      [config.key]: e.target.value,
                    };
                  });
                  setFilter((state: any) => {
                    if (e.target.value === '') {
                      delete state[config.key];

                      return { ...state };
                    }

                    return {
                      ...state,
                      [config.key]: e.target.value,
                    };
                  });
                }}
                value={(filterState[config.key] && filterState[config.key]) || ''}
              >
                {key === 'type' ? (
                  <>
                    <option></option>
                    <option value="CONCERT">CONCERT</option>
                    <option value="SPORT">SPORT</option>
                    <option value="OTHER">OTHER</option>
                  </>
                ) : key === 'statusId' ? (
                  <>
                    <option></option>
                    <option value={1}>Pending Authorization</option>
                    <option value={2}>Authorized</option>
                    <option value={6}>Rejected</option>
                    <option value={5}>Ready To Ship</option>
                    <option value={7}>Void</option>
                  </>
                ) : key === 'validated' || key === 'ticketFulfilled' ? (
                  <>
                    <option></option>
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </>
                ) : null}
              </select>
            ) : (
              <>
                <i className="fas fa-filter mr-1" />
                <input
                  style={{ width: '85%', appearance: 'auto' }}
                  value={(filterState[config.key] && filterState[config.key]) || ''}
                  type={config.type}
                  onChange={(e) => {
                    setFilterState((state: any) => {
                      if (e.target.value === '') {
                        delete state[config.key];

                        return { ...state };
                      }

                      return {
                        ...state,
                        [config.key]:
                          key === 'saleDate' || key === 'eventDate' || key === 'shipBy'
                            ? e.target.value
                            : config.type === 'number'
                            ? Number.parseFloat(e.target.value)
                            : e.target.value.toLowerCase(),
                      };
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleApplyFilters(e);
                    }
                  }}
                />
              </>
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
