import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import AdminNavbar from '../../components/Navbar/Navbar';
import ClientsTable from '../../components/ClientsTable/ClientsTable';
import { TABS_LABELS } from '../../utils/constants';

const ClientsPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const currentSection: string = location.pathname.slice(1).toUpperCase();
  const tabQueryParamValue = new URLSearchParams(location.search).get('tab');

  const [locationKeys, setLocationKeys] = React.useState<(string | undefined)[]>([]);
  const [currentTab, setCurrentTab] = React.useState(
    TABS_LABELS[tabQueryParamValue as keyof typeof TABS_LABELS],
  );

  const [page, setPage] = React.useState<number>(1);
  const [sortBy, setSortBy] = React.useState('');
  const [sortDirection, setSortDirection] = React.useState('');
  const [isHideEmpty, setIsHideEmpty] = React.useState(false);

  React.useEffect(() => {
    if (!tabQueryParamValue) {
      history.push(`clients?tab=${TABS_LABELS.CLIENTS}`);
    }
  }, []);

  React.useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        if (location.key) {
          setLocationKeys([location.key]);
        }
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          const tabQueryParamValueOnForwardClick = new URLSearchParams(location.search).get('tab');
          if (tabQueryParamValueOnForwardClick) {
            setCurrentTab(
              TABS_LABELS[tabQueryParamValueOnForwardClick as keyof typeof TABS_LABELS],
            );
          }
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          const tabQueryParamValueOnBackClick = new URLSearchParams(location.search).get('tab');
          if (tabQueryParamValueOnBackClick) {
            setCurrentTab(TABS_LABELS[tabQueryParamValueOnBackClick as keyof typeof TABS_LABELS]);
          }
        }
      }
    });
  }, [locationKeys]);

  const handleCurrentTab = (title: string) => {
    setCurrentTab(title);
    setPage(1);
    if (sortBy && sortDirection) {
      setSortBy('');
      setSortDirection('');
    }
    if (isHideEmpty) {
      setIsHideEmpty(false);
    }
    history.push(
      `clients?tab=${
        Object.entries(TABS_LABELS)
          .filter(([key, value]) => value === title)
          .flat()[0]
      }`,
    );
  };

  return (
    <>
      <AdminNavbar
        handleCurrentTab={handleCurrentTab}
        currentTab={currentTab ? currentTab : TABS_LABELS.CLIENTS}
        currentSection={currentSection}
      />
      <Container className="mt-4" fluid>
        <Row className="align-items-center">
          <Col xs="6">
            <h1 className="mb-0">{currentTab}</h1>
          </Col>
        </Row>
        <ClientsTable
          page={page}
          setPage={setPage}
          currentTabKey={tabQueryParamValue ? tabQueryParamValue : TABS_LABELS.CLIENTS}
          currentTab={currentTab ? currentTab : TABS_LABELS.CLIENTS}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          isHideEmpty={isHideEmpty}
          setIsHideEmpty={setIsHideEmpty}
        />
      </Container>
    </>
  );
};

export default ClientsPage;
