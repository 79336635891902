import { Link } from 'react-router-dom';
import { TABS_LABELS } from '../../utils/constants';
import RecipientInfoButton from './RecipientInfoButton';
import { UncontrolledTooltip } from 'reactstrap';

const RenderFieldValue = ({
  configKey,
  packageItem,
  config,
  setCurrentTab,
  setFilter,
  setFilterState,
  isPopoverOpen,
  setIsPopoverOpen,
  orderIndex,
}: any) => {
  const handleTogglePopover = (popoverId: string) => {
    if (isPopoverOpen === popoverId) {
      setIsPopoverOpen('');
    } else {
      setIsPopoverOpen(popoverId);
    }
  };

  if (configKey === 'statusId') {
    return config.render(packageItem[configKey]);
  }

  if (configKey === 'validated') {
    return config.render(packageItem[configKey]);
  }

  if (configKey === 'ticketFulfilled') {
    return config.render(packageItem[configKey]);
  }

  if (configKey === 'shipBy' && packageItem.intlShipBy) {
    return (
      <>
        {packageItem.intlShipBy}&nbsp;&nbsp;{' '}
        <span>
          <i className="fas fa-lg fa-regular fa-globe" id={`shipby${orderIndex}`} />
          <UncontrolledTooltip target={`shipby${orderIndex}`} delay={0}>
            Original ship by: {packageItem[configKey]}
          </UncontrolledTooltip>
        </span>
      </>
    );
  }

  if (configKey === 'recipient') {
    return (
      <RecipientInfoButton
        popoverId={orderIndex}
        config={config}
        handleTogglePopover={handleTogglePopover}
        isPopoverOpen={isPopoverOpen}
        item={packageItem}
        itemKey={configKey}
        setCurrentTab={setCurrentTab}
        setIsPopoverOpen={setIsPopoverOpen}
        setFilter={setFilter}
        setFilterState={setFilterState}
      />
    );
  }

  if (configKey === 'client') {
    return (
      <button
        className="btn-cell"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setCurrentTab(TABS_LABELS.VIEW_ALL);
          setFilter((state: any) => {
            return { ...state, [configKey]: packageItem[configKey] };
          });
          setFilterState((state: any) => {
            return { ...state, [configKey]: packageItem[configKey] };
          });
        }}
      >
        {packageItem[configKey]}
      </button>
    );
  }

  if (configKey === 'invoiceId') {
    return (
      <Link
        to={`/package-info/${packageItem[configKey]}`}
        target="_blank"
        style={{
          border: 'none',
          background: 'none',
          color: '#337ab7',
          padding: '0',
          textDecoration: 'underline',
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {packageItem[configKey]}
      </Link>
    );
  }

  return <>{packageItem[configKey]}</>;
};

export default RenderFieldValue;
