import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CommonLayout } from './Layout/CommonLayout/CommonLayout';
import Login from './pages/Login/Login';
import WorkflowPage from './pages/Workflow/Workflow';
import Docs from './pages/Docs/Docs';
import Admin from './pages/Admin/Admin';
import Reports from './pages/Reports/Reports';
import { checkToken } from './utils/services';
import PackageInfoPage from './pages/PackageInfo/PackageInfo';
import ClientsPage from './pages/Clients/Clients';
import { GET_PROFILE } from './queries/auth';
import { useApolloClient } from '@apollo/client';
import ForgotPasswordPage from './pages/Login/ForgotPassword';
import ResetPasswordPage from './pages/Login/ResetPassword';
import TicketsDashboard from './pages/TicketsDashboard/TicketsDashboard';

const PrivateRoute = ({ component: Component, ...rest }: any) => (
  <Route
    {...rest}
    render={(props) =>
      checkToken() ? (
        <CommonLayout>
          <Component {...props} {...rest} />
        </CommonLayout>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      )
    }
  />
);

export const Navigator: React.FC = () => {
  return (
    <Switch>
      <PrivateRoute exact component={WorkflowPage} path="/vip-workflow" />
      <PrivateRoute exact component={ClientsPage} path="/clients" />
      <PrivateRoute exact component={Docs} path="/docs" />
      <PrivateRoute exact component={Admin} path="/admin" />
      <PrivateRoute exact component={Reports} path="/reports" />
      <PrivateRoute exact component={PackageInfoPage} path="/package-info/:id" />
      <PrivateRoute exact component={TicketsDashboard} path="/tickets-dashboard" />

      <Route path="/login">
        <Login />
      </Route>
      {/* <Route path="/register">
        <SignUp />
      </Route> */}
      <Route path="/forgot-password">
        <ForgotPasswordPage />
      </Route>
      <Route exact component={ResetPasswordPage} path="/reset-password/:token" />

      <Redirect from="*" to="/vip-workflow" />
    </Switch>
  );
};
