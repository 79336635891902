import { Card, CardHeader, Row, CardBody } from 'reactstrap';
import { successMsg } from '../../cache/vars';
import { CRYPTO_JS_SECRET } from '../../utils/constants';
import PackageInfoActionButtons from './PackageInfoActionButtons';
import PackageInfoCartFilters from './PackageInfoCartFilters';
import CryptoJS from 'crypto-js/aes';
import moment from 'moment';

const PackageMenu = ({
  packageOrderWithDetails,
  activeCardsFilter,
  setActiveCardsFilter,
  availableFilters,
}: any) => {
  return (
    <Card style={{ marginBottom: '15px' }}>
      <CardHeader>
        <Row className="ml-2 mt-1">
          <h1 className="m-0">{packageOrderWithDetails?.eventName}</h1>
        </Row>
        <Row className="ml-2">
          <span className="m-0">
            {packageOrderWithDetails?.eventDate?.includes('3:30 am')
              ? packageOrderWithDetails.eventDate.replace('3:30 am', 'Time TBD')
              : packageOrderWithDetails?.eventDate}
          </span>
        </Row>
        <Row className="ml-2 mt-3">
          <span className="m-0" style={{ color: '#807979' }}>
            {packageOrderWithDetails?.venueCity}, {packageOrderWithDetails?.venue} (
            {packageOrderWithDetails?.venueAddress})
          </span>
        </Row>
      </CardHeader>
      <CardBody className="d-flex flex-column">
        <Row className="ml-0 mr-0 ml-2 mb-1">
          <h2 className="m-0">
            {packageOrderWithDetails?.section} {packageOrderWithDetails?.row}
          </h2>
        </Row>
        <Row className="ml-2 mr-0">
          {moment(packageOrderWithDetails?.startDate, 'MMMM Do YYYY').toLocaleString() ===
          moment(packageOrderWithDetails?.endDate, 'MMMM Do YYYY').toLocaleString()
            ? packageOrderWithDetails?.startDate
            : packageOrderWithDetails?.startDate + ' - ' + packageOrderWithDetails?.endDate}
        </Row>
        <Row className="ml-2 mt-2">
          <span>{packageOrderWithDetails?.recipient}</span>
        </Row>
        <Row className="ml-2">
          <span>{packageOrderWithDetails?.recipientEmail}</span>
        </Row>
        <Row className="ml-2 mt-2 d-flex flex-column">
          <span className="m-0">
            Invoice Id:&nbsp;
            {packageOrderWithDetails?.invoiceId}
          </span>
          <span className="m-0">
            Purchase Order ID:&nbsp;
            {packageOrderWithDetails?.purchaseOrderId || '-'}
          </span>
          <span className="m-0">
            Order ID:&nbsp;
            {packageOrderWithDetails?.externalPo || '-'}
          </span>
        </Row>
        <Row className="ml-2 mt-2">
          <button
            className="m-0 btn btn-sm btn-primary"
            onClick={() => {
              const encryptedInvoiceId = CryptoJS.encrypt(
                `${packageOrderWithDetails?.invoiceId}`,
                CRYPTO_JS_SECRET,
              ).toString();

              const encryptedInvoiceIdWithReplacedSlashes = encryptedInvoiceId.replace(
                /\//g,
                'HjsOwpeSBqdLafaFsjhHfd73sb7kdheYTsd61',
              );

              const invoiceLink: string = `https://${process.env.REACT_APP_APPLICATION_HOST}/package-info/${encryptedInvoiceIdWithReplacedSlashes}`;
              navigator.clipboard.writeText(invoiceLink);
              successMsg('Copied to clickboard');
            }}
          >
            <i className="fa fa-copy"></i>&nbsp;&nbsp; Share link with Client
          </button>
        </Row>
        <PackageInfoActionButtons />
        <PackageInfoCartFilters
          activeCardsFilter={activeCardsFilter}
          setActiveCardsFilter={setActiveCardsFilter}
          availableFilters={availableFilters}
        />
      </CardBody>
    </Card>
  );
};

export default PackageMenu;
