import { Row } from 'reactstrap';

const PackageInfoActionButtons = () => {
  return (
    <Row className="d-flex justify-content-around mt-4">
      <button className="btn" disabled={true}>
        <i className="fas fa-lg fa-calendar" />
      </button>
      <button className="btn" disabled={true}>
        <i className="fas fa-lg fa-envelope" />
      </button>
      {/* <PDFDownloadLink
                    document={
                      <MyDocument
                        title={data?.getOnePackageWithDetails.eventName}
                        invoiceId={id}
                        orderDetails={orderDetails}
                      />
                    }
                    fileName={`invoice-${id}.pdf`}
                  >
                    {() => (
                      <button className="btn">
                        <i className="fas fa-lg fa-save" />
                      </button>
                    )}
                  </PDFDownloadLink> */}
      <button className="btn" disabled={true}>
        <i className="fas fa-lg fa-search" />
      </button>
    </Row>
  );
};

export default PackageInfoActionButtons;
