import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { Card, Spinner, Table } from 'reactstrap';
import { isTableDataLoading } from '../../cache/vars';
import FiltersModal from '../Modals/VipWorkflowFiltersModal/FiltersModal';
import PaginationComponent from '../Pagination/Pagination';
import './Table.scss';
import TableActions from './TableActions';
import TableBody from './TableBody';
import TableHead from './TableHead';

const TableLayout = ({
  currentTab,
  setCurrentTab,
  setFilter,
  filterState,
  setFilterState,
  sortBy,
  sortDirection,
  setSortBy,
  setSortDirection,
  isDetailsOpened,
  setIsDetailsOpened,
  tableConfig,
  orders,
  isQueryLoading,
  totalPageCount,
  totalPackagesCount,
  page,
  setPage,
  setItemsPerPage,
  itemsPerPage,
  csrIdsToFilter,
  setCsrIdsToFilter,
  csrListState,
  typesToFilter,
  setTypesToFilter,
  getPackageQuery,
  sortDateRangeBy,
  setSortDateRangeBy,
  dateRange,
  setDateRange,
}: any) => {
  const isLoading = useReactiveVar(isTableDataLoading);

  const [isTooltipOpen, setIsTooltipOpen] = React.useState('');
  const [isPopoverOpen, setIsPopoverOpen] = React.useState('');
  const [isFiltersModalOpen, setIsFiltersModalOpen] = React.useState(false);

  const handleApplyFilters = (e: any) => {
    e.preventDefault();
    setIsPopoverOpen('');
    setFilter((state: any) => {
      return { ...filterState };
    });
    if (isFiltersModalOpen) {
      setIsFiltersModalOpen(false);
    }
  };

  const handleClearFilters = () => {
    if (isFiltersModalOpen) {
      setIsFiltersModalOpen(false);
    }

    if (sortDateRangeBy) {
      setSortDateRangeBy('saleDate');
    }

    if (dateRange && dateRange.length) {
      setDateRange(null);
    }

    if (csrIdsToFilter) {
      setCsrIdsToFilter([]);
    }

    if (typesToFilter) {
      setTypesToFilter([]);
    }

    setFilter({});
    setFilterState({});
  };

  const handleSorting = (key: string) => {
    setSortBy(key);
    setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
  };

  const handleToggleTooltip = (tooltipId: string) => {
    if (isTooltipOpen === tooltipId) {
      setIsTooltipOpen('');
    } else {
      setIsTooltipOpen(tooltipId);
    }
  };

  const handleCsrChange = async (csrsData: { label: string; value: number }[]) => {
    if (!csrsData.length) {
      setCsrIdsToFilter([]);
    }

    setCsrIdsToFilter(csrsData);
  };

  const handleTypeFilter = (types: { label: string; value: number }[]) => {
    if (!types.length) {
      setTypesToFilter([]);
    }

    setTypesToFilter(types);
  };

  const handleClearFiltersModal = () => {
    setFilter({});
    setFilterState({});
  };
  
  const handleReset = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSortBy('');
    setSortDirection('');
  };

  return (
    <Card>
      <FiltersModal
        isFiltersModalOpen={isFiltersModalOpen}
        setIsFiltersModalOpen={setIsFiltersModalOpen}
        tableConfig={tableConfig}
        filterState={filterState}
        setFilterState={setFilterState}
        handleApplyFilters={handleApplyFilters}
        handleClearFilters={handleClearFiltersModal}
      />
      <TableActions
        currentTab={currentTab}
        handleCsrChange={handleCsrChange}
        handleClearFilters={handleClearFilters}
        setIsFiltersModalOpen={setIsFiltersModalOpen}
        csrListState={csrListState}
        csrIdsToFilter={csrIdsToFilter}
        handleTypeFilter={handleTypeFilter}
        typesToFilter={typesToFilter}
        sortDateRangeBy={sortDateRangeBy}
        setSortDateRangeBy={setSortDateRangeBy}
        dateRange={dateRange}
        setDateRange={setDateRange}
        getPackageQuery={getPackageQuery}
      />
      <div
        style={{
          maxHeight: '65vh',
          overflowY: 'auto',
        }}
        onScroll={() => {
          if (isPopoverOpen) {
            setIsPopoverOpen('');
          }
        }}
      >
        <Table className="Table" responsive>
          <TableHead
            tableConfig={tableConfig}
            setFilterState={setFilterState}
            handleSorting={handleSorting}
            handleReset={handleReset}
            sortBy={sortBy}
            sortDirection={sortDirection}
            filterState={filterState}
            handleApplyFilters={handleApplyFilters}
            setFilter={setFilter}
          />
          {isQueryLoading || isLoading ? (
            <tr>
              <td colSpan={17} className="p-3 text-center">
                <Spinner animation="border" variant="info" />
              </td>
            </tr>
          ) : null}
          {orders.length && !isQueryLoading && !isLoading ? (
            <TableBody
              orders={orders}
              currentTab={currentTab}
              setIsDetailsOpened={setIsDetailsOpened}
              tableConfig={tableConfig}
              handleToggleTooltip={handleToggleTooltip}
              isTooltipOpen={isTooltipOpen}
              setCurrentTab={setCurrentTab}
              setFilter={setFilter}
              setFilterState={setFilterState}
              isPopoverOpen={isPopoverOpen}
              setIsPopoverOpen={setIsPopoverOpen}
              isDetailsOpened={isDetailsOpened}
            />
          ) : !isQueryLoading && !isLoading ? (
            <tr>
              <td colSpan={17} className="p-3 text-center">
                <h2>Data Not Found</h2>
              </td>
            </tr>
          ) : null}
        </Table>
      </div>
      {!isQueryLoading && !isLoading && orders.length ? (
        <PaginationComponent
          page={page}
          setPage={setPage}
          totalPageCount={totalPageCount - 1}
          totalItemsCount={totalPackagesCount}
          setItemsPerPage={setItemsPerPage}
          itemsPerPage={itemsPerPage}
        />
      ) : null}
    </Card>
  );
};

export default TableLayout;
