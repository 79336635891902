export const DailyInventoryReport: React.FC = () => {
  return (
    <ul>
      <li>
        Create subset of data that will select all sales from the previous day where the Ticket
        Group Code is not in the following list -
        <ul>
          <li>
            'CATFB','CATFBTG','CATNW','CATNWTG','NWSPORTS','FILLCONCERTS','FILLSPORTS','CAT',
            'PSCONCERTS', 'NWSPORTSTG', 'NWTGSH'
          </li>
        </ul>
      </li>
      <li>
        Create subset of data that will select all sales from the current month where the Ticket
        Group Code is not in the following list -
        <ul>
          <li>
            'CATFB','CATFBTG','CATNW','CATNWTG','NWSPORTS','FILLCONCERTS','FILLSPORTS','CAT',
            'PSCONCERTS', 'NWSPORTSTG', 'NWTGSH'
          </li>
        </ul>
      </li>
      <li>
        Using those subsets of data, we will get totals based on the ticket group code. Separating
        them out into the Primary Inventory and Alternate inventory buckets. We will provide one set
        of totals for Yesterday and another for Month to date.
        <ul>
          <li>
            Primary inventory is defined as Ticket Group Codes - 'PSUSA', 'LP', 'LP1', 'AM', 'CJ',
            'LM', 'LM1', 'RH', 'RH1'
          </li>
          <li>
            Alternate is define as Ticket Group Codes - 'CONSIGNPSUSA', 'CONSIGN', 'FILLSPORTS1',
            'FILLCONCERTS1', 'JMS', 'LMS', 'OPSET'
          </li>
          <li>
            Other will be listed under Alternate, and will include and Ticket group code that is not
            in the following list -
            <ul>
              <li>
                {' '}
                'PSUSA', 'LP', 'AM', 'CJ', 'LM', 'RH', 'CONSIGNPSUSA', 'CONSIGN', 'FILLSPORTS1',
                'FILLCONCERTS1', 'JMS', 'LMS', 'OPSET',
                'CATFB','CATFBTG','CATNW','CATNWTG','NWSPORTS','FILLCONCERTS','FILLSPORTS','CAT',
                'PSCONCERTS', 'NWSPORTSTG', 'NWTGSH', 'RH1', 'LM1', 'LP1'
              </li>
            </ul>
          </li>
        </ul>
        <p></p>
        <h4>Full Inventory Tab</h4>
      </li>
      <li>
        Take the Sales data from the previous day and put all of the detail data on the 2nd tab,
        labeled Full Inventory.
        <p></p>
        <h4>Ticket Group Code Tabs</h4>
      </li>
      <li>
        {' '}
        Then we add a tab for each of the defined ticket group codes in Primary and Alternate
        Inventory and will put the previous days sales for them each in a tab. This will also
        include Other.
      </li>
    </ul>
  );
};
