import React from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { Navigator } from './Navigator';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  ApolloLink,
  useReactiveVar,
  useQuery,
  useApolloClient,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { errorMsg, isTableDataLoading, successMsg, userRole, infoMsg } from './cache/vars';
import { createUploadLink } from 'apollo-upload-client';
import NotificationAlert from 'react-notification-alert';
import { GET_PROFILE } from './queries/auth';
import { checkToken } from './utils/services';
import { JWT_KEY } from './utils/constants';

const App: React.FC = () => {
  const history = useHistory();

  const httpLink = createUploadLink({
    uri: `${
      process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === 'development' ? 'http' : 'https'
    }://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}/graphql`,
    credentials: 'include',
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        const extensionsCode = extensions && extensions.code ? extensions.code : null;
        switch (extensionsCode) {
          case "UNAUTHENTICATED":
            const jwt = document.cookie.match(/jwt=[^\s]+/);
            const jwtValue = jwt && jwt[0].replace("jwt=", "");
            errorMsg(message);
            if (jwtValue) {
              history.push("/login");
              break;
            }
            break;

          case "BAD_USER_INPUT":
            isTableDataLoading(false);
            return errorMsg(message || "The field(s) cannot be empty");

          case "INTERNAL_SERVER_ERROR":
          case "500":
            isTableDataLoading(false);
            return errorMsg(message);

          default:
            console.warn(`[Unhandled error code]: ${extensionsCode}`);
            return errorMsg(message);
        }
      });
    }
  
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const authLink = new ApolloLink((operation, forward) => {
    const jwt = document.cookie.match(/jwt=[^\s]+/);
    const jwtValue = jwt && jwt[0].replace('jwt=', '');

    operation.setContext({
      headers: {
        authorization: jwtValue ? `Bearer ${jwtValue}` : '',
      },
    });

    return forward(operation);
  });

  const client = new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <Root />
    </ApolloProvider>
  );
};

const Root = () => {
  const client = useApolloClient();

  const notificationAlertRef = React.useRef(null) as any;

  const getUserProfile = async () => {
    const { data } = await client.query({
      query: GET_PROFILE,
      fetchPolicy: 'cache-first',
    });

    userRole(data?.getProfile.role);
  };

  React.useEffect(() => {
    if (checkToken()) {
      getUserProfile();
    }
  }, []);

  const errorMessage = useReactiveVar(errorMsg);
  const successMessage = useReactiveVar(successMsg);
  const informationMessage = useReactiveVar(infoMsg);

  const notify = (type: any, errorMessage: string) => {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span data-notify="message">{errorMessage}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  React.useEffect(() => {
    if (errorMessage) {
      notify('warning', errorMessage);
      errorMsg('');
    }
    if (successMessage) {
      notify('success', successMessage);
      successMsg('');
    }
    if (informationMessage) {
      notify('info', informationMessage);
      infoMsg('');
    }
  }, [errorMessage, informationMessage, successMessage]);

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <BrowserRouter>
        <Navigator />
      </BrowserRouter>
    </>
  );
};

export default App;
