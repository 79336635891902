import "antd/dist/antd.css";
import { Menu, Dropdown, Button } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { Spinner } from "reactstrap";

import './dropdownUpload.scss';

const menu = (props: any) => (
  <Menu>
    {props}
  </Menu>
);

export default function DropdownUpload({ children, disabled, loading }: any) {
  return (
    <div className="">
      <Dropdown disabled={disabled} arrow={true} overlay={menu(children)}>
        <Button
          className="upload-button"
          style={{
          background: '#5e72e4',
          color: '#ffffff',
          border: 'none',
          borderRadius: '8px',
          height: '46px',
          marginRight: '10px',
          }}
          icon={loading ? ( <Spinner animation="border" variant="info" size="sm" className="mr-2" />) : (<UploadOutlined />)}
        >
          {loading ? 'Loading...' : 'Upload'}
        </Button>
      </Dropdown>
    </div>
  );
}