import "antd/dist/antd.css";
import { Menu, Dropdown, Button } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { Spinner } from "reactstrap";

import './dropdownUrls.scss';

const menu = (props: any) => (
  <Menu>
    {props}
  </Menu>
);

export default function DropdownUrls({ children, disabled, loading }: any) {
  return (
    <div className="">
      <Dropdown disabled={disabled} arrow={true} overlay={menu(children)}>
        <Button
          className='btn text-primary border-primary add-url'
          style={{
            borderRadius: '8px',
            height: '46px',
            marginRight: '10px',
          }}
          icon={loading ? ( <Spinner animation="border" variant="info" size="sm" className="mr-2" />) : (<PlusOutlined />)}
        >
          {loading ? 'Loading...' : 'Add url'}
        </Button>
      </Dropdown>
    </div>
  );
}