import { useApolloClient } from '@apollo/client';
import React from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Modal } from 'reactstrap';
import { successMsg } from '../../cache/vars';
import { DELETE_USERS_ASSOC, EDIT_USER } from '../../queries/users';
import { CLIENT_ROLES } from '../../utils/constants';
import AutocompleteInput from '../AutocompleteInput/AutocompleteInput';
import CloseModalButton from '../Buttons/CloseModalButton';

const EditUser = ({ isModalOpen, setIsModalOpen, userToEdit, setUserToEdit, setClients }: any) => {
  const client = useApolloClient();

  const [updatedUser, setUpdatedUser] = React.useState(
    userToEdit
      ? {
          brokerId: userToEdit.brokerId || null,
          clientBrokerName: userToEdit.clientBrokerName || '',
          createdAtDate: userToEdit.createdAtDate,
          email: userToEdit.email,
          phoneNumber: userToEdit.phoneNumber,
          role: userToEdit.role,
          userId: userToEdit.userId,
          username: userToEdit.username || '',
          associatedEmails: userToEdit.associatedEmails,
        }
      : {},
  );
  const [isSearchInRecipientTable, setIsSearchInRecipientTable] = React.useState(false);

  const handleInputChange = (e: any) => {
    if (e.target.name === 'role') {
      setUpdatedUser((prev: any) => {
        if (e.target.value === 'BROKER') {
          return { ...prev, [e.target.name]: e.target.value, clientBrokerName: '', brokerId: null };
        } else {
          return { ...prev, [e.target.name]: e.target.value, brokerId: null, clientBrokerName: '' };
        }
      });
    } else {
      setUpdatedUser((prev: any) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={() => {
        setIsModalOpen(false);
        setUserToEdit(null);
      }}
      className="modal-dialog-centered modal-secondary"
      size="md"
      fade={false}
    >
      <div className="modal-body pb-0">
        <div className="d-flex justify-content-between ">
          <div>
            <h1>Edit User</h1>
          </div>
          <div>
            <CloseModalButton
              handleCloseModalButtonClick={(e) => {
                e.preventDefault();
                setIsModalOpen(false);
              }}
            />
          </div>
        </div>
        <Form
          role="form"
          onSubmit={async (e) => {
            e.preventDefault();
            const { clientBrokerName, associatedEmails, ...updatedUserData } = updatedUser;
            const { data } = await client.query({
              query: EDIT_USER,
              variables: { updatedUserInput: { ...updatedUserData } },
              fetchPolicy: 'no-cache',
            });

            setClients((prev: any) => {
              return prev.map((item: any) =>
                item.userId === data?.editUser.userId ? data?.editUser : item,
              );
            });
            setIsModalOpen(false);
            successMsg('Saved');
          }}
        >
          <FormGroup>
            <Input
              name="username"
              placeholder="User Name"
              onChange={handleInputChange}
              value={updatedUser.username}
            />
          </FormGroup>
          <FormGroup className={!updatedUser.email ? 'mb-0' : ''}>
            <Input
              invalid={!updatedUser.email}
              name="email"
              placeholder="Email"
              onChange={handleInputChange}
              value={updatedUser.email}
            />
            <FormFeedback style={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}>
              This field can not be empty
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Input
              name="phoneNumber"
              placeholder="Phone Number"
              onChange={handleInputChange}
              value={updatedUser.phoneNumber}
            />
          </FormGroup>
          <FormGroup>
            <Input
              className="form-control-alternative new-event--title"
              type="select"
              name="role"
              placeholder="Role"
              onChange={handleInputChange}
              value={updatedUser.role}
            >
              {CLIENT_ROLES.map((role: string, id) => {
                return (
                  <option key={id} value={role}>
                    {role}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
          <FormGroup>
            <AutocompleteInput
              updatedUser={updatedUser}
              userData={userToEdit}
              setUpdatedUser={setUpdatedUser}
              isSearchInRecipientTable={isSearchInRecipientTable}
            />
          </FormGroup>
          {updatedUser && updatedUser.role === 'RECIPIENT' && updatedUser.associatedEmails ? (
            <div className="d-flex flex-column w-100">
              {updatedUser.associatedEmails.map((assoc: any, id: number) => {
                return (
                  <div key={id} className="d-flex align-items-center justify-content-between">
                    <span>{assoc.assignedEmail}</span>
                    <button
                      className="btn btn-sm text-right"
                      onClick={async (e) => {
                        e.preventDefault();

                        const deletedUser = await client.mutate({
                          mutation: DELETE_USERS_ASSOC,
                          variables: {
                            id: assoc.id,
                          },
                        });

                        if (deletedUser.data?.deleteUsersAssoc === 'ok') {
                          setUpdatedUser((prev: any) => {
                            return {
                              ...prev,
                              associatedEmails: prev.associatedEmails.filter(
                                (item: any) => item.id !== assoc.id,
                              ),
                            };
                          });

                          successMsg('Deleted');
                        }
                      }}
                    >
                      delete
                    </button>
                  </div>
                );
              })}
            </div>
          ) : null}
          <div className="text-right">
            <Button className="my-4" color="info" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default EditUser;
