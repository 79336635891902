import { useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import { Col, Form, Input } from 'reactstrap';
import { successMsg } from '../../../cache/vars';
import { RENAME_CARD, REVERT_CARD_TITLE_TO_ORIGINAL } from '../../../queries/vip-workflow';
import * as Yup from 'yup';

const VALIDATION_SCHEMA = Yup.object().shape({
  cardTitle: Yup.string()
    .matches(/^\S.*[a-zA-Z\s]*$/)
    .required('Required'),
});

const CardTitle = ({
  isEditCardTitle,
  cardTitle,
  setCardTitle,
  modalState,
  setPackageOrderWithDetails,
  packageDetailsId,
  setIsEditCardTitle,
  modaName,
  setModalState,
  editCardTitleRef,
}: any) => {
  const client = useApolloClient();

  return (
    <Col className="d-flex" sm="11">
      {isEditCardTitle ? (
        <Formik
          initialValues={{ cardTitle }}
          onSubmit={async (cardTitleData) => {
            const { data } = await client.mutate({
              mutation: RENAME_CARD,
              variables: {
                packageDetailsId: modalState.packageDetailsId,
                cartId: modalState.cartId,
                newTitle: cardTitleData.cardTitle,
              },
            });

            if (data) {
              if (setPackageOrderWithDetails) {
                setPackageOrderWithDetails((prev: any) => {
                  return {
                    ...prev,
                    orderDetails: prev.orderDetails.map((item: any) =>
                      item.packageDetailsId === packageDetailsId
                        ? {
                            ...item,
                            cartDetails: {
                              ...item.cartDetails,
                              cardTitle: cardTitleData.cardTitle,
                            },
                          }
                        : item,
                    ),
                  };
                });
              }

              setModalState((prev: any) => {
                return {
                  ...prev,
                  cartId: data?.renameCard.cartId,
                  cardTitle: data?.renameCard.cardTitle,
                };
              });
              setCardTitle(cardTitleData.cardTitle);
              successMsg('Card renamed');
              setIsEditCardTitle(false);
            }
          }}
          validationSchema={VALIDATION_SCHEMA}
        >
          {(props) => {
            const { values, errors, handleChange, handleSubmit } = props;

            return (
              <Form className="d-flex flex-column w-100" onSubmit={handleSubmit}>
                <Input
                  type="text"
                  value={values.cardTitle}
                  onChange={handleChange}
                  style={{ marginBottom: '0.4rem' }}
                  ref={editCardTitleRef}
                  autoFocus
                  name="cardTitle"
                />
                <div className="d-flex justify-content-end">
                  <button
                    style={{ marginBottom: '0.8rem' }}
                    className="btn btn-primary btn-sm ml-3"
                    disabled={!!errors.cardTitle}
                    type="submit"
                  >
                    Save
                  </button>
                  {values.cardTitle !== modaName ? (
                    <button
                      style={{ marginBottom: '0.8rem' }}
                      className="btn btn-primary btn-sm"
                      onClick={async (e) => {
                        e.preventDefault();
                        const { data } = await client.mutate({
                          mutation: REVERT_CARD_TITLE_TO_ORIGINAL,
                          variables: {
                            cartId: modalState.cartId,
                          },
                        });

                        if (
                          data?.revertCardTitleToOriginal === 'ok' &&
                          setPackageOrderWithDetails
                        ) {
                          setModalState((prev: any) => {
                            return { ...prev, cardTitle: modaName };
                          });

                          setCardTitle(modaName);

                          setPackageOrderWithDetails((prev: any) => {
                            return {
                              ...prev,
                              orderDetails: prev.orderDetails.map((item: any) =>
                                item.packageDetailsId === modalState.packageDetailsId
                                  ? {
                                      ...item,
                                      cartDetails: {
                                        ...item.cartDetails,
                                        cardTitle: '',
                                      },
                                    }
                                  : item,
                              ),
                            };
                          });

                          successMsg('Title Reverted');
                          setIsEditCardTitle(false);
                        }
                      }}
                    >
                      Revert To Original
                    </button>
                  ) : null}
                  <button
                    style={{ marginBottom: '0.8rem' }}
                    className="btn btn-primary btn-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      setCardTitle(cardTitle);
                      setIsEditCardTitle(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <>
          <h1>{cardTitle}</h1>
          <button
            style={{
              marginBottom: '0.8rem',
              border: 'none',
              background: 'none',
              padding: '0px 15px 0px 15px',
            }}
            onClick={(e) => {
              e.preventDefault();
              setIsEditCardTitle(true);
            }}
          >
            <i className="fa fa-solid fa-pen"></i>
          </button>
        </>
      )}
    </Col>
  );
};

export default CardTitle;
