import { useApolloClient } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardHeader, Col, Form, FormGroup, Row, Spinner, Table } from 'reactstrap';
import { clientsFilter, successMsg } from '../../cache/vars';
import { BROKERS } from '../../queries/brokers';
import { RECIPIENTS } from '../../queries/recipients';
import { DELETE_USER, USERS_LIST } from '../../queries/users';
import { tableFieldTypes as types, TABS_LABELS } from '../../utils/constants';
import { formatPrice } from '../../utils/services';
import { TABLE_CONFIG } from '../../utils/tableConfigs';
import EditUser from '../Modals/EditUser';
import PaginationComponent from '../Pagination/Pagination';
import '../WorkflowTable/Table.scss';

const responseKeys = {
  [TABS_LABELS.CLIENTS]: 'brokers',
  [TABS_LABELS.RECIPIENTS]: 'recipients',
  [TABS_LABELS.USERS]: 'usersList',
};

const formatValue = {
  [types.price]: (val: any) => formatPrice(val),
  [types.percent]: (val: any) => `${val}%`,
  [types.number]: (val: any) => (val ? parseInt(val) : null),
  [types.string]: (val: any) => val,
  [types.bool]: (val: any) => <input type="checkbox" defaultChecked={!!val} />,
};

const ClientsTable = ({
  page,
  setPage,
  currentTabKey,
  currentTab,
  sortBy,
  setSortBy,
  sortDirection,
  setSortDirection,
  isHideEmpty,
  setIsHideEmpty,
}: any) => {
  const client = useApolloClient();
  const history = useHistory();

  const tableConfig = TABLE_CONFIG[currentTabKey as keyof typeof TABLE_CONFIG];

  const [itemsPerPage, setItemsPerPage] = React.useState<number>(25);

  const [isLoading, setIsLoading] = React.useState(false);

  const [clients, setClients] = React.useState([]);
  const [totalPageCount, setTotalPageCount] = React.useState(0);
  const [totalClientsCount, setTotalClientsCount] = React.useState(0);
  const [searchInput, setSearchInput] = React.useState('');
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [userToEdit, setUserToEdit] = React.useState(null);
  const [filterState, setFilterState] = React.useState<any>({});

  const getCLients = async (QUERY: any) => {
    setIsLoading(true);

    const clients = await client.query({
      query: QUERY,
      variables: {
        page,
        itemsPerPage,
        sortBy,
        sortDirection,
        search: searchInput,
        isHideEmpty,
        filter: filterState,
      },
      fetchPolicy: 'no-cache',
    });

    setIsLoading(false);
    setClients(clients.data[responseKeys[currentTab]]?.clientData);
    setTotalPageCount(clients.data[responseKeys[currentTab]].totalPageCount);
    setTotalClientsCount(clients.data[responseKeys[currentTab]].count);
  };

  React.useEffect(() => {
    switch (currentTab) {
      case TABS_LABELS.CLIENTS:
        getCLients(BROKERS);
        break;

      case TABS_LABELS.RECIPIENTS:
        getCLients(RECIPIENTS);
        break;

      case TABS_LABELS.USERS:
        getCLients(USERS_LIST);
        break;

      default:
        return;
    }
  }, [currentTab, page, itemsPerPage, sortBy, sortDirection, isHideEmpty]);

  const handleSorting = (key: string) => {
    setSortBy(key);
    setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
  };

  const renderFieldValue = (key: keyof typeof tableConfig, item: any, config: any) => {
    if (key === 'name' || key === 'email') {
      if (!item[key]) return;

      return <button className="btn-cell">{item[key]}</button>;
    }

    if (key === 'action') {
      return (
        <>
          <button
            className="btn btn-sm btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              setUserToEdit(item);
              setIsModalOpen(true);
            }}
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-danger"
            onClick={async (e) => {
              e.stopPropagation();
              if (window.confirm('Are you sure you want to delete this user?')) {
                const { data } = await client.query({
                  query: DELETE_USER,
                  variables: {
                    userId: item.userId,
                  },
                });

                setClients((prev: any) => {
                  return prev.filter((item: any) => item.userId !== data?.deleteUser);
                });

                successMsg('User Deleted');
              }
            }}
          >
            Delete
          </button>
        </>
      );
    }

    return formatValue[config.type](item[key]);
  };

  const handleSelectChange = async (e: any, config: any) => {
    setFilterState((state: any) => {
      if (e.target.value === '') {
        delete state[config.key];
        return { ...state };
      }

      return {
        ...state,
        [config.key]: e.target.value,
      };
    });

    setIsLoading(true);

    const { role, ...rest } = filterState;

    const clients = await client.query({
      query: USERS_LIST,
      variables: {
        page,
        itemsPerPage,
        sortBy,
        sortDirection,
        search: searchInput,
        isHideEmpty,
        filter: e.target.value ? { ...filterState, role: e.target.value } : rest,
      },
      fetchPolicy: 'no-cache',
    });

    setIsLoading(false);
    setClients(clients.data[responseKeys[currentTab]]?.clientData);
    setTotalPageCount(clients.data[responseKeys[currentTab]].totalPageCount);
    setTotalClientsCount(clients.data[responseKeys[currentTab]].count);
  };
  
  const handleReset = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSortBy('');
    setSortDirection('');
  };

  if (isLoading) {
    return (
      <Row className="justify-content-center mb-xl-4">
        <Spinner animation="border" variant="info" />
      </Row>
    );
  }

  return (
    <>
      <EditUser
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        userToEdit={userToEdit}
        setUserToEdit={setUserToEdit}
        key={isModalOpen}
        setClients={setClients}
      />
      <Card>
        <CardHeader className="border-0">
          <Row className="d-flex align-items-center">
            <Col xs="2">
              <h3 className="mb-0">{currentTab}</h3>
            </Col>
            <Col xs="10">
              <div className="d-flex justify-content-end align-items-center m-0">
                {currentTab === TABS_LABELS.CLIENTS ? (
                  <div className="mr-3 d-flex align-items-center">
                    <span>Hide Empty</span>
                    <input
                      type="checkbox"
                      className="ml-2"
                      onChange={() => {
                        setIsHideEmpty((prev: boolean) => {
                          return !prev;
                        });
                        setPage(1);
                      }}
                      checked={isHideEmpty}
                    />
                  </div>
                ) : null}
                {currentTab !== TABS_LABELS.USERS ? (
                  <Form
                    className="w-25"
                    onSubmit={async (e) => {
                      e.preventDefault();

                      if (currentTab === TABS_LABELS.CLIENTS) {
                        const clients = await client.query({
                          query: BROKERS,
                          variables: {
                            page: 1,
                            itemsPerPage,
                            sortBy,
                            sortDirection,
                            search: searchInput,
                            isHideEmpty,
                          },
                          fetchPolicy: 'no-cache',
                        });

                        setPage(1);
                        setClients(clients.data[responseKeys[currentTab]]?.clientData);
                        setTotalPageCount(clients.data[responseKeys[currentTab]].totalPageCount);
                        setTotalClientsCount(clients.data[responseKeys[currentTab]].count);
                      } else {
                        const clients = await client.query({
                          query: RECIPIENTS,
                          variables: {
                            page: 1,
                            itemsPerPage,
                            sortBy,
                            sortDirection,
                            search: searchInput,
                          },
                          fetchPolicy: 'no-cache',
                        });

                        setPage(1);
                        setClients(clients.data[responseKeys[currentTab]]?.clientData);
                        setTotalPageCount(clients.data[responseKeys[currentTab]].totalPageCount);
                        setTotalClientsCount(clients.data[responseKeys[currentTab]].count);
                      }
                    }}
                  >
                    <FormGroup className="d-flex justify-content-end align-items-center m-0">
                      <input
                        className="mr-4 p-1 w-100"
                        placeholder={
                          currentTab === TABS_LABELS.CLIENTS
                            ? 'Search By Name'
                            : 'Search By Name Or Email'
                        }
                        onChange={(e) => setSearchInput(e.target.value)}
                        value={searchInput}
                      />
                      <Button
                        className="btn-round btn-icon d-flex align-items-center p-2"
                        color="primary"
                        size="sm"
                        type="submit"
                      >
                        <span className="btn-inner--icon mr-1">
                          {isLoading ? (
                            <Spinner animation="border" variant="info" size="sm" />
                          ) : (
                            <i className="fas fa-search ml-1" />
                          )}
                        </span>
                        <span className="btn-inner--text">Search</span>
                      </Button>
                    </FormGroup>
                  </Form>
                ) : null}
              </div>
            </Col>
          </Row>
        </CardHeader>
        <div
          style={{
            maxHeight: '65vh',
            overflowY: 'auto',
          }}
        >
          <Table className="Table" responsive>
            <thead className="thead-light text-center">
              <tr>
                {Object.entries(tableConfig).map(([key, config]: any, id) => (
                  <th
                    key={id}
                    onClick={() => {
                      if (config.isSortable) {
                        handleSorting(config.key);
                      }
                    }}
                  >
                    {config.colTitle}&nbsp;
                    {sortBy && sortBy === key ? (
                      sortDirection === 'ASC' ? (
                        <>
                          <span>&uarr;</span>
                          <button type='button' onClick={handleReset} 
                          style={
                            {
                              border: 'none',
                              background: 'none',
                              outline: 'none',
                              cursor: 'pointer',
                              padding: '0'
                            }
                          }>
                            &nbsp;↺&nbsp;
                          </button>
                        </>
                      ) : (
                        <>
                          <span>&darr;</span>
                          <button type='button' onClick={handleReset} 
                          style={
                            {
                              border: 'none',
                              background: 'none',
                              outline: 'none',
                              cursor: 'pointer',
                              padding: '0'
                            }
                          }>
                            &nbsp;↺&nbsp;
                          </button>
                        </>
                      )
                    ) : config.isSortable ? (
                      <>
                        <span>&uarr;</span>
                        <span>&darr;</span>
                      </>
                    ) : null}
                  </th>
                ))}
              </tr>

              {currentTab === TABS_LABELS.USERS ? (
                <tr>
                  {Object.entries(tableConfig).map(([key, config]: any, id) => {
                    if (config.isHideFilter)
                      return <th key={id} className="pl-2 pr-2 pt-1 pb-1"></th>;

                    return (
                      <th key={id} className="pl-2 pr-2 pt-1 pb-1">
                        {key === 'role' ? (
                          <select
                            style={{ height: '21px' }}
                            onChange={(e) => {
                              handleSelectChange(e, config);
                            }}
                            value={filterState?.[config.key] || ''}
                          >
                            <option value=""></option>
                            <option value="BROKER">BROKER</option>
                            <option value="RECIPIENT">RECIPIENT</option>
                          </select>
                        ) : (
                          <>
                            <i className="fas fa-filter mr-1" />
                            <input
                              style={{ width: '85%', appearance: 'auto' }}
                              value={filterState?.[config.key] || ''}
                              type={config.type}
                              onChange={(e) => {
                                setFilterState((state: any) => {
                                  if (e.target.value === '') {
                                    delete state[config.key];

                                    return { ...state };
                                  }

                                  return {
                                    ...state,
                                    [config.key]:
                                      key === 'createdAt' || key === 'phoneNumber'
                                        ? e.target.value
                                        : config.type === 'number'
                                        ? Number.parseFloat(e.target.value)
                                        : e.target.value.toLowerCase(),
                                  };
                                });
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  getCLients(USERS_LIST);
                                }
                              }}
                            />
                          </>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ) : null}
            </thead>
            {clients.length ? (
              <tbody>
                {clients.map((item: any, id: number) => (
                  <React.Fragment key={id}>
                    <tr
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (currentTab === TABS_LABELS.CLIENTS) {
                          clientsFilter({
                            client: item.name,
                          });
                        } else if (currentTab === TABS_LABELS.USERS) {
                          return;
                        } else {
                          clientsFilter({
                            recipientName: item.name,
                          });
                        }

                        history.push('/vip-workflow');
                      }}
                    >
                      {Object.entries(tableConfig as keyof typeof tableConfig).map(
                        ([key, config], id) => {
                          return (
                            <td key={id} className="pl-2 pr-2 pt-2 pb-2">
                              {renderFieldValue(key as keyof typeof tableConfig, item, config)}
                            </td>
                          );
                        },
                      )}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            ) : (
              <tr>
                <td colSpan={17} className="p-3 text-center">
                  <h2>Data Not Found</h2>
                </td>
              </tr>
            )}
          </Table>
        </div>
        <PaginationComponent
          page={page}
          setPage={setPage}
          totalPageCount={totalPageCount}
          totalItemsCount={totalClientsCount}
          setItemsPerPage={setItemsPerPage}
          itemsPerPage={itemsPerPage}
        />
      </Card>
    </>
  );
};

export default ClientsTable;
