import { useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import { successMsg } from '../../cache/vars';
import { FORGOT_PASSWORD } from '../../queries/auth';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string().email().required('Required'),
});

const ForgotPasswordPage: React.FC = () => {
  const client = useApolloClient();
  const history = useHistory();

  return (
    <Container className="mt-8 pb-5">
      <Row className="justify-content-center">
        <Col lg="5" md="7">
          <Card className="bg-secondary border-0 mb-0">
            <CardBody className="px-lg-5 py-lg-5">
              <Formik
                initialValues={{ email: '' }}
                onSubmit={async (userInfo) => {
                  const forgotPasswordResponse = await client.mutate({
                    mutation: FORGOT_PASSWORD,
                    variables: { email: userInfo.email },
                    fetchPolicy: 'no-cache',
                  });

                  if (forgotPasswordResponse.data?.forgotPassword === 'ok') {
                    history.push('/login');
                    successMsg('Link sent, please check your email');
                  }
                }}
                validationSchema={VALIDATION_SCHEMA}
              >
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                  } = props;

                  return (
                    <Form role="form" onSubmit={handleSubmit}>
                      <FormGroup>
                        <Input
                          placeholder="Email"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.email}
                          name="email"
                          type="text"
                          invalid={!!errors.email && touched.email}
                        />
                        {errors.email && touched.email ? (
                          <FormFeedback>Wrong email format</FormFeedback>
                        ) : (
                          <FormText>Enter email</FormText>
                        )}
                      </FormGroup>
                      <div className="text-center">
                        <Button className="my-4" color="info" type="submit">
                          Send Link
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6" className="text-left">
              <Link to="/login" className="text-primary">
                <small>Back</small>
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPasswordPage;
