import { IItemsPerPageSelect } from '../../utils/interfaces';

const ItemsPerPageSelect = ({ setItemsPerPage, setPage, itemsPerPage }: IItemsPerPageSelect) => {
  return (
    <div
      className="dataTables_length d-flex align-items-baseline col-sm-6 col-md-6 col-lg-6"
      id="datatable-basic_length"
    >
      <label>Show</label>
      <select
        name="datatable-basic_length"
        aria-controls="datatable-basic"
        className="form-control form-control-sm col-lg-3"
        onChange={(e) => {
          setItemsPerPage(Number.parseInt(e.target.value));
          setPage(1);
        }}
      >
        <option value={10} selected={itemsPerPage === 10}>
          10
        </option>
        <option value={25} selected={itemsPerPage === 25}>
          25
        </option>
        <option value={50} selected={itemsPerPage === 50}>
          50
        </option>
        <option value={100} selected={itemsPerPage === 100}>
          100
        </option>
      </select>
      <label>entries.</label>
    </div>
  );
};

export default ItemsPerPageSelect;
