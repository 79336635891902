import { Row, Col, Button, Card, CardHeader, UncontrolledTooltip } from 'reactstrap';
import { users } from '../../data/general';
import { ManageUserReportsInterface } from '../../utils/interfaces';
import DataTable from '../ManageUserReportsTable/ManageUserReportsTable';

export const ManageUserReports: React.FC<ManageUserReportsInterface> = ({
  currentTab,
  currentTabKey,
}) => {
  return (
    <Card>
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <Col xs="6">
            <h3 className="mb-0">{currentTab}</h3>
          </Col>
          <Col className="text-right" xs="6">
            <Button
              className="btn-round btn-icon"
              color="primary"
              href="#pablo"
              id="tooltip443412080"
              onClick={(e) => e.preventDefault()}
              size="sm"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-filter" />
              </span>
              <span className="btn-inner--text">Filter</span>
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip443412080">
              Filter
            </UncontrolledTooltip>
          </Col>
        </Row>
      </CardHeader>
      <DataTable currentTabKey={currentTabKey} tableData={users} />
    </Card>
  );
};
