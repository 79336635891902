import { useLazyQuery } from '@apollo/client';
import React from 'react';
import { Table } from 'reactstrap';
import { TRANSFER_DETAILS_INFO_TABLE } from '../../utils/tableConfigs';
import { GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD } from '../../queries/ticket';
import moment from 'moment';
import { Tooltip } from 'antd';

const NestedTransferLogsTable = ({ invoiceIdList, isDetailsOpened, openedTransfer }: any) => {
  
  const [getPackageDetailsHistoryByInvoiceId, { called, loading, data }] = useLazyQuery(
    GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD,
    {
      variables: { invoiceId: openedTransfer },
      fetchPolicy: 'no-cache',
    },
  );
  
  const truncateErrorMessage = (message?: string) => {
    if (!message) return '';
  
    const words = message.split('');
  
    if (words.length > 100) {
      return words.slice(0, 100).join('') + '...';
    }
  
    return message;
  };
  
  React.useEffect(() => {
    if (isDetailsOpened && invoiceIdList.length) {
      getPackageDetailsHistoryByInvoiceId();
    }
  }, [isDetailsOpened]);
  
  return (
    <Table className="Table align-items-center" responsive bordered>
      <thead className="thead-light text-center">
        <tr>
          {Object.keys(TRANSFER_DETAILS_INFO_TABLE).map((key, id) => (
            <th key={key}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.getPackageDetailsHistoryByInvoiceId && 
        data?.getPackageDetailsHistoryByInvoiceId.map((item: any) => {
          return (
            <tr key={item.packageHistoryId}>
              <td height="60px" key={`${item.packageHistoryId}_status`}>
              <Tooltip title={item[TRANSFER_DETAILS_INFO_TABLE.Logs.key]} trigger="hover" overlayStyle={{maxWidth: '500px', maxHeight: '100px',  overflow: 'auto'}}>
                {truncateErrorMessage(item[TRANSFER_DETAILS_INFO_TABLE.Logs.key])}
              </Tooltip>
            </td>
              <td height="60px" key={`${item.packageHistoryId}_date`}>
                {moment(item[TRANSFER_DETAILS_INFO_TABLE.Date.key]).format('MMMM Do YYYY hh:mm a')}
              </td>
              <td height="60px" key={`${item.packageHistoryId}_trigger`}>
                {item[TRANSFER_DETAILS_INFO_TABLE.Trigger.key]}
              </td>
            </tr>
          )})
        }
      </tbody>
    </Table>
  );
};

export default NestedTransferLogsTable;
