import { useApolloClient } from '@apollo/client';
import { Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { successMsg } from '../../../cache/vars';
import { DELETE_FIELD_FROM_CARD } from '../../../queries/vip-workflow';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React from 'react';
import FormSection from './FormSection';
import moment from 'moment';

const camelCase = (str: string) => {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

const VALIDATION_SCHEMA = Yup.object().shape({
  cardObjectKey: Yup.string().max(40).min(1).matches(/^\S/).required('Required'),
});

const FullyEditableFormSection = ({
  modalState,
  setModalState,
  setPackageOrderWithDetails,
}: any) => {
  const client = useApolloClient();

  const [isElemetOnDrag, setIsElementOnDrag] = React.useState(false);
  const [ticketsSent, setTicketsSent] = React.useState(false);
  const [ticketsSentDate, setTicketsSentDate] = React.useState('');

  const handleOnDragEnd = async (result: any) => {
    setIsElementOnDrag(false);

    if (!result.destination) return;

    const items = Array.from(modalState.cartData);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setModalState((prev: any) => {
      return {
        ...prev,
        cartData: items,
      };
    });
  };

  const handleTicketsSentChange = (e: { target: { checked: any } }) => {
    const checked = e.target.checked;
    setTicketsSent(checked);
    let newDate = ticketsSentDate;
    if ((checked && !newDate) || (checked && newDate === '-')) {
      newDate = moment(new Date()).format('YYYY-MM-DD');
      setTicketsSentDate(newDate);
    } else if (!checked) {
      newDate = '';
      setTicketsSentDate('');
    }

    updateModalStateForTicketsSent(checked, newDate);
  };

  const handleTicketsSentDateChange = (e: { target: { value: any } }) => {
    const newDate = e.target.value;
    setTicketsSentDate(newDate);
    updateModalStateForTicketsSent(ticketsSent, newDate);
  };

  const updateModalStateForTicketsSent = (ticketsSent: boolean, ticketsSentDate: string) => {
    setModalState((prev: any) => {
      return {
        ...prev,
        cartData: prev.cartData.map((fieldData: any) => {
          if ('ticketsSent' in fieldData) {
            return { ...fieldData, ticketsSent: ticketsSent };
          }
          if ('ticketsSentDate' in fieldData) {
            return { ...fieldData, ticketsSentDate: ticketsSentDate };
          }
          return fieldData;
        }),
      };
    });
  };

  React.useEffect(() => {
    if (modalState.cartData && Array.isArray(modalState.cartData)) {
      const ticketsSentField = modalState.cartData.find((field: any) => 'ticketsSent' in field);
      const ticketsSentDateField = modalState.cartData.find(
        (field: any) => 'ticketsSentDate' in field,
      );
      if (ticketsSentField && ticketsSentDateField) {
        setTicketsSent(ticketsSentField.ticketsSent);
        setTicketsSentDate(ticketsSentDateField.ticketsSentDate);
      }
    }
  }, [modalState.cartData]);

  return (
    <div className="mb-4">
      <Formik
        initialValues={{ cardObjectKey: '' }}
        onSubmit={(keyData, { resetForm }) => {
          setModalState((prev: any) => {
            return {
              ...prev,
              cartData: [...prev.cartData, { [`${keyData.cardObjectKey}`]: '' }],
            };
          });

          resetForm();
        }}
        validationSchema={VALIDATION_SCHEMA}
      >
        {(props) => {
          const { values, errors, handleChange, handleSubmit } = props;
          return (
            <Form>
              <Row className="pb-4 border-bottom">
                <Col sm="8" className="d-flex pl-4 pr-4">
                  <Input
                    type="text"
                    className="mr-3"
                    placeholder="Field Name (max 40)"
                    onChange={handleChange}
                    name="cardObjectKey"
                    value={values.cardObjectKey}
                  />
                  <button
                    disabled={!!errors.cardObjectKey || !values.cardObjectKey}
                    className="btn btn-primary ml-3"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      handleSubmit();
                    }}
                  >
                    Add
                  </button>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
      <DragDropContext
        onDragEnd={handleOnDragEnd}
        onBeforeDragStart={() => {
          setIsElementOnDrag(true);
        }}
      >
        <Droppable droppableId="input">
          {(providedDroppable) => (
            <div {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>
              <Row className="pt-4">
                {modalState?.cartData?.map((field: any, id: number) => {
                  return Object.entries(field).map(([fieldKey, fieldValue]: any) => {
                    return (
                      <Col className="pl-4" xs="8" key={id}>
                        <Draggable index={id} draggableId={id.toString()} key={id}>
                          {(providedDraggable) => (
                            <div
                              {...providedDraggable.dragHandleProps}
                              {...providedDraggable.draggableProps}
                              ref={providedDraggable.innerRef}
                            >
                              {fieldKey === 'ticketsSent' && (
                                <FormGroup key={id} className="mr-3">
                                  <div className="d-flex justify-content-between">
                                    <label className="form-control-label text-capitalize">
                                      {fieldKey}
                                    </label>

                                    <Input
                                      className="form-control-alternative new-event--title ml-9"
                                      type="checkbox"
                                      name={fieldKey}
                                      onChange={handleTicketsSentChange}
                                      checked={fieldValue || false}
                                    />
                                  </div>
                                </FormGroup>
                              )}
                              {ticketsSent && fieldKey === 'ticketsSentDate' && (
                                <FormGroup key={id} className="mr-3">
                                  <div className="d-flex justify-content-between">
                                    <label className="form-control-label text-capitalize">
                                      {fieldKey}
                                    </label>
                                  </div>
                                  <Input
                                    className="form-control-alternative new-event--title w-25"
                                    type="date"
                                    name={fieldKey}
                                    onChange={handleTicketsSentDateChange}
                                    value={ticketsSentDate || ''}
                                  />
                                </FormGroup>
                              )}
                              {fieldKey !== 'ticketsSent' && fieldKey !== 'ticketsSentDate' && (
                                <FormGroup key={id} className="mr-3">
                                  <div className="d-flex justify-content-between">
                                    <label className="form-control-label text-capitalize">
                                      {fieldKey}
                                    </label>
                                    <button
                                      type="button"
                                      className="text-primary revert-btn"
                                      onClick={async (e) => {
                                        e.preventDefault();
                                        if (!modalState.cartId) {
                                          setModalState((prev: any) => {
                                            prev.cartData.splice(id, 1);

                                            return {
                                              ...prev,
                                              cartData: prev.cartData,
                                            };
                                          });
                                          successMsg('Field Deleted');
                                        } else {
                                          const { data } = await client.mutate({
                                            mutation: DELETE_FIELD_FROM_CARD,
                                            variables: {
                                              cartId: modalState.cartId,
                                              fieldName: fieldKey,
                                              fieldId: id,
                                            },
                                          });
                                          if (data?.deleteFieldFromCard === 'ok') {
                                            setModalState((prev: any) => {
                                              prev.cartData.splice(id, 1);

                                              return {
                                                ...prev,
                                                cartData: prev.cartData,
                                              };
                                            });
                                            setPackageOrderWithDetails((prev: any) => {
                                              return {
                                                ...prev,
                                                orderDetails: prev.orderDetails.map((item: any) =>
                                                  item.packageDetailsId ===
                                                  modalState.packageDetailsId
                                                    ? {
                                                        ...item,
                                                        cartDetails: {
                                                          ...item.cartDetails,
                                                          cartJsonData: JSON.stringify(
                                                            modalState.cartData,
                                                          ),
                                                        },
                                                      }
                                                    : item,
                                                ),
                                              };
                                            });
                                            successMsg('Field Deleted');
                                          }
                                        }
                                      }}
                                    >
                                      Delete field
                                    </button>
                                  </div>
                                  <Input
                                    className="form-control-alternative new-event--title"
                                    type="text"
                                    name={fieldKey}
                                    placeholder={fieldKey.toLowerCase() === 'link' ? 'wrapper(https://example.com)' : 'Enter Value'}
                                    onChange={(e) => {
                                      setModalState((prev: any) => {
                                        return {
                                          ...prev,
                                          cartData: [
                                            ...prev.cartData.map((fieldData: any, index: number) =>
                                              index === id
                                                ? { [fieldKey]: e.target.value }
                                                : fieldData,
                                            ),
                                          ],
                                        };
                                      });
                                    }}
                                    value={fieldValue}
                                  />
                                </FormGroup>
                              )}
                            </div>
                          )}
                        </Draggable>
                      </Col>
                    );
                  });
                })}
                {isElemetOnDrag ? (
                  <Col className="pl-4" xs="8" style={{ height: '102px' }}></Col>
                ) : null}
              </Row>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default FullyEditableFormSection;
