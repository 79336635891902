import { gql } from '@apollo/client';

export const AUTH = gql`
  mutation logIn($authInput: AuthInput!) {
    logIn(authInput: $authInput) {
      user {
        userId
        email
        role
        createdAt
      }
    }
  }
`;

export const GET_PROFILE = gql`
  query getProfile {
    getProfile {
      email
      username
      role
      createdAt
      userId
    }
  }
`;

export const ADMIN_SIGN_UP = gql`
  mutation adminRegister($registerInput: RegisterInput!) {
    adminRegister(registerInput: $registerInput) {
      user {
        userId
        email
        role
        createdAt
      }
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $token: String!) {
    resetPassword(password: $password, token: $token)
  }
`;
