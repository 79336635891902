import { useApolloClient } from '@apollo/client';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroup,
  Input,
} from 'reactstrap';
import { AUTOCOMPLETE_BROKERS } from '../../queries/brokers';
import './autocomplete.scss';
import { useDebouncedCallback } from 'use-debounce';
import { AUTOCOMPLETE_RECIPIENTS } from '../../queries/recipients';
import { SAVE_USERS_ASSOC } from '../../queries/users';
import { successMsg } from '../../cache/vars';

const AutocompleteInput = ({
  updatedUser,
  userData,
  setUpdatedUser,
  isSearchInRecipientTable,
}: any) => {
  const client = useApolloClient();

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState<any>([]);
  const [inputValue, setInputValue] = React.useState(updatedUser?.clientBrokerName || '');

  React.useEffect(() => {
    if (updatedUser?.role !== 'BROKER') {
      setInputValue('');
    }

    if (updatedUser?.role !== 'RECIPIENT') {
      setInputValue(userData?.clientBrokerName || '');
    }
  }, [updatedUser.role]);

  const debounce = useDebouncedCallback(async (brokerNameOrRecipientEmail) => {
    if (updatedUser.role === 'BROKER') {
      const brokers = await client.query({
        query: AUTOCOMPLETE_BROKERS,
        variables: {
          search: brokerNameOrRecipientEmail,
        },
        fetchPolicy: 'no-cache',
      });

      setSuggestions(brokers.data?.autocompleteBrokers || []);
    }

    if (updatedUser.role === 'RECIPIENT') {
      const recipients = await client.query({
        query: AUTOCOMPLETE_RECIPIENTS,
        variables: {
          recipientEmail: brokerNameOrRecipientEmail,
          userEmail: updatedUser.email,
        },
        fetchPolicy: 'no-cache',
      });

      setSuggestions(recipients.data?.recipientsAutocomplete || []);
    }
  }, 500);

  const getSuggestionValue = (suggestion: any) =>
    updatedUser.role === 'BROKER' ? suggestion.name : suggestion.email;

  const onSuggestionsFetchRequested = async ({ value }: any) => {
    await debounce(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const onChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const inputProps = {
    placeholder: updatedUser.role === 'BROKER' ? 'Type broker name' : 'Email from PSUSA',
    value: inputValue || '',
    onChange: onChange,
  };

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggle={() => {
        setIsDropdownOpen((prev) => {
          return !prev;
        });
      }}
      style={{ width: '100%' }}
    >
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={(suggestion: any) =>
          suggestion ? (
            <DropdownItem key={suggestion.clientBrokerId} defaultValue={suggestion.clientBrokerId}>
              {updatedUser.role === 'BROKER' ? suggestion.name : suggestion.email}
            </DropdownItem>
          ) : (
            <DropdownItem>Not Found</DropdownItem>
          )
        }
        renderSuggestionsContainer={({ containerProps, children, query }: any) => (
          <div {...containerProps}>
            <DropdownMenu style={{ width: '100%' }}>{children}</DropdownMenu>
          </div>
        )}
        onSuggestionSelected={async (e, { suggestion }) => {
          if (updatedUser.role === 'BROKER') {
            setUpdatedUser((prev: any) => {
              return { ...prev, brokerId: suggestion.clientBrokerId };
            });
            setInputValue(suggestion.name || '');
          }

          if (updatedUser.role === 'RECIPIENT') {
            const newAssoc = await client.mutate({
              mutation: SAVE_USERS_ASSOC,
              variables: {
                recipientEmail: suggestion.email,
                userId: updatedUser.userId,
              },
            });

            setUpdatedUser((prev: any) => {
              return {
                ...prev,
                associatedEmails: [...prev.associatedEmails, newAssoc.data?.newUsersAssoc],
              };
            });

            successMsg('Saved');
          }
        }}
        renderInputComponent={(inputProps: any) => (
          <DropdownToggle tag="div">
            <InputGroup>
              <Input {...inputProps} />
            </InputGroup>
          </DropdownToggle>
        )}
        inputProps={inputProps}
      />
    </Dropdown>
  );
};

export default AutocompleteInput;
