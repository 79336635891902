import {
  Button,
  Card,
  Col,
  Collapse,
  Container,
  PopoverBody,
  Row,
  Spinner,
  Table,
  UncontrolledPopover,
} from 'reactstrap';
import { Tooltip } from 'antd';
import './getTickets.scss';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD, GET_TICKETS_LIST_FOR_DASHBOARD, SAVE_ACCEPTANCE_URL } from '../../queries/ticket';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { clientsFilter, errorMsg, infoMsg, successMsg } from '../../cache/vars';
import { Input, Select } from 'antd';
import { transformPhoneNumber } from '../../utils/services';
import PaginationComponent from '../../components/Pagination/Pagination';
import CronInfoTable from './CronInfoTable';
import moment from 'moment';
import TicketCardDetails from './TicketCardDetails';
import { GET_PROFILE } from '../../queries/auth';

const TICKETS_ICONS_BY_STATYSES = {
  TRANSFERED_EXTERNALLY: (
    <i className="fa fa-sharp fa-lg fa-regular fa-ban" style={{ color: '#0F9E48' }} />
  ),
  NOT_TRANSFERABLE: (
    <i className="fa fa-light fa-lg fa-triangle-exclamation" style={{ color: '#CE4275' }} />
  ),
  TRANSFERABLE: <i className="fa fa-light fa-lg fa-circle-check" style={{ color: '#E19B12' }} />,
  UPLOADED: <i className="fa fa-lg fa-light fa-calendar" style={{ color: 'grey' }} />,
  EMAILED: <i className="fa fa-light fa-lg fa-circle-check" style={{ color: 'green' }} />,
  FAILED: <i className="fa fa-light fa-lg fa-circle-xmark" style={{ color: '#CE4275' }} />,
  FAILED_MAPPING: <i className="fa fa-light fa-lg fa-circle-xmark" style={{ color: '#CE4275' }} />,
};

interface ITicket {
  deliveryStatus: string;
  updatedAt: Date;
  invoiceId: number;
  packagePo: number;
  transferId: number;
  eventDate: string;
  marketplace: string;
  acceptanceURL: string;
  errorMessage: string;
  errorLogs: string;
  client: string;
  eventName: string;
  venue: string;
  recipient: Recipient;
}

interface Recipient {
  recipientName: string;
  recipientEmail: string;
  recipientPhone: string;
}

const TABLE_HEADER_CONFIG = {
  Status: {
    key: 'deliveryStatus',
    width: '12%',
    isSortable: true,
  },
  'Last Update': {
    key: 'updatedAt',
    width: '3%',
    isSortable: true,
  },
  'Invoice ID': {
    key: 'invoiceId',
    width: '3%',
    isSortable: true,
  },
  Client: {
    key: 'client',
    width: '3%',
    isSortable: true,
  },
  Recipient: {
    key: 'recipient',
    width: '3%',
    isSortable: true,
  },
  'Package PO': {
    width: '3%',
    key: 'packagePo',
    isSortable: true,
  },
  'Transfer ID': {
    key: 'transferId',
    width: '3%',
    isSortable: true,
  },
  'Event Date': {
    key: 'eventDate',
    width: '3%',
    isSortable: true,
  },
  'Event Name': {
    key: 'recipient',
    width: '10%',
    isSortable: true,
  },
  Venue: {
    key: 'venue',
    width: '3%',
    isSortable: true,
  },
  Marketplace: {
    key: 'marketplace',
    width: '3%',
    isSortable: true,
  },
  'Acceptance URL': {
    key: 'acceptanceURL',
    width: '18%',
  },
  Actions: {
    key: '',
    width: '10%',
  },
};

const STATUS_FILTER_OPTIONS = [
  { label: 'Emailed', value: 'EMAILED' },
  { label: 'Pending', value: 'PENDING' },
  { label: 'Transferable', value: 'TRANSFERABLE' },
  { label: 'Not transferable', value: 'NOT_TRANSFERABLE' },
  { label: 'Failed', value: 'FAILED' },
];

const urlRegexp = new RegExp(
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]+\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi,
);

const TicketsDashboard = () => {
  const history = useHistory();

  const [isEditAcceptanceUrl, setIsEditAcceptanceUrl] = React.useState<null | number>(null);
  const [acceptanceUrlInputState, setAcceptanceUrlInputState] = React.useState('');
  const [currentAcceptanceUrl, setCurrentAcceptanceUrl] = React.useState('');

  const [page, setPage] = React.useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = React.useState(25);

  const [sortBy, setSortBy] = React.useState('updatedAt');
  const [sortDirection, setSortDirection] = React.useState('DESC');
  const [isDetailsOpened, setIsDetailsOpened] = React.useState<number[]>([]);
  const [statusesToFilter, setStatusesToFilter] = React.useState([]);
  const [invoiceIdInputState, setInvoiceIdInputState] = React.useState('');
  const [invoiceIdSearch, setInvoiceIdSearch] = React.useState('');
  const [isPopoverOpen, setIsPopoverOpen] = React.useState('');
  const [transfersList, setTransfersList] = React.useState([]);
  const [isScreenWidthLessThanTablet, setIsScreenWidthLessThanTablet] = React.useState(window.innerWidth < 1370);

  const editAcceptanceUrlRef = React.useRef(null);
  
  const truncateErrorMessage = (message?: string) => {
    if (!message) return '';
  
    const words = message.split('');
  
    if (words.length > 100) {
      return words.slice(0, 100).join('') + '...';
    }
  
    return message;
  };
  
  const { loading, error, data, refetch } = useQuery(GET_TICKETS_LIST_FOR_DASHBOARD, {
    variables: {
      sortBy,
      sortDirection,
      statusesToFilter,
      invoiceIdSearch: Number.parseFloat(invoiceIdSearch),
      page,
      itemsPerPage,
    },
  });

  const [saveAcceptanceUrl, saveAcceptanceUrlResponse] = useMutation(SAVE_ACCEPTANCE_URL, {
    refetchQueries: [GET_TICKETS_LIST_FOR_DASHBOARD, 'getTicketsListForTicketsDashboard', GET_PACKAGE_DETAILS_HISTORY_FOR_TICKETS_DASHBOARD],
    onCompleted() {
      if (acceptanceUrlInputState ===  currentAcceptanceUrl) {
        infoMsg('Link should be changed first in order to update');
      } else {
        successMsg('Acceptance url saved.');
      }

      setIsEditAcceptanceUrl(null);
      setAcceptanceUrlInputState('');
      setCurrentAcceptanceUrl('');
    },
  });
  
  const handleReset = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSortBy('');
    setSortDirection('');
  };

  React.useEffect(() => {
    if (!invoiceIdInputState) {
      setInvoiceIdSearch('');
    }
  }, [invoiceIdInputState]);

  React.useEffect(() => {
    function handleClickOutside(e: any) {
      if (
        editAcceptanceUrlRef.current &&
        //@ts-expect-error
        !editAcceptanceUrlRef.current.contains(e.target) &&
        e.target.nodeName !== 'BUTTON'
      ) {
        setAcceptanceUrlInputState('');
        setCurrentAcceptanceUrl(''); 
        setIsEditAcceptanceUrl(null);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editAcceptanceUrlRef]);

  const handleTogglePopover = (popoverId: string) => {
    if (isPopoverOpen === popoverId) {
      setIsPopoverOpen('');
    } else {
      setIsPopoverOpen(popoverId);
    }
  };
  
  React.useEffect(() => {
    refetch();
  }, [sortBy, sortDirection, statusesToFilter, invoiceIdSearch, page, itemsPerPage]);
  
  React.useEffect(() => {
    if (data?.getTicketsListForTicketsDashboard?.transfersList) {
      setTransfersList(data.getTicketsListForTicketsDashboard.transfersList);
    }
  }, [data]);
  
  const [getUserProfile, getUserProfileResponse] = useLazyQuery(
    GET_PROFILE,
    {
      fetchPolicy: 'network-only',
    },
  );
  
  React.useEffect(() => {
    getUserProfile();
  }, []);
  
  const user = getUserProfileResponse?.data?.getProfile?.username ? getUserProfileResponse?.data?.getProfile?.username : getUserProfileResponse?.data?.getProfile?.email;

  return (
    <Container className="" fluid>
      <Row className="mt-4 d-flex">
        <Col style={{ flexGrow: '1.5' }} className="p-0">
          <CronInfoTable />
        </Col>
        <Col className="d-flex align-items-end pr-0" style={{ flexGrow: '3' }}>
          <div className="d-flex w-100 justify-content-end">
            <Select
              className="mr-3 w-25"
              value={statusesToFilter}
              mode="multiple"
              options={STATUS_FILTER_OPTIONS}
              onChange={(status) => {
                if (!status.length) {
                  setStatusesToFilter([]);
                }

                setPage(1);

                setStatusesToFilter(status);
              }}
              placeholder="Filter by status"
              maxTagCount="responsive"
              allowClear
              size="large"
            />
            <Input
              className="w-25 mr-3"
              placeholder="Invoice Id Search"
              onChange={(e) => {
                setInvoiceIdInputState(e.target.value);
                setPage(1);
              }}
              value={invoiceIdInputState}
              size="large"
              allowClear={true}
              onPressEnter={() => {
                setInvoiceIdSearch(invoiceIdInputState);
                setPage(1);
              }}
            />
            <button
              style={{
                border: '1px solid #ce4275',
                borderRadius: '10px',
                background: '#ce4275',
                color: '#FFFFFF',
                padding: '0 0.5rem 0 0.5rem',
              }}
              onClick={() => {
                setStatusesToFilter([]);
                setInvoiceIdInputState('');
                setInvoiceIdSearch(invoiceIdInputState);
              }}
            >
              Clear All
            </button>
          </div>
        </Col>
      </Row>
      {!data?.getTicketsListForTicketsDashboard.transfersList.length && !loading ? (
        <div className="mt-3">
          <h2>No Tickets Found</h2>
        </div>
      ) : (
        <Row className="mt-3" style={{ overflowY: 'auto' }}>
          <Col className="p-0">
            <Card className="mt-4">
              <Table className="Table" responsive>
                <thead className="thead-light text-center">
                  <tr>
                    {Object.entries(TABLE_HEADER_CONFIG)?.map(([key, config]: any, id) => (
                      <th
                        key={id}
                        style={{ width: config.width }}
                        onClick={() => {
                          if (sortBy !== config.key) {
                            setSortBy(config.key);
                            setSortDirection('DESC');
                          } else {
                            setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
                          }
                        }}
                      >
                        {key}
                        {config.isSortable ? (
                          sortBy && sortBy === config.key ? (
                            sortDirection === 'ASC' ? (
                              <>
                                <span>&uarr;</span>
                                <button type='button' onClick={handleReset} 
                                style={
                                  {
                                    border: 'none',
                                    background: 'none',
                                    outline: 'none',
                                    cursor: 'pointer',
                                    padding: '0'
                                  }
                                }>
                                  &nbsp;↺&nbsp;
                                </button>
                              </>
                            ) : (
                              <>
                                <span>&darr;</span>
                                <button type='button' onClick={handleReset} 
                                style={
                                  {
                                    border: 'none',
                                    background: 'none',
                                    outline: 'none',
                                    cursor: 'pointer',
                                    padding: '0'
                                  }
                                }>
                                  &nbsp;↺&nbsp;
                                </button>
                              </>
                            )
                          ) : (
                            <>
                              &nbsp;
                              <span>&uarr;</span>
                              <span>&darr;</span>
                            </>
                          )
                        ) : null}
                      </th>
                    ))}
                  </tr>
                </thead>
                {loading ? (
                  <tbody>
                    <tr>
                      <td colSpan={17} className="text-center">
                        <Spinner animation="border" variant="info" />
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                    {transfersList?.map(
                      (ticket: ITicket, id: number) => { 
                        const dataUTC = moment(ticket.updatedAt, "MMMM Do YYYY hh:mm a").utc(true).toDate();
                        const localeDate = moment(dataUTC).format("MMMM Do YYYY hh:mm a");
                        const deliveryStatus =
                          ticket.deliveryStatus === 'FAILED_MAPPING' ? 'FAILED' : ticket.deliveryStatus;
                        return (
                          <React.Fragment key={ticket.invoiceId}>
                            <tr
                              onClick={() => {
                                if (isDetailsOpened.includes(ticket.invoiceId)) {
                                  setIsDetailsOpened((state: any) => {
                                    return state.filter((el: any) => el !== ticket.invoiceId);
                                  });
                                } else {
                                  setIsDetailsOpened((state: any) => {
                                    return [...state, ticket.invoiceId];
                                  });
                                }
                              }}
                            >
                              <td>
                                {
                                  TICKETS_ICONS_BY_STATYSES[
                                    deliveryStatus as keyof typeof TICKETS_ICONS_BY_STATYSES
                                  ]
                                }
                                &nbsp; &nbsp;
                                {deliveryStatus}
                              </td>
                              <td>
                                {localeDate}
                              </td>
                              <td>
                                <Link
                                  to={`/package-info/${ticket.invoiceId}`}
                                  target="_blank"
                                  style={{
                                    border: 'none',
                                    background: 'none',
                                    color: '#337ab7',
                                    padding: '0',
                                    textDecoration: 'underline',
                                  }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {ticket.invoiceId}
                                </Link>
                              </td>
                              <td>
                                <button
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    clientsFilter({
                                      client: ticket.client,
                                    });
                                    history.push('/vip-workflow');
                                  }}
                                  className="btn-cell"
                                >
                                  {ticket.client}
                                </button>
                              </td>
                              <td>
                                <>
                                  <button
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                    }}
                                    className="btn-cell"
                                    id={`id${ticket.packagePo}`}
                                  >
                                    {ticket.recipient.recipientName}
                                  </button>
                                  <UncontrolledPopover
                                    target={`id${ticket.packagePo}`}
                                    toggle={() => handleTogglePopover(`id${ticket.packagePo}`)}
                                    placement="right"
                                    isOpen={isPopoverOpen === `id${ticket.packagePo}`}
                                  >
                                    <PopoverBody onClick={(e) => e.stopPropagation()}>
                                      <div>
                                        <div className="d-flex justify-content-between">
                                          <span>
                                            <strong>Recipient Name:</strong>{' '}
                                            {ticket.recipient.recipientName
                                              ? ticket.recipient.recipientName
                                              : ''}
                                          </span>
                                          <i
                                            className="fas fa-window-close"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleTogglePopover(`id${ticket.packagePo}`);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                          />
                                        </div>
                                        <span>
                                          <strong>Recipient Email:</strong>{' '}
                                          {ticket.recipient.recipientEmail
                                            ? ticket.recipient.recipientEmail
                                            : ''}
                                        </span>
                                        <br />
                                        <span>
                                          <strong>Recipient Phone: </strong>
                                          {ticket.recipient.recipientPhone
                                            ? transformPhoneNumber(ticket.recipient.recipientPhone)
                                            : ''
                                          }
                                        </span>
                                      </div>
                                      <div className="mt-2">
                                        <Button
                                          onClick={() => {
                                            setIsPopoverOpen('');
                                            clientsFilter({
                                              recipientName: ticket.recipient.recipientName,
                                            });
                                            history.push('/vip-workflow');
                                          }}
                                        >
                                          Show All Packages
                                        </Button>
                                      </div>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </>
                              </td>
                              <td>{ticket.packagePo}</td>
                              <td>{ticket.transferId}</td>
                              <td>{ticket.eventDate}</td>
                              <td>{ticket.eventName}</td>
                              <td>{ticket.venue}</td>
                              <td>{ticket.marketplace ? ticket.marketplace : '-'}</td>
                              <td>
                                {isEditAcceptanceUrl === ticket.invoiceId ? (
                                  <input
                                    className="pl-2"
                                    type="text"
                                    value={acceptanceUrlInputState}
                                    onChange={(e) => {
                                      setAcceptanceUrlInputState(e.target.value);
                                    }}
                                    autoFocus
                                    ref={editAcceptanceUrlRef}
                                    onKeyDown={(e) => {
                                      if (e.key === 'Enter') {
                                        if (acceptanceUrlInputState.match(urlRegexp)) {
                                          saveAcceptanceUrl({
                                            variables: {
                                              invoiceId: ticket.invoiceId,
                                              acceptanceUrl: acceptanceUrlInputState,
                                              user: user,
                                            },
                                          });
                                        } else {
                                          errorMsg('Acceptance url should be a link.');
                                        }
                                      }
                                    }}
                                  />
                                ) : deliveryStatus === 'FAILED' ? (
                                  <Tooltip title={ticket.errorMessage} trigger="hover" overlayStyle={{maxWidth: '500px', maxHeight: '100px',  overflow: 'auto'}}>
                                    <span>{truncateErrorMessage(ticket.errorMessage)}</span>
                                  </Tooltip>
                                ) : deliveryStatus === 'NOT_TRANSFERABLE' ? (
                                  ticket.errorLogs
                                ) : ticket.acceptanceURL ? (
                                  ticket.acceptanceURL
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td style={{ maxWidth: "100%", paddingLeft: '10px', whiteSpace: 'nowrap' }}>
                                <div className="w-100 d-flex justify-content-center">
                                  {isEditAcceptanceUrl === ticket.invoiceId ? (
                                    <>
                                      <button
                                        className="btn btn-sm m-0 ml-4 text-primary border-primary"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (acceptanceUrlInputState.match(urlRegexp)) {
                                            saveAcceptanceUrl({
                                              variables: {
                                                invoiceId: ticket.invoiceId,
                                                acceptanceUrl: acceptanceUrlInputState,
                                                user: user,
                                              },
                                            });
                                          } else {
                                            errorMsg("Acceptance url should be a link.");
                                          }
                                        }}
                                      >
                                        Save
                                      </button>
                                      <button
                                        className="btn btn-sm ml-2 m-0 text-primary border-primary"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setIsEditAcceptanceUrl(null);
                                          setAcceptanceUrlInputState("");
                                          setCurrentAcceptanceUrl('');
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        style={{ border: "none", background: "none" }}
                                        className="text-primary p-0 mr-2"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setIsEditAcceptanceUrl(ticket.invoiceId);
                                          setAcceptanceUrlInputState(ticket.acceptanceURL);
                                          setCurrentAcceptanceUrl(ticket.acceptanceURL); 
                                        }}
                                      >
                                      <i className="fa fa-lg fa-light fa-pen"></i>
                                      </button>
                                      {ticket.acceptanceURL && 
                                        <button
                                          className="btn btn-sm"
                                          style={{
                                            border: "1px solid #ce4275",
                                            borderRadius: "10px",
                                            background: "none",
                                            color: "#ce4275",
                                          }}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            navigator.clipboard.writeText(ticket.acceptanceURL);
                                            successMsg("Copied to clickboard");
                                          }}
                                          >
                                          Copy URL
                                        </button> 
                                      }
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={17} className="p-0">
                                <Collapse isOpen={isDetailsOpened.includes(ticket.invoiceId)}>
                                  <TicketCardDetails isDetailsOpened={isDetailsOpened} invoiceId={ticket.invoiceId} editable={false}/>
                                </Collapse>
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      },
                    )}
                  </tbody>
                )}
              </Table>
              {/* {!loading && data?.getTicketsListForTicketsDashboard.length ? ( */}
              <PaginationComponent
                page={page}
                setPage={setPage}
                totalPageCount={data?.getTicketsListForTicketsDashboard.totalPageCount || 0}
                totalItemsCount={data?.getTicketsListForTicketsDashboard.totalItemsCount || 0}
                setItemsPerPage={setItemsPerPage}
                itemsPerPage={itemsPerPage}
              />
              {/* ) : null} */}
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default TicketsDashboard;
