import { useApolloClient, useQuery } from '@apollo/client';
import moment from 'moment';
import React from 'react';
import { Row, Spinner, Table } from 'reactstrap';
import { successMsg } from '../../cache/vars';
import { DELETE_USER, GET_ADMIN_USERS } from '../../queries/users';
import { IUser } from '../../utils/interfaces';
import { TABLE_CONFIG } from '../../utils/tableConfigs';
import PaginationComponent from '../Pagination/Pagination';
import '../WorkflowTable/Table.scss';

const ManageUsersTable = ({
  setIsEditAdminModalOpened,
  setUserToEdit,
  userRoleValue,
  adminsList,
  setAdminsList,
}: any) => {
  const client = useApolloClient();

  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState<number>(1);
  const [sortBy, setSortBy] = React.useState('');
  const [sortDirection, setSortDirection] = React.useState('');
  const [itemsPerPage, setItemsPerPage] = React.useState<number>(25);
  const [totalPageCount, setTotalPageCount] = React.useState(1);
  const [totalUsersCount, setTotalUsersCount] = React.useState(null);

  React.useEffect(() => {
    getAdminsList();
  }, [sortBy, sortDirection, page, itemsPerPage]);

  const getAdminsList = async () => {
    setLoading(true);

    const { data } = await client.query({
      query: GET_ADMIN_USERS,
      variables: {
        page,
        sortBy,
        sortDirection,
        itemsPerPage,
      },
      fetchPolicy: 'no-cache',
    });

    if (data) {
      setLoading(false);
      setAdminsList(data?.adminUsersList.users);
      setTotalPageCount(data?.adminUsersList.totalPageCount);
      setTotalUsersCount(data?.adminUsersList.totalUsersCount);
    }
  };

  const renderFieldValue = (key: string, item: any) => {
    if (key === 'actions') {
      return (
        <>
          <button
            className="btn btn-sm btn-primary"
            onClick={(e) => {
              e.stopPropagation();
              setIsEditAdminModalOpened(true);
              setUserToEdit(item);
            }}
          >
            Edit
          </button>
          <button
            className="btn btn-sm btn-danger"
            onClick={async (e) => {
              e.stopPropagation();
              if (window.confirm('Are you sure you want to delete this user?')) {
                const { data } = await client.query({
                  query: DELETE_USER,
                  variables: {
                    userId: item.userId,
                  },
                });
                setAdminsList((prev: any) => {
                  return prev.filter((item: any) => item.userId !== data?.deleteUser);
                });
                successMsg('User Deleted');
              }
            }}
          >
            Delete
          </button>
        </>
      );
    }

    if (key === 'createdAt') {
      return moment(item[key]).format('YYYY-MM-DD');
    }

    return item[key];
  };
  
  const handleReset = (e: React.MouseEvent) => {
    e.stopPropagation();
    setSortBy('');
    setSortDirection('');
  };

  if (loading) {
    return (
      <Row className="justify-content-center mb-xl-4">
        <Spinner animation="border" variant="info" />
      </Row>
    );
  }

  return (
    <>
      <Table className="Table align-items-center table-flush" responsive striped>
        <thead className="thead-light thead-light text-center">
          <tr>
            {Object.keys(TABLE_CONFIG.MANAGE_USERS).map((key, id) => {
              if (key === 'actions' && userRoleValue === 'ADMIN') {
                return;
              }

              return (
                <th
                  key={id}
                  onClick={() => {
                    if (key !== 'actions') {
                      setSortBy(key);
                      setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
                    }
                  }}
                >
                  {(TABLE_CONFIG as any).MANAGE_USERS[key].colTitle}
                  {sortBy && sortBy === key ? (
                      sortDirection === 'ASC' ? (
                        <>
                          <span>&uarr;</span>
                          <button type='button' onClick={handleReset} 
                            style={
                              {
                                border: 'none',
                                background: 'none',
                                outline: 'none',
                                cursor: 'pointer',
                                padding: '0'
                              }
                            }>
                            &nbsp;↺&nbsp;
                          </button>
                        </>
                      ) : (
                        <>
                          <span>&darr;</span>
                          <button type='button' onClick={handleReset} 
                            style={
                              {
                                border: 'none',
                                background: 'none',
                                outline: 'none',
                                cursor: 'pointer',
                                padding: '0'
                              }
                            }>
                            &nbsp;↺&nbsp;
                          </button>
                        </>
                      )
                  ) : key !== 'actions' ? (
                    <>
                      <span>&uarr;</span>
                      <span>&darr;</span>
                    </>
                  ) : null}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {adminsList &&
            adminsList.length &&
            adminsList.map((item: IUser, id: number) => (
              <tr key={id}>
                {Object.keys(TABLE_CONFIG.MANAGE_USERS).map((key, id) => {
                  if (key === 'actions' && userRoleValue === 'ADMIN') {
                    return;
                  }
                  return (
                    <td key={id}>{renderFieldValue(key as keyof typeof TABLE_CONFIG, item)}</td>
                  );
                })}
              </tr>
            ))}
        </tbody>
      </Table>
      {adminsList.length ? (
        <PaginationComponent
          page={page}
          setPage={setPage}
          totalPageCount={totalPageCount === 1 ? 1 : totalPageCount}
          totalItemsCount={totalUsersCount || 0}
          setItemsPerPage={setItemsPerPage}
          itemsPerPage={itemsPerPage}
        />
      ) : null}
    </>
  );
};

export default ManageUsersTable;
