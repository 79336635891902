import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { errorMsg, isTableDataLoading } from '../../cache/vars';
import {
  ALL,
  COMPLETED,
  GET_PACKAGE_CURRENT_TAB,
  GET_PACKAGE_CURRENT_TAB_BY_ORDER_ID,
  GET_PACKAGE_CURRENT_TAB_BY_PO,
  MERCH_DELIVERY,
  NEEDS_DELIVERY,
  NEEDS_FULFILLMENT,
  NEW,
  PENDING_REC,
  REJECTED,
  VOID,
} from '../../queries/vip-workflow';
import { IS_EXPANDABLE_ROWS, responseKeys, TABS_LABELS } from '../../utils/constants';
import { Input } from 'antd';

const SearchAndExpandActions = ({
  setCurrentTab,
  currentTab,
  isDetailsOpened,
  handleExpandRows,
  invoiceIdInputState,
  setInvoiceId,
  setInvoiceIdInputState,
  poInputSate,
  setPoInputState,
  setSearchPoId,
  searchInvoiceId,
  searchPoId,
  setInitialOrders,
  setTotalPageCount,
  setTotalPackagesCount,
  setOrderIdInputState,
  orderIdInputSate,
  searchOrderId,
  setSearchOrderId,
}: any) => {
  const client = useApolloClient();
  const history = useHistory();

  const getQuery = (orderCurrentTab: string) => {
    switch (orderCurrentTab) {
      case TABS_LABELS.NEW:
        return NEW;

      case TABS_LABELS.NEEDS_FULFILLMENT:
        return NEEDS_FULFILLMENT;

      case TABS_LABELS.NEEDS_DELIVERY:
        return NEEDS_DELIVERY;

      case TABS_LABELS.MERCH_DELIVERY:
        return MERCH_DELIVERY;

      case TABS_LABELS.PENDING_REC:
        return PENDING_REC;

      case TABS_LABELS.COMPLETED:
        return COMPLETED;

      case TABS_LABELS.REJECTED:
        return REJECTED;

      case TABS_LABELS.VIEW_ALL:
        return ALL;

      case TABS_LABELS.VOID:
        return VOID;

      default:
        errorMsg('Not Found');
    }
  };

  return (
    <Row className="d-flex align-items-center">
      <Col>
        <div className="d-flex justify-content-end align-items-center m-0 ">
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              isTableDataLoading(true);

              const tabResponse = await client.query({
                query: GET_PACKAGE_CURRENT_TAB_BY_ORDER_ID,
                variables: { orderId: orderIdInputSate },
                fetchPolicy: 'no-cache',
              });

              setSearchOrderId(orderIdInputSate);

              if (
                tabResponse.data?.packageCurrentTabByOrderId.currentTab.toUpperCase() !== currentTab
              ) {
                setCurrentTab(
                  tabResponse.data?.packageCurrentTabByOrderId.currentTab.toUpperCase(),
                );
                history.push(
                  `vip-workflow?tab=${tabResponse.data?.packageCurrentTabByOrderId.currentTab
                    .toUpperCase()
                    .replace(' ', '_')}`,
                );
              }

              if (
                tabResponse.data?.packageCurrentTabByOrderId.currentTab.toUpperCase() === currentTab
              ) {
                const QUERY = getQuery(currentTab);

                if (QUERY) {
                  const initialOrdersData = await client.query({
                    query: QUERY,
                    variables: {
                      page: 1,
                      itemsPerPage: 25,
                      sortBy: '',
                      sortDirection: '',
                      filter: {},
                      csrIdsToFilter: null,
                      po: null,
                      typesToFilter: null,
                      sortDateRangeBy: null,
                      dateRange: null,
                      orderId: orderIdInputSate,
                    },
                    fetchPolicy: 'no-cache',
                  });

                  setInitialOrders(initialOrdersData.data[responseKeys[currentTab]]?.orders);
                  setTotalPageCount(
                    initialOrdersData.data[responseKeys[currentTab]]?.totalPageCount,
                  );
                  setTotalPackagesCount(
                    initialOrdersData.data[responseKeys[currentTab]]?.totalPackagesCount,
                  );
                }
              }

              isTableDataLoading(false);
            }}
          >
            <FormGroup className="d-flex justify-content-end align-items-center m-0">
              <Input
                className="mr-4 p-1 w-100"
                placeholder="Order ID Search"
                onChange={(e) => {
                  setOrderIdInputState(e.target.value);
                }}
                value={orderIdInputSate}
                size="large"
              />
            </FormGroup>
          </Form>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              isTableDataLoading(true);

              if (poInputSate) {
                setPoInputState('');
              }

              if (searchPoId) {
                setSearchPoId(null);
              }

              if (!invoiceIdInputState) {
                const QUERY = getQuery(currentTab);

                if (QUERY) {
                  const initialOrdersData = await client.query({
                    query: QUERY,
                    variables: {
                      page: 1,
                      itemsPerPage: 25,
                      invoiceId: null,
                      sortBy: '',
                      sortDirection: '',
                      filter: {},
                      csrIdsToFilter: null,
                      typesToFilter: null,
                      sortDateRangeBy: null,
                      dateRange: null,
                    },
                    fetchPolicy: 'cache-first',
                  });

                  setInitialOrders(initialOrdersData.data[responseKeys[currentTab]]?.orders);
                  setTotalPageCount(
                    initialOrdersData.data[responseKeys[currentTab]]?.totalPageCount,
                  );
                  setTotalPackagesCount(
                    initialOrdersData.data[responseKeys[currentTab]]?.totalPackagesCount,
                  );
                }

                isTableDataLoading(false);
              } else {
                const tabResponse = await client.query({
                  query: GET_PACKAGE_CURRENT_TAB,
                  variables: { invoiceId: Number.parseFloat(invoiceIdInputState) },
                  fetchPolicy: 'no-cache',
                });

                setInvoiceId(invoiceIdInputState);

                if (tabResponse.data?.packageCurrentTab.currentTab.toUpperCase() !== currentTab) {
                  setCurrentTab(tabResponse.data?.packageCurrentTab.currentTab.toUpperCase());
                  history.push(
                    `vip-workflow?tab=${tabResponse.data?.packageCurrentTab.currentTab
                      .toUpperCase()
                      .replace(' ', '_')}`,
                  );
                }

                if (tabResponse.data?.packageCurrentTab.currentTab.toUpperCase() === currentTab) {
                  const QUERY = getQuery(currentTab);

                  if (QUERY) {
                    const initialOrdersData = await client.query({
                      query: QUERY,
                      variables: {
                        page: 1,
                        itemsPerPage: 25,
                        invoiceId: Number.parseFloat(invoiceIdInputState),
                        sortBy: '',
                        sortDirection: '',
                        filter: {},
                        csrIdsToFilter: null,
                        typesToFilter: null,
                        sortDateRangeBy: null,
                        dateRange: null,
                      },
                      fetchPolicy: 'no-cache',
                    });

                    setInitialOrders(initialOrdersData.data[responseKeys[currentTab]]?.orders);
                    setTotalPageCount(
                      initialOrdersData.data[responseKeys[currentTab]]?.totalPageCount,
                    );
                    setTotalPackagesCount(
                      initialOrdersData.data[responseKeys[currentTab]]?.totalPackagesCount,
                    );
                  }
                }

                isTableDataLoading(false);
              }
            }}
          >
            <FormGroup className="d-flex justify-content-end align-items-center m-0">
              <Input
                className="mr-4 p-1 w-100"
                placeholder="Invoice Id Search"
                onChange={(e) => {
                  setInvoiceIdInputState(e.target.value);
                }}
                value={invoiceIdInputState}
                size="large"
              />
            </FormGroup>
          </Form>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              isTableDataLoading(true);

              if (invoiceIdInputState) {
                setInvoiceIdInputState('');
              }

              if (searchInvoiceId) {
                setInvoiceId(null);
              }

              const tabResponse = await client.query({
                query: GET_PACKAGE_CURRENT_TAB_BY_PO,
                variables: { po: Number.parseFloat(poInputSate) },
                fetchPolicy: 'no-cache',
              });

              setSearchPoId(poInputSate);

              if (tabResponse.data?.packageCurrentTabByPo.currentTab.toUpperCase() !== currentTab) {
                setCurrentTab(tabResponse.data?.packageCurrentTabByPo.currentTab.toUpperCase());
                history.push(
                  `vip-workflow?tab=${tabResponse.data?.packageCurrentTabByPo.currentTab
                    .toUpperCase()
                    .replace(' ', '_')}`,
                );
              }

              if (tabResponse.data?.packageCurrentTabByPo.currentTab.toUpperCase() === currentTab) {
                const QUERY = getQuery(currentTab);

                if (QUERY) {
                  const initialOrdersData = await client.query({
                    query: QUERY,
                    variables: {
                      page: 1,
                      itemsPerPage: 25,
                      sortBy: '',
                      sortDirection: '',
                      filter: {},
                      csrIdsToFilter: null,
                      po: Number.parseFloat(poInputSate),
                      typesToFilter: null,
                      sortDateRangeBy: null,
                      dateRange: null,
                    },
                    fetchPolicy: 'no-cache',
                  });

                  setInitialOrders(initialOrdersData.data[responseKeys[currentTab]]?.orders);
                  setTotalPageCount(
                    initialOrdersData.data[responseKeys[currentTab]]?.totalPageCount,
                  );
                  setTotalPackagesCount(
                    initialOrdersData.data[responseKeys[currentTab]]?.totalPackagesCount,
                  );
                }
              }

              isTableDataLoading(false);
            }}
          >
            <FormGroup className="d-flex justify-content-end align-items-center m-0">
              <Input
                className="mr-4 p-1 w-100"
                placeholder="PO Search"
                onChange={(e) => {
                  setPoInputState(e.target.value);
                }}
                value={poInputSate}
                size="large"
              />
            </FormGroup>
          </Form>
          {IS_EXPANDABLE_ROWS.includes(currentTab) ? (
            <Button
              className="btn-round btn-icon d-flex p-2"
              color="primary"
              size="sm"
              onClick={handleExpandRows}
            >
              <span className="btn-inner--text" style={{ width: '90px' }}>
                {isDetailsOpened.length ? 'Collapse Rows' : 'Expand Rows'}
              </span>
            </Button>
          ) : null}
        </div>
      </Col>
    </Row>
  );
};

export default SearchAndExpandActions;
