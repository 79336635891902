import { useLazyQuery } from '@apollo/client';
import React from 'react';
import { Table } from 'reactstrap';
import { GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD } from '../../queries/ticket';
import { TRANSFER_DETAILS_TABLE_HEADER_CONFIG } from '../../utils/tableConfigs';

const NestedTransferInfoTable = ({ invoiceIdList, isDetailsOpened, openedTransfer, isEditing, editData, setIsEditing, setEditData, setEditable }: any) => {
  const [getTransferInfo, { called, loading, data }] = useLazyQuery(
    GET_PACKAGE_PO_INFO_FOR_TICKETS_DASHBOARD,
    {
      variables: { invoiceIdList },
    },
  );
  
  React.useEffect(() => {
    if (isDetailsOpened && invoiceIdList.length) {
      getTransferInfo();
    }
  }, [isDetailsOpened]);
  
  React.useEffect(() => {
    if (data && data?.getPackagePoInfoForTicketsDahboard) {
      const packagePOInfoArr = JSON.parse(data?.getPackagePoInfoForTicketsDahboard);
      const [invoice] = invoiceIdList;
      if (!packagePOInfoArr[invoice]?.length) {
        setEditable(false);
      }
    }
  }, [data]);

  return (
    <Table className="Table align-items-center" responsive bordered>
      <thead className="thead-light text-center">
        <tr>
          {Object.keys(TRANSFER_DETAILS_TABLE_HEADER_CONFIG).map((key) => (
            <th key={key}>{key}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data?.getPackagePoInfoForTicketsDahboard &&
          Object.entries(JSON.parse(data?.getPackagePoInfoForTicketsDahboard)).map(
            ([detailsKey, detailsValue]: any) => {
              if (Number.parseFloat(detailsKey) === openedTransfer) {
                if (isEditing && editData.length) {
                  return editData.map((item: any, i: any, arr: any) => {
                    return item.tickets.map((ticket: any, index: any) => (
                      <tr key={index}>
                        {Object.entries(TRANSFER_DETAILS_TABLE_HEADER_CONFIG).map(
                          ([key, value]) => {
                            if (['row', 'section', 'seat'].includes(value.key)) {
                              let displayValue: any;
                              if (value.key === 'seat') {
                                displayValue = ticket['seatNumber'];
                              } else {
                                displayValue = editData[i][value.key];
                              }
                              return (
                                <td className="p-2" height="60px" key={value.key}>
                                  <input 
                                    style={{
                                      width: '100%',
                                      padding: '8px 4px',
                                      margin: '0',
                                      border: '1px solid #eeeeee',
                                      background: 'transparent',
                                      boxSizing: 'border-box',
                                      outline: 'none',
                                      textAlign: 'center',
                                    }}
                                    type="text"
                                    defaultValue={displayValue}
                                    value={displayValue}
                                    onFocus={(e) => e.target.style.background = 'white'}
                                    onBlur={(e) => e.target.style.background = 'transparent'}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      if (value.key === 'seat') {
                                        const updatedTickets = arr.tickets.map((currentTicket: any, ticketIndex: any) => {
                                          if (ticketIndex === index) {
                                            return { ...currentTicket, seatNumber: Number(newValue) };
                                          }
                                          return currentTicket;
                                        });
                                        setEditData((prevData: any) => ({ ...prevData, tickets: updatedTickets }));
                                      } 
                                      else {
                                        setEditData((prevData: any) => ({ ...prevData, [value.key]: newValue }));
                                      }
                                      // const updatedData = { ...editData, [value.key]: e.target.value };
                                      // setEditData(updatedData);
                                    }}
                                  />
                                </td>
                              );
                            } else {
                              return (
                                <td className="p-2" height="60px" key={value.key}>
                                  {detailsValue.find((item: any) => item.ticketId === ticket.ticketId) ? detailsValue.find((item: any) => item.ticketId === ticket.ticketId)[value.key] : null}
                                </td>
                              );
                            }
                          }
                        )}
                      </tr>
                    ));
                  });
                } else {
                  return detailsValue.map((item: any, index: number) => (
                    <tr key={index}>
                      {Object.entries(TRANSFER_DETAILS_TABLE_HEADER_CONFIG).map(
                        ([key, value]) => (
                          <td className="p-2" height="60px" key={value.key}>
                            {item[value.key]}
                          </td>
                        ),
                      )}
                    </tr>
                  ));
                }
              }
              return null;
            }
          )}
      </tbody>
    </Table>
  );
};

export default NestedTransferInfoTable;
