import { useApolloClient, useLazyQuery } from '@apollo/client';
import React from 'react';
import { Row, Spinner, Table } from 'reactstrap';
import { GET_DETAILS_BY_INVOICE_ID } from '../../queries/vip-workflow';
import {
  editableCartTypes,
  FULLY_EDITABLE_CARDS,
  tableFieldTypes as types,
} from '../../utils/constants';
import { formatPrice } from '../../utils/services';
import { TABLE_CONFIG } from '../../utils/tableConfigs';
import EditPackageDetailsModal from '../Modals/EditCardModal/EditPackageDetailsModal';
import '../WorkflowTable/Table.scss';

const formatValue = {
  [types.price]: (val: any) => formatPrice(val),
  [types.percent]: (val: any) => `${val}%`,
  [types.number]: (val: any) => (val ? parseInt(val) : null),
  [types.string]: (val: any) => val,
  [types.bool]: (val: any) => <input type="checkbox" defaultChecked={!!val} />,
};

const PackageDetailsTable = ({ invoiceIds, isDetailsOpened, openedInvoiceId }: any) => {
  const client = useApolloClient();

  const [packageDetailsData, setPackageDetailsData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isModalOpened, setIsModalOpened] = React.useState(false);
  const [modalGroup, setModalGroup] = React.useState('');
  const [packageDetailsId, setPackageDetailsId] = React.useState<number | null>(null);
  const [modaName, setModalName] = React.useState('');

  const detailsByInvoiceId =
    packageDetailsData.reduce((prev: any, cur: any) => {
      prev[cur.invoiceId] = prev[cur.invoiceId] || [];
      prev[cur.invoiceId].push(cur);
      return prev;
    }, {}) || {};

  React.useEffect(() => {
    if (isDetailsOpened && invoiceIds.length) {
      client
        .query({
          query: GET_DETAILS_BY_INVOICE_ID,
          variables: { invoiceIds },
          fetchPolicy: 'no-cache',
        })
        .then((res) => {
          setPackageDetailsData(res.data?.packageDetailsByInvoiceId);
          setIsLoading(res.loading);
        });
    }
  }, [isDetailsOpened]);

  const renderFieldValue = (
    key: keyof typeof TABLE_CONFIG.PACKAGE_DETAILS,
    value: any,
    item: any,
  ) => {
    if (value.render) {
      return value.render(item[key]);
    }

    if (
      key === 'type' &&
      (editableCartTypes.includes(item.group) || FULLY_EDITABLE_CARDS.includes(item.group))
    ) {
      return (
        <button
          onClick={(e) => {
            e.preventDefault();
            setPackageDetailsId(item.packageDetailsId);
            setModalGroup(item.group);
            setModalName(item.type);
            setIsModalOpened(true);
          }}
          style={{
            border: 'none',
            background: 'none',
            color: '#337ab7',
            padding: '0',
            textDecoration: 'underline',
            textTransform: 'capitalize',
            maxWidth: 'inherit',
          }}
        >
          {item.customTitle ? item.customTitle : item[key]}
        </button>
      );
    }

    return formatValue[typeof value.type === 'function' ? value.type(item) : value.type](item[key]);
  };

  if (isLoading) {
    return (
      <Row className="justify-content-center mb-xl-4">
        <Spinner animation="border" variant="info" />
      </Row>
    );
  }

  return (
    <>
      <EditPackageDetailsModal
        isModalOpened={isModalOpened}
        setIsModalOpened={setIsModalOpened}
        modalGroup={modalGroup}
        setModalGroup={setModalGroup}
        packageDetailsId={packageDetailsId}
        setPackageDetailsId={setPackageDetailsId}
        modaName={modaName}
        setModalName={setModalName}
        invoiceId={openedInvoiceId}
        defaultAirportTransferType={'transfer1'}
        defaultVegasLimoTransferType='transfer1'
      />
      <Table className="Table align-items-center" responsive bordered>
        <thead className="thead-light text-center">
          <tr>
            {Object.keys(TABLE_CONFIG.PACKAGE_DETAILS).map((key, id) => (
              <th key={id}>{(TABLE_CONFIG as any).PACKAGE_DETAILS[key].colTitle}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.entries(detailsByInvoiceId).map(([detailsKey, detailsValue]: any, id: number) => {
            if (detailsKey === openedInvoiceId.toString()) {
              return detailsValue.map((item: any) => {
                return (
                  <tr key={id}>
                    {Object.entries(TABLE_CONFIG.PACKAGE_DETAILS).map(([key, value], id) => {
                      return (
                        <td height="60px" key={id}>
                          {renderFieldValue(
                            key as keyof typeof TABLE_CONFIG.PACKAGE_DETAILS,
                            value,
                            item,
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              });
            }
          })}
        </tbody>
      </Table>
    </>
  );
};

export default PackageDetailsTable;
