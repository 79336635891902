import React from 'react';
import { Table } from 'reactstrap';
import '../WorkflowTable/Table.scss';
import { DataTableInterface } from '../../utils/interfaces';
import { TABLE_CONFIG } from '../../utils/tableConfigs';

const DataTable: React.FC<DataTableInterface> = ({ currentTabKey, tableData }) => {
  const renderFieldValue = (key: keyof typeof TABLE_CONFIG, item: any) => {
    return item[key];
  };

  return (
    <Table className="Table align-items-center table-flush" responsive striped>
      <thead className="thead-light">
        <tr>
          {Object.keys((TABLE_CONFIG as any)[currentTabKey]).map((key, id) => (
            <th key={id}>{(TABLE_CONFIG as any)[currentTabKey][key].colTitle}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.map((item: any) => (
          <tr key={item.invoiceId}>
            {Object.keys((TABLE_CONFIG as any)[currentTabKey]).map((key, id) => {
              return <td>{renderFieldValue(key as keyof typeof TABLE_CONFIG, item)}</td>;
            })}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default DataTable;
