import { useApolloClient } from '@apollo/client';
import { Formik } from 'formik';
import { Button, Form, FormFeedback, FormGroup, Input, Modal } from 'reactstrap';
import * as Yup from 'yup';
import { successMsg } from '../../cache/vars';
import { CREATE_ADMIN_USER } from '../../queries/users';
import { ADMIN_ROLES } from '../../utils/constants';
import CloseModalButton from '../Buttons/CloseModalButton';

const VALIDATION_SCHEMA = Yup.object().shape({
  username: Yup.string().optional(),
  email: Yup.string().email().required('Required'),
  role: Yup.string().oneOf(ADMIN_ROLES).required('Required'),
  password: Yup.string().min(8).max(32).required('Required'),
});

const CreateAdminModal = ({ isModalOpened, setIsModalOpened, setAdminsList }: any) => {
  const client = useApolloClient();

  return (
    <Modal
      isOpen={isModalOpened}
      toggle={() => {
        setIsModalOpened(false);
      }}
      className="modal-dialog-centered modal-secondary"
      size="md"
      fade={false}
    >
      <div className="modal-body pb-0">
        <div className="d-flex justify-content-between ">
          <div>
            <h1>Create User</h1>
          </div>
          <div>
            <CloseModalButton
              handleCloseModalButtonClick={(e) => {
                e.preventDefault();
                setIsModalOpened(false);
              }}
            />
          </div>
        </div>
        <Formik
          initialValues={{ email: '', role: 'ADMIN', username: '', password: '' }}
          onSubmit={async (userInfo) => {
            const { data } = await client.query({
              query: CREATE_ADMIN_USER,
              variables: {
                newAdminUserInput: {
                  email: userInfo.email,
                  role: userInfo.role,
                  username: userInfo.username,
                  password: userInfo.password,
                },
              },
              fetchPolicy: 'no-cache',
            });

            if (data) {
              setAdminsList((prev: any) => {
                return [data?.createNewAdmin, ...prev];
              });
              setIsModalOpened(false);
              successMsg('Saved');
            }
          }}
          validationSchema={VALIDATION_SCHEMA}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
            } = props;
            return (
              <Form
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();

                  handleSubmit();
                }}
              >
                <FormGroup>
                  <Input
                    type="text"
                    name="username"
                    placeholder="User Name"
                    autoComplete="off"
                    onChange={handleChange}
                    value={values.username}
                    onBlur={handleBlur}
                    invalid={!!errors.username && touched.username}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    invalid={!!errors.email && touched.email}
                    name="email"
                    autoComplete="off"
                    placeholder="Email"
                    role="presentation"
                    onChange={handleChange}
                    value={values.email}
                    onBlur={handleBlur}
                  />
                  {errors.email && touched.email ? (
                    <FormFeedback style={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}>
                      Wrong email format
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <FormGroup>
                  <Input
                    className="form-control-alternative new-event--title"
                    type="select"
                    name="role"
                    placeholder="Role"
                    autoComplete="off"
                    onChange={handleChange}
                    value={values.role}
                    onBlur={handleBlur}
                    invalid={!!errors.role && touched.role}
                  >
                    {ADMIN_ROLES.map((role: string) => {
                      return (
                        <option value={role} key={role}>
                          {role}
                        </option>
                      );
                    })}
                  </Input>
                </FormGroup>
                <FormGroup>
                  <Input
                    type="text"
                    autoComplete="off"
                    onChange={handleChange}
                    value={values.password}
                    name="password"
                    placeholder="Password"
                    invalid={!!errors.password && touched.password}
                    onBlur={handleBlur}
                  />
                  {errors.password && touched.password ? (
                    <FormFeedback style={{ marginTop: '0.2rem', marginBottom: '0.2rem' }}>
                      Password should contain min 8 and max 32 symbols
                    </FormFeedback>
                  ) : null}
                </FormGroup>
                <div className="text-right">
                  <Button
                    className="my-4"
                    color="info"
                    type="submit"
                    disabled={!dirty && isSubmitting}
                  >
                    Create User
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default CreateAdminModal;
